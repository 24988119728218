<app-header></app-header>
<div class="outer">
  <div class="workspace main" [ngClass]="{'mobile_menu': extraService.showUserMobile}" *ngIf="documents">
    <app-side-menu></app-side-menu>
    <section class="content custom_scroll" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}" [@show_preview]="showPrev"
             infiniteScroll
             #workspace
             scrollButtonListener
             [infiniteScrollDistance]="2"
             [infiniteScrollThrottle]="50"
             [scrollWindow]="false"
             [infiniteScrollDisabled]="endScroll"
             (scrolled)="paginationOn()">
        <swiper
          class="custom_swiper"
          [autoplay]="true"
          *ngIf="banners"
          [pagination]="{ clickable: true }"
        >
          <ng-template swiperSlide *ngFor="let banner of banners">
            <div
              class="slide_image"
              [ngStyle]="{backgroundImage: 'url('+ (banner.images[bannersType]) + ')', cursor: banner.link !== null ? 'pointer' : 'default'}"
              (click)="goToLink(banner.link)"
            ></div>
          </ng-template>
        </swiper>
        <div
        class="document_list"
        >
          <div class="document" *ngFor="let document of documents" (click)="goDocument(document)">
            <div class="new_doc" *ngIf="!document.is_view">NEW</div>
            <div class="new_message" *ngIf="document.nonviewed_messages">
              <div class="icon"></div>
              <span>+{{document.nonviewed_messages}}</span>
            </div>
            <div class="document_image" [ngStyle]="{backgroundImage: 'url(' + (document.preview !== '' ? (document.preview) : '') + ')'}"></div>
            <div class="document_title">
              <span>{{document.title}}</span>
              <div class="dead_date" *ngIf="document.deadline && !document.best_score">
                <span class="desc">Дедлайн теста: {{document.deadline | dateFormat: 'mediumDate'}} до {{document.deadline | dateFormat: 'shortTime'}}</span>
              </div>
              <div class="dead_date" *ngIf="document.end_date">
                <span class="desc black">Курс доступен до: {{document.end_date | dateFormat: 'mediumDate'}} </span>
              </div>
              <div class="result" *ngIf="document.result !== null" [ngStyle]="{color: document.before_deadline ? 'black' : 'grey'}">Результат: {{ document.result }}%
                <div
                class="bullet"
                [ngClass]="{
                  gray: document.result === 0,
                  crimson: document.result >= 1 && document.result <= 49,
                  red: document.result >= 50 && document.result <= 79,
                  yellow: document.result >= 80 && document.result <= 89,
                  light_green: document.result >= 90 && document.result <= 99,
                  green: document.result === 100
                }"
                >
              </div>
            </div>
            </div>
          </div>
        </div>

    </section>

    <app-scroll-to-top [workspace]="workspace"></app-scroll-to-top>

  </div>

</div>
