import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import {DialogService, ExtraService} from 'services';
import { ChatService } from "services/api";
import {AlertDialogComponent} from "components/dialogs";

@Component({
  selector: 'app-form-send',
  templateUrl: 'form-send.component.html',
  styleUrls: ['form-send.component.scss'],
})

export class FormSendComponent implements OnInit {

  constructor(
    public extraService: ExtraService,
    private chatService: ChatService,
    private dialogService: DialogService
  ) {}

  @Output() emitAnswer: EventEmitter<any> = new EventEmitter<any>();

  @Input() answer = undefined;
  @Input() chatId = undefined;

  formVisible = true;

  openEmojiPanel = false;

  formSend: FormGroup | any;

  fileType: any;
  fileEntry: any;
  loadFiles: any = [];
  uploadFiles: any = [];

  public files: NgxFileDropEntry[] = [];

  inactiveSend = false

  mobile = false

  openEmoji() {
    if (!this.openEmojiPanel) {
      this.openEmojiPanel = true
    }
  }

  closeEmoji() {
    if (this.openEmojiPanel) {
      this.openEmojiPanel = false
    }
  }

  public addEmoji(event) {
    let text = this.formSend.get('text').value;
    this.formSend.get('text').setValue(text + event.emoji.native);
    this.openEmojiPanel = false;
  }

  deleteFile(index) {
    this.loadFiles.splice(index, 1);
    this.files.splice(index, 1);
  }

  onPush(fileType, name, file) {
    this.loadFiles.push({
      type: fileType,
      name: name,
      url: URL.createObjectURL(file)
    })
    this.uploadFiles.push(file)
  }

  dropped(files: NgxFileDropEntry[]) {
    this.loadFiles = []
    this.uploadFiles = []
    this.files = files;
    this.formVisible = true;
    for (const droppedFile of files) {

      if (droppedFile.fileEntry.isFile) {
        this.fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        this.fileEntry.file((file: File) => {
          this.fileType = droppedFile.relativePath.split('.').pop()
          switch (this.fileType) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'webp':
              this.fileType = 'image'
              this.onPush(this.fileType, droppedFile.relativePath, file)
              break;
            case 'mp4':
            case 'webm':
            case 'm4v':
              this.fileType = 'video'
              this.onPush(this.fileType, droppedFile.relativePath, file)
              break;
            case 'pdf':
            case 'doc':
            case 'docx':
            case 'xls':
            case 'xlsx':
            case 'ppt':
            case 'pptx':
              this.fileType = 'document'
              this.onPush(this.fileType, droppedFile.relativePath, file)
              break;
          }
        });
      } else {
        this.fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
    this.formSend.get('files').setValue(this.uploadFiles)
  }

  fileOver(event) {
    this.formVisible = false;
  }

  fileLeave(event) {
    this.formVisible = true;
  }

  collectObject(formData, form) {
    for (let control in form.controls) {
      if ((form.get(control) as FormGroup).controls) {
        for (let control_in in (form.get(control) as FormGroup).controls) {
          formData.append( control, form.get(control).get(control_in).value );
        }
      } else {
        if (form.get(control).value) {
          if (form.get(control).value._d) {
            formData.append( control, form.get(control).value._d.toISOString() );
          } else {
            if (Array.isArray(form.get(control).value)) {
              for (let i = 0; i < form.get(control).value.length; i++) {
                formData.append(control, form.get(control).value[i])
              }
            } else {
              formData.append( control, form.get(control).value );
            }
          }
        } else {
          if (Array.isArray(form.get(control).value)) {
            formData.append( control, JSON.stringify(form.get(control).value) );
          } else {
            formData.append( control, form.get(control).value );
          }
        }
      }
    }
    return formData;
  }

  removeAnswer() {
    this.answer = undefined;
    this.emitAnswer.emit(undefined);
  }

  onKeydown(event) {
    if (!this.mobile) {
      if (event.key === 'Enter' && event.shiftKey && !event.repeat) {
        event.preventDefault()
        let text = this.formSend.get('text').value
        this.formSend.get('text').setValue(text + '\n')
        return
      }
      if (event.key === 'Enter') {
        event.preventDefault()
        this.submit()
        return
      }
    }
  }

  submit() {
    this.inactiveSend = true
    if (this.formSend.get('text').value.trim() === '' && this.formSend.get('files').value === null) {
      this.resetForm();
      this.dialogService.openDialog(AlertDialogComponent, {title: 'Нельзя отправить пустое сообщение!', confirmButtonText: 'OK'})
      this.inactiveSend = false
      return
    }
    if (this.answer) {
      this.formSend.get('parent').setValue(String(this.answer.id))
    }
    if (this.inactiveSend) {
      const formData: FormData = new FormData();
      this.collectObject(formData, this.formSend);
      this.chatService.sendMessage(this.chatId, formData).subscribe(() => {
        this.resetForm();
        this.inactiveSend = false
      })
    }
  }

  resetForm() {
    this.formSend.get('parent').reset()
    this.formSend.get('text').setValue('')
    this.formSend.get('files').reset()
    this.uploadFiles = []
    this.loadFiles = []
    this.answer = undefined
  }

  ngOnInit(): void {
    if (window.innerWidth <= 1024) {
      this.mobile = true;
    }
    this.formSend = new FormGroup({
      parent: new FormControl(null),
      text: new FormControl(''),
      files: new FormControl(null),
    });
  }
}
