<div class="custom_dialog rating-info">
  <div class="header_dialog">
    <div class="title">Информация</div>
    <div class="icon_close" (click)="dialogClose()"></div>
  </div>
  <div class="title" style="margin-bottom: 20px">{{this.userSurname}} {{this.userName}}</div>
  <div class="table">
    <div class="tr tr_header">логин: </div>
    <div class="tr">{{this.login}}</div>
  </div>
  <div class="table">
    <div class="tr tr_header">город: </div>
    <div class="tr">{{this.city}}</div>
  </div>
  <div class="table">
    <div class="tr tr_header">регион: </div>
    <div class="tr">
        <div class="items" *ngFor="let region of this.region">{{region}}</div>
    </div>
  </div>
  <div class="table">
    <div class="tr tr_header">должность: </div>
    <div class="tr">{{this.position}}</div>
  </div>
  <div class="table">
    <div class="tr tr_header">канал: </div>
    <div class="tr">{{this.channel}}</div>
  </div>
  <div class="table">
    <div class="tr tr_header">бренд: </div>
    <div class="tr">
      <div class="items" *ngFor="let brand of this.brand">{{brand}}</div>
    </div>
  </div>
</div>
