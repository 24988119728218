import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from 'services';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class StatisticsService {
    private readonly root = environment.url + 'api/';

    constructor(
        private httpClient: HttpClient,
        private storage: StorageService,
      ) {}

    getToken(): string {
        const token = this.storage.getValue('token');
        return 'Token ' + token;
    }

    private readonly getUserScoreUrl = this.root + 'statistics/score/';

    private readonly getTestsAvailableUrl = this.root + 'statistics/tests-available/';
    private readonly getTestsComplitedUrl = this.root + 'statistics/tests-completed/';
    private readonly getTestsNonStartedUrl = this.root + 'statistics/tests-non-started/';

    private readonly getReportsListUrl = this.root + 'statistics/reports/list/';

    // private readonly getReportUsersUrl = this.root + 'statistics/report/users/';

    private readonly addStatisticsLogsUrl = this.root + 'statistics/logs/add/';
    private readonly getStatisticsReportContentViewsUrl = this.root + 'statistics/reports/content-views/';

    private readonly getInfographicsParamsUrl = this.root + 'statistics/params/';
    private readonly getInfographicsTableAttestationUrl = this.root + 'statistics/info/attestation/';

    private readonly getInfographicsTableUrl = this.root + 'statistics/info/main/table/';
    private readonly getInfographicsInfoUrl = this.root + 'statistics/info/main/short/';

    getUserScore() {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(),
      });


    return this.httpClient.post(
        this.getUserScoreUrl,
        null,
        { headers },
      );
    }

    getTestsAvailable(id = null) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.getToken(),
          });


        return this.httpClient.post(
            this.getTestsAvailableUrl,
          { id: id },
            { headers },
        );
    }

    getTestsComplited(id = null) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.getToken(),
          });


        return this.httpClient.post(
            this.getTestsComplitedUrl,
          { id: id },
            { headers },
        );
    }

    getTestsNonStarted(id = null) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.getToken(),
          });


        return this.httpClient.post(
            this.getTestsNonStartedUrl,
          { id: id },
            { headers },
        );
    }

    getReportsList() {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(),
      });


      return this.httpClient.post(
          this.getReportsListUrl,
          null,
          { headers },
      );
    }

    getReport(reference, reportId) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(),
      });

      return this.httpClient.post(
        this.root + 'statistics/reports/' + reference + '/export/',
        { id: reportId },
        { headers }
      )
    }

    // getReportUsers() {
    //   const headers = new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Authorization': this.getToken(),
    //   });
    //
    //   return this.httpClient.post(
    //     this.getReportUsersUrl,
    //     null,
    //     { headers }
    //   )
    // }

    addStatisticsLogs(logs: any = null) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(),
      });
      return this.httpClient.post(
        this.addStatisticsLogsUrl,
        { logs: logs },
        { headers }
      );
    }

  getStatisticsReportContentViews(start, finish) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.getStatisticsReportContentViewsUrl,
      { start: start, finish: finish },
      { headers }
    );
  }

  getInfographicsParams(reference) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.getInfographicsParamsUrl + reference + '/',
      null,
      { headers }
    );
  }

  getInfographicsTableAttestation(docId, regionId = null) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.getInfographicsTableAttestationUrl,
      {doc_id: docId, region_id: regionId},
      { headers }
    );
  }

  getInfographicsTable(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.getInfographicsTableUrl,
      { filters: data },
      { headers }
    );
  }

  getInfographicsInfo(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.getInfographicsInfoUrl,
      { filters: data },
      { headers }
    );
  }

}
