import {Component, Input, OnInit} from '@angular/core';
import { forkJoin } from 'rxjs';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService, ExtraService } from 'services';
import { ManagementApiService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-add-management',
  templateUrl: 'add-management.component.html',
  styleUrls: ['add-management.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class AddManagementComponent implements OnInit{

  @Input() noShowMenu = false;

  showPrev = 'show';
  section = undefined;

  constructor(
    public extraService: ExtraService,
    private managementService: ManagementApiService,
    private dialogService: DialogService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) {
  }

  form: FormGroup = this.formBuilder.group({});

  addType = undefined;
  controls = undefined;

  goBack() {
    this.location.back();
  }

  makeFormGroup(form) {
    const group = {};

    Object.keys(form).forEach((key: any) => {
      if (form[key].input.required) {
        group[form[key].input.name] = [form[key].default, Validators.required]
      } else {
        group[form[key].input.name] = [form[key].default]
      }
    })
    this.form = this.formBuilder.group(group);
  }





  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.section = params.section;
      this.addType = params['add-type'];
      switch (this.addType) {
        case 'add-folder':
          this.managementService.managementGetTypesFolder(this.section).subscribe((response: any) => {
            this.controls = response;
            this.makeFormGroup(this.controls);
          });
          break
        case 'add-doc':
          this.managementService.managementGetTypesDoc(this.section).subscribe((response: any) => {
            this.controls = response;
            this.makeFormGroup(this.controls);
          });
          break
        case 'add-attestation':
          this.managementService.managementGetTypesDoc(this.section).subscribe((response: any) => {
            this.controls = response;
            this.makeFormGroup(this.controls);
          });
          break
        case 'add-test':
          this.managementService.managementGetTypesTest().subscribe((response: any) => {
            this.controls = response;
            this.makeFormGroup(this.controls);
          })
          break
        case 'add-attestation-test':
          this.managementService.managementGetTypesAttestationTest().subscribe((response: any) => {
            this.controls = response;
            this.makeFormGroup(this.controls);
          })
          break
        case 'add-banner':
          this.managementService.managementGetTypesBanner(this.section).subscribe((response: any) => {
            this.controls = response;
            this.makeFormGroup(this.controls);
          })
          break
        case 'add-notification':
          this.managementService.managementGetNotificationsTypes().subscribe((response: any) => {
            this.controls = response;
            this.makeFormGroup(this.controls);
          })
          break
      }
    })
  }
}
