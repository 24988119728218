<app-header [noShowMenu]="true" [noGoMain]="true"></app-header>
<div class="outer">
  <div class="workspace doc test"
       *ngIf="testBody"
       [@show_preview]="showPrev"
       #workspace
       scrollButtonListener
  >
    <div class="back_button" (click)="goBack()">
      <div class="back_icon"></div>
      <div class="back_title">{{ testBody.title }}</div>
    </div>

    <section class="content" *ngIf="questionBody">
      <form class="form" [formGroup]="form" (ngSubmit)="submit()" enctype="multipart/form-data">

        <div class="timer" *ngIf="initiateTimer">{{timeleft | msToTime}}</div>

        <div class="question_body">

          <div class="question_count" [ngClass]="{error: errors[questionBody.id]}">
            {{'Вопрос ' + currentQuestionNumber + '/' + totalQuestionNumber}}
          </div>

          <div class="question_info" translate="no" [innerHTML]="questionBody.text | safeHtml">
          </div>

          <div class="greeting" [ngClass]="{error: errors[questionBody.id]}">

            {{questionBody.type === 'matching' ? 'Сопоставьте значения левой и правой части'
            : questionBody.type === 'input' ? 'Введите число'
              : 'Варианты ответа:'}}
            <span *ngIf="questionBody.type === 'checkbox'"> (выбери несколько вариантов ответа)</span>
            <span *ngIf="questionBody.type === 'radio'"> (выбери один правильный ответ)</span>
            <span *ngIf="questionBody.type === 'sort'"> (сортировка по приоритету)</span>
            <span *ngIf="questionBody.type === 'rating'"> (поставьте оценку)</span>
            <span *ngIf="questionBody.type === 'text'"> (открытый вопрос)</span>
          </div>

          <div class="question_options">

            <!--Rating-->
            <ng-container *ngIf="questionBody.type === 'rating'">
              <mat-slider class="custom-slider"
                          min="{{questionBody.options[0].text}}"
                          max="{{questionBody.options[questionBody.options.length - 1].text}}"
                          step="1"
                          thumbLabel="true"
                          tickInterval="1"
                          [formControl]="form.controls[questionBody.id]"
              ></mat-slider>
            </ng-container>

            <!--Radio-->
            <ng-container *ngIf="questionBody.type === 'radio'">
              <mat-radio-group class="options-group" [formControl]="form.controls[questionBody.id]">
                <mat-radio-button
                  value="{{option.id}}"
                  translate="no"
                  class="options-button"
                  *ngFor="let option of questionBody.options; let i = index"
                >
                  <span [innerHTML]="option.text | safeHtml"></span>
                </mat-radio-button>
              </mat-radio-group>
            </ng-container>

            <!--Checkbox-->
            <ng-container *ngIf="questionBody.type === 'checkbox'">
              <div class="options-group" formArrayName="{{questionBody.id}}">
                <mat-checkbox
                  formControlName="{{i}}"
                  value="{{option.id}}"
                  translate="no"
                  class="options-button"
                  *ngFor="let option of questionBody.options; let i = index"
                >
                  <span [innerHTML]="option.text | safeHtml"></span>
                </mat-checkbox>
              </div>
            </ng-container>

            <!--Sort-->
            <ng-container *ngIf="questionBody.type === 'sort'">
              <div class="sort_container">
                <div class="number_container">
                  <div class="number" *ngFor="let number of questionBody.options; let i = index">{{i + 1}}</div>
                </div>
                <div cdkDropList class="options-group" (cdkDropListDropped)="drop($event, questionBody.id, questionBody.options)">
                  <div
                    translate="no"
                    cdkDrag
                    class="options-button options-DropList"
                    *ngFor="let option of questionBody.options"
                  >
                    <span class="text" [innerHTML]="option.text | safeHtml"></span>
                  </div>
                </div>
              </div>
            </ng-container>

            <!--Matching-->
            <ng-container *ngIf="questionBody.type === 'matching'">
              <div class="matching_container">
                <div class="options-group for_matching">
                  <div
                    translate="no"
                    class="options-button options-DropList half_left default_cursor"
                    *ngFor="let option of questionBody.options | halfArray: 'left'; let i = index"
                  >
                    <span class="text" [innerHTML]="option.text | safeHtml"></span>
                  </div>
                </div>
                <div class="equals">
                  <div class="equal" *ngFor="let equal of questionBody.options | halfArray: 'left';"></div>
                </div>
                <div cdkDropList class="options-group for_matching" *ngIf="matchingArrays.length"
                     (cdkDropListDropped)="matchingDrop(
                            $event,
                            questionBody.id
                    )">
                  <div
                    cdkDrag
                    class="options-button options-DropList"
                    *ngFor="let option of matchingArrays | findArray: questionBody.id; let i = index"
                  >
                    <span class="text" [innerHTML]="option.text | safeHtml"></span>
                  </div>
                </div>
              </div>
            </ng-container>

            <!--text-->
            <div class="options-textarea" *ngIf="questionBody.type === 'open'">
                      <textarea
                        type="text"
                        placeholder="Введите текст ответа"
                        [formControl]="form.controls[questionBody.id]"
                      ></textarea>
            </div>

            <ng-container *ngIf="questionBody.type === 'input'">
              <input
                type="number"
                pattern="\d*"
                [formControl]="form.controls[questionBody.id]"
              >
            </ng-container>

          </div>
        </div>

        <div class="test_submit_body" [ngStyle]="{'justify-content': currentQuestionNumber === totalQuestionNumber ? 'flex-end' : 'flex-start'}">
          <button class="test_submit" [disabled]="inactiveSubmit" type="submit">{{currentQuestionNumber === totalQuestionNumber ? 'Завершить' : 'Далее'}}</button>
        </div>

      </form>
    </section>
    <app-scroll-to-top [workspace]="workspace"></app-scroll-to-top>
  </div>

</div>
