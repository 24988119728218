import { Pipe, PipeTransform } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { environment } from '../../environments/environment';

@Pipe( {
  name: 'file',
} )
export class FilePipe implements PipeTransform {

  private readonly root = environment.url;

  constructor( private storage: StorageService ) {}

  transform( filePath: string, download = false, ...args: any[] ) {
    const token = this.storage.getValue( 'token' );
    if ( !filePath || filePath.trim() === '' ) {
      return filePath;
    } else {
      if (download) {
        return this.root + `api/download/${filePath}?${token}`;
      } else {
        if (filePath[0] === '/') {
          return this.root + `files${filePath}`;
        } else {
          return this.root + `files/${filePath}`;
        }
      }
    }
  }
}
