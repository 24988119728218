import { Component, Input, OnInit, HostListener, OnDestroy } from '@angular/core';
import { LightGallery } from 'lightgallery/lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import { HttpClient } from '@angular/common/http';
import {DialogService, ExtraService} from 'services';
import { AlertDialogComponent } from 'components/dialogs';
import { VgApiService } from '@videogular/ngx-videogular/core';
import {BaseService} from "services/api";

@Component({
  selector: 'app-content-blocks',
  templateUrl: './content-blocks.component.html',
  styleUrls: ['./content-blocks.component.scss'],
})

export class ContentBlocksComponent implements OnInit {

  private lightGallery!: LightGallery;

  inactive = false;

  @Input() arrayContent = [];

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    public extraService: ExtraService,
    private baseService: BaseService
  ) {}

  settings = {
    plugins: [lgZoom],
  };

  onInit = (detail): void => {
    this.lightGallery = detail.instance;
};

  onPlayerReady(api: VgApiService, index) {
    const video = api.getMediaById('singleVideo' + index);
    video.subscriptions.play.subscribe((event) => {
      if(!video.canPlay) {
        this.dialogService.openDialog(AlertDialogComponent, {title: 'Идет загрузка, не закрывайте страницу', confirmButtonText: 'ОК'})
        setTimeout(() => {
          this.dialogService.closeDialog()
        }, 5000)
      }
    })
  }

  likedBlock(block) {
    block.is_liked = !block.is_liked
    if (block.is_disliked) {
      block.is_disliked = !block.is_disliked
    }
    this.baseService.likeBlock(block.id).subscribe((response: any) => {
      block.likes = response.likes
      block.dislikes = response.dislikes
    })
  }

  dislikedBlock(block) {
    block.is_disliked = !block.is_disliked
    if (block.is_liked) {
      block.is_liked = !block.is_liked
    }
    this.baseService.dislikeBlock(block.id).subscribe((response: any) => {
      block.likes = response.likes
      block.dislikes = response.dislikes
    })
  }

  setQuality(value, index) {
    setTimeout(() => {
      document.getElementById('singleVideo' + index).setAttribute('src', value.src)
      document.getElementById('source' + index).setAttribute('src', value.src)
    })
  }

  preloaderOn(index, blockId, active = true) {
    if (active) {
      this.dialogService.openDialog(AlertDialogComponent, {title: 'Идет загрузка, не закрывайте страницу', confirmButtonText: 'ОК'})
      document.getElementById('preloader:' + index + '_' + blockId).style.display = 'flex';
      document.getElementById('file:' + index + '_' + blockId).style.display = 'none';
      return;
    }
    this.dialogService.closeDialog()
    document.getElementById('preloader:' + index + '_' + blockId).style.display = 'none';
    document.getElementById('file:' + index + '_' + blockId).style.display = 'block';
    return;
  }

  downloadFileArchive(blockId) {
    if (!this.inactive) {
      this.inactive = true
      this.dialogService.openDialog(AlertDialogComponent, {title: 'Идет загрузка, не закрывайте страницу', confirmButtonText: 'ОК'})
      document.getElementById('title_download_archive' + blockId).style.display = 'none';
      document.getElementById('preloader_download_archive' + blockId).style.display = 'flex';
      this.baseService.downloadFileArchive(blockId).subscribe((response: any) => {
        location.assign(response);
        this.inactive = false;
        document.getElementById('title_download_archive' + blockId).style.display = 'flex';
        document.getElementById('preloader_download_archive' + blockId).style.display = 'none';
      }, () => {
        const data = {
          'title': 'Произошла ошибка, попробуйте ещё раз.',
          'confirmButtonText': 'OK'
        }
        this.inactive = false;
        this.dialogService.openDialog(AlertDialogComponent, data);
      })
    }
    return;
  }

  goFile(file, length, titleBlock, index, blockId) {
    if (file.extension === 'pdf') {
      this.preloaderOn(index, blockId);
      setTimeout(() => {
        window.open(file.src, '_blank');
        this.preloaderOn(index, blockId, false);
      })
    } else {
      if (!this.inactive) {
        this.inactive = true;
        this.preloaderOn(index, blockId);
        this.http.get(file.src, { responseType: 'blob', observe: 'response' })
          .subscribe(response => {

            // file-type
            let fileName: string;

            if (length > 1) {
              fileName = file.title + '.' + file.extension;
            } else {
              fileName = titleBlock + '.' + file.extension;
            }

            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.body);
            link.download = fileName;
            link.click();
            link.remove();
            this.inactive = false;
            this.preloaderOn(index, blockId, false);
          }, () => {
            const data = {
              'title': 'Произошла ошибка, попробуйте ещё раз.',
              'confirmButtonText': 'OK'
            }
            this.inactive = false;
            this.preloaderOn(index, blockId, false);
            this.dialogService.openDialog(AlertDialogComponent, data);
          });
      }
      return;
    }
  }

  startScorm(block: any) {
    this.arrayContent.map((item) => {
      if (item.id === block.id) {
        item.active = true
        item.wasActive = true
        this.baseService.baseViewScorm(block.id, 'start').subscribe()
      } else {
        item.active = false
      }
      if (item.id !== block.id && item.wasActive && !item.active) {
        delete item.wasActive
        this.baseService.baseViewScorm(item.id, 'finish').subscribe()
      }
    })
  }

  zoomScorm(parentBlock: string, childrenBlock: string, id: number) {
    const parentElement = document.getElementById(parentBlock + id)
    const childrenElement = document.getElementById(childrenBlock + id)
    if (!parentElement.classList.contains('zoom')) {
      parentElement.classList.add('zoom')
      childrenElement.style.backgroundImage = 'url(' + '/assets/images/icons/zoom_out.svg' + ')'
    } else {
      parentElement.classList.remove('zoom')
      childrenElement.style.backgroundImage = 'url(' + '/assets/images/icons/zoom_in.svg' + ')'
    }
  }

  @HostListener('document:visibilitychange')
  unloadHandler() {
    if (document.hidden) {
      this.arrayContent.forEach((item) => {
        if (item.type === 'scorm' && item.active) {
          this.baseService.baseViewScorm(item.id, 'finish').subscribe()
        }
      })
    } else {
      this.arrayContent.forEach((item) => {
        if (item.type === 'scorm' && item.active) {
          this.baseService.baseViewScorm(item.id, 'start').subscribe()
        }
      })
    }
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.arrayContent.forEach((item) => {
      if (item.type === 'scorm' && item.active) {
        this.baseService.baseViewScorm(item.id, 'finish').subscribe()
      }
    })
  }
}
