import {Component, OnInit, OnDestroy, ViewChild, Input, NgZone} from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ChatService } from 'services/api';
import { ExtraService } from 'services';

import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';

import { NgScrollbar } from 'ngx-scrollbar';

import {environment} from "../../../../environments/environment";

import * as Centrifuge from 'centrifuge'

@Component({
  selector: 'app-chat',
  templateUrl: 'chat.component.html',
  styleUrls: ['chat.component.scss']
})

export class ChatComponent implements OnInit, OnDestroy {

  constructor(
    private chatService: ChatService,
    public extraService: ExtraService,
    private ngZone: NgZone
  ) {
  }

  settings = {
    plugins: [lgVideo, lgZoom],
  };

  @Input() chatId = undefined;

  @ViewChild(NgScrollbar) chat: NgScrollbar;

  messages = undefined;

  parentMessage = undefined;

  centrifuge = new Centrifuge(environment.socket)
  chatToken = undefined;

  inactiveButton = false

  nonviewedIndex = undefined;

  display$ = new Subject();

  private _scrollSubscription = Subscription.EMPTY;

  preloaderActive = true;

  goToMessage(parentId, event?) {
    if (event) {
      event.stopPropagation()
    }
    document.getElementById(parentId).scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
    document.getElementById(parentId).style.transition = 'all .2s linear'
    document.getElementById(parentId).style.background = '#c3cacc'
    setTimeout(() => {
      if (document.getElementById(parentId).classList.contains('self_message')) {
        document.getElementById(parentId).style.background = '#dfffbb'
      } else {
        document.getElementById(parentId).style.background = '#ffffff'
      }
    }, 1000)
  }

  goToBottom(event) {
    event.stopPropagation()
    setTimeout(() => {
      this.chat.scrollTo({bottom: 0})
    })
  }

  deleteMessage(message_id) {
    this.chatService.deleteMessage(this.chatId, message_id).subscribe()
  }

  likedMessage(message) {
    message.is_liked = !message.is_liked
    if (message.is_disliked) {
      message.is_disliked = !message.is_disliked
    }
    this.chatService.likeMessage(this.chatId, message.id).subscribe()
  }

  dislikedMessage(message) {
    message.is_disliked = !message.is_disliked
    if (message.is_liked) {
      message.is_liked = !message.is_liked
    }
    this.chatService.dislikeMessage(this.chatId, message.id).subscribe()
  }

  downloadFile(url) {
    if (!this.inactiveButton) {
      this.inactiveButton = true
      setTimeout(() => {
        location.assign(url);
        this.inactiveButton = false
      })
    }
  }

  setAnswer(event) {
    this.parentMessage = event
  }

  chatConnect(token) {
    this.centrifuge.setToken(token)
    this.centrifuge.on('connect', (ctx) => {
      console.log('connect')
    });
    this.centrifuge.on('disconnect', (ctx) => {
      console.log('disconnect')
    });
    this.centrifuge.connect();
  }

  chatDispatcher(channel) {
     this.centrifuge.subscribe(channel, async (ctx) => {
       switch (ctx.data.command) {
         case 'add':
           await this.messages.push(ctx.data)
           this.chatService.viewMessage(this.chatId, ctx.data.id).subscribe()
           setTimeout(() => {
             this.chat.scrollTo({bottom: 0})
           })
           break
         case 'delete':
           await this.messages.splice(ctx.data.index,1)
           break
         case 'dislike':
           this.messages[ctx.data.index].dislikes = ctx.data.dislikes
           this.messages[ctx.data.index].likes = ctx.data.likes
           break
         case 'like':
           this.messages[ctx.data.index].likes = ctx.data.likes
           this.messages[ctx.data.index].dislikes = ctx.data.dislikes
           break
       }
    })
  }

  scrollEvent() {
    this._scrollSubscription = this.chat.scrolled
      .pipe(
        map(
          (e: any) => ((e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight)) < 1  ? 'flex' : 'none')),
        tap((display: string) => this.ngZone.run(() => this.display$.next(display)))
      )
      .subscribe();
  }

  ngOnInit(): void {
    let chatChannel = 'chats:' + this.chatId
    forkJoin([
      this.chatService.nonviewedMessage(this.chatId),
      this.chatService.getChatList(this.chatId),
      this.chatService.getChatsToken()
    ]).subscribe((response: any) => {
      this.nonviewedIndex = response[0].index
      this.messages = response[1].messages
      this.preloaderActive = false
      this.chatToken = response[2].token
      this.chatConnect(this.chatToken)
      this.chatDispatcher(chatChannel)
      if (this.nonviewedIndex === this.messages.length) {
        setTimeout(() => {
          this.chat.scrollTo({bottom: 0})
          this.scrollEvent()
        })
      } else {
        let message = this.messages[this.nonviewedIndex]
        setTimeout(() => {
          this.chat.scrollToElement(document.getElementById('message' + message.id), {duration: 1})
          this.scrollEvent()
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.centrifuge.disconnect();
    this._scrollSubscription.unsubscribe();
  }

}
