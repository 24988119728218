import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "orderBy"
})
export class OrderByPipe implements PipeTransform {
  transform(array: any, elem: any): any {
    if (elem) {
      const foundIdx = array.findIndex(el => el == elem)
      array.splice(foundIdx, 1)
      array.unshift(elem)
      return array;
    }
    return array;
  }
}
