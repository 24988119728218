export * from './alert/alert-dialog.component';
export * from './user-change/user-change-dialog.component';
export * from './management-elements/management-elements-dialog.component';
export * from './attestation-test-result/attestation-test-result-dialog.component';
export * from './team/team-dialog.component';
export * from './final-test-change/final-test-change-dialog.component';
export * from './update-user/update-user-dialog.component';
export * from './feedback/feedback-dialog.component'
export * from './feedback-rating/feedback-rating-dialog.component'
export * from './documents-viewed/documents-viewed-dialog.component'
export * from './info-user-rating/info-user-rating-dialog.component'
