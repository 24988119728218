<div class="custom_dialog team">
  <ng-scrollbar class="team_scrollbar">
    <div class="user_container">
      <div class="dialog-title">Редактирование пользователя</div>
      <div class="update_container">
        <form [formGroup]="formUpdate" class="form_filter" *ngIf="formUpdate">
          <div class="update_user">
            <div class="title_text">Фамилия</div>
            <input class="input_update" formControlName="surname">
          </div>
          <div class="update_user">
            <div class="title_text">Имя</div>
            <input class="input_update" formControlName="name">
          </div>
          <div class="update_user">
            <div class="title_text">Отчество</div>
            <input class="input_update" formControlName="patronymic">
          </div>
          <div class="update_user">
            <div class="title_text">Email</div>
            <input class="input_update" formControlName="email">
          </div>
          <div class="update_user">
            <div class="title_text">Телефон</div>
            <input class="input_update" formControlName="phone">
          </div>
          <div class="update_user">
            <div class="title_text">Город</div>
            <mat-select class="custom_select for_update_user" placeholder="Город" formControlName="city">

              <ngx-mat-select-search
                [formControl]="searchFilterCtrl"
                placeholderLabel="Поиск"
                noEntriesFoundLabel="Нет результатов"
                [(ngModel)]="selectSearchUpdateString"
              ></ngx-mat-select-search>

              <mat-option
                *ngFor="let city of params.cities | search:selectSearchUpdateString:'title'"
                [value]="city.id">
                {{ city.title }}
              </mat-option>

            </mat-select>
          </div>
          <div class="update_user">
            <div class="title_text">Департамент</div>
            <mat-select class="custom_select for_update_user" placeholder="Департамент" formControlName="department">
              <mat-option
                *ngFor="let department of params.departments"
                [value]="department.id">
                {{ department.title }}
              </mat-option>

            </mat-select>
          </div>
          <div class="update_user">
            <div class="title_text">Бренд</div>
            <mat-select class="custom_select for_update_user" placeholder="Бренд" formControlName="brands" multiple>
              <mat-option
                *ngFor="let brand of params.brands"
                [value]="brand.id">
                {{ brand.title }}
              </mat-option>
            </mat-select>
          </div>
          <div class="update_user">
            <div class="title_text">КАНАЛ</div>
            <mat-select class="custom_select for_update_user" placeholder="Канал" formControlName="channel">
              <mat-option
                *ngFor="let channel of params.channels"
                [value]="channel.id">
                {{ channel.title }}
              </mat-option>
            </mat-select>
          </div>
          <div class="update_user">
            <div class="title_text">Должность</div>
            <mat-select class="custom_select for_update_user" placeholder="Должность" formControlName="position">

              <ngx-mat-select-search
                [formControl]="searchFilterCtrl"
                placeholderLabel="Поиск"
                noEntriesFoundLabel="Нет результатов"
                [(ngModel)]="selectSearchUpdateString"
              ></ngx-mat-select-search>

              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let position of params.positions | search:selectSearchUpdateString:'title'"
                [value]="position.id">
                {{ position.title }}
              </mat-option>

            </mat-select>
          </div>
          <div class="update_user">
            <div class="title_text">Регион</div>
            <mat-select class="custom_select for_update_user" placeholder="Регион" formControlName="regions" multiple>

              <ngx-mat-select-search
                [formControl]="searchFilterCtrl"
                placeholderLabel="Поиск"
                noEntriesFoundLabel="Нет результатов"
                [(ngModel)]="selectSearchUpdateString"
              ></ngx-mat-select-search>

              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let region of params.region | search:selectSearchUpdateString:'title'"
                [value]="region.id">
                {{ region.title }}
              </mat-option>

            </mat-select>
          </div>
          <div class="update_user">
            <div class="title_text">Партнер</div>
            <mat-select class="custom_select for_update_user" placeholder="Партнер" formControlName="partner">

              <ngx-mat-select-search
                [formControl]="searchFilterCtrl"
                placeholderLabel="Поиск"
                noEntriesFoundLabel="Нет результатов"
                [(ngModel)]="selectSearchUpdateString"
              ></ngx-mat-select-search>

              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let partner of params.partners"
                [value]="partner.id">
                {{ partner.title }}
              </mat-option>
            </mat-select>
          </div>
          <div class="update_user">
            <div class="title_text">Штат</div>
            <mat-select class="custom_select for_update_user" placeholder="Штат" formControlName="state">
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let state of params.states"
                [value]="state.id">
                {{ state.title }}
              </mat-option>
            </mat-select>
          </div>
          <div class="update_user">
            <div class="title_text">ТЕСТ OFF:</div>
            <div class="custom_select for_update_user">
              <mat-checkbox formControlName="test_off"></mat-checkbox>
            </div>
          </div>
          <div class="update_user">
            <div class="title_text">Активен:</div>
            <div class="custom_select for_update_user">
              <mat-checkbox formControlName="is_active"></mat-checkbox>
            </div>
          </div>

          <div class="button_update_container">
            <div class="button" (click)="sendUpdateUser()">СОХРАНИТЬ</div>
          </div>
        </form>
      </div>
    </div>
  </ng-scrollbar>
</div>
