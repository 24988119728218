import {Component, OnInit, ViewChild} from '@angular/core';

import { ExtraService } from 'services';

import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-personal',
  templateUrl: 'personal.component.html',
  styleUrls: ['personal.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class PersonalComponent implements OnInit{

  @ViewChild('workspace') workspace;

  showPrev = 'show';

  constructor(
    public extraService: ExtraService,
  ) {
  }

  ngOnInit() {

  }
}
