import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'timestampToDate',
})

export class TimestampToDate implements PipeTransform {
  transform(timestamp: any, format: string): any {
    let result;
    switch (format) {
      case 'shortDate':
        // dd.mm.yy
       result = moment(timestamp).format('D.M.YY');
       break;
      case 'shortTime':
        // hh:mm
        result = moment(timestamp).format('HH:MM');
        break;
    }
    return result;
  }
}
