import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

@Component({
  selector: 'app-image-preview',
  templateUrl: 'image-preview.component.html',
  styleUrls: ['image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit, OnDestroy {
  @Input() file!: File

  imageUrl!: SafeUrl

  private objectUrl = ''

  constructor(private readonly domSanitizer: DomSanitizer) {}

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.objectUrl = URL.createObjectURL(this.file)
    this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(this.objectUrl)
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    URL.revokeObjectURL(this.objectUrl)
  }
}
