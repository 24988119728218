import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from 'services';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  private readonly root = environment.url + 'api/';

  private readonly authSignInUrl = this.root + 'auth/signin/';
  private readonly authManagementSignInUrl = this.root + 'auth/signin/management/';
  private readonly authLogOutUtl = this.root + 'auth/logout/';
  private readonly authValidateTokenUrl = this.root + 'auth/validate/';
  private readonly getAuthPermissionsUrl = this.root + 'auth/permissions/';
  private readonly getRulesUrl = this.root + 'auth/rules/';
  private readonly authPasswordUpdateUrl = this.root + 'auth/password/reset/';

  private readonly getUserInfoUrl = this.root + 'auth/user/get/';
  private readonly getUserListUrl = this.root + 'auth/user/list/';
  private readonly getParamsUrl = this.root + 'auth/params/';
  private readonly updateUsersUrl = this.root + 'auth/user/update/';

  private readonly updateUserAvatarUrl = this.root + 'auth/user/update/';
  private readonly resetUserPasswordUrl = this.root + 'auth/password/edit/';

  constructor(
    private httpClient: HttpClient,
    private storage: StorageService,
  ) {}

  getToken(): string {
    const token = this.storage.getValue('token');
    return 'Token ' + token;
  }

  saveNewTokens(response: any): boolean {
    let resultToken = false;
    if (response.token !== undefined) {
      this.storage.setValue('token', response.token);
      this.storage.setValue('token-management', '');
      resultToken = true;
    } else {
      console.error('Токен авторизации не пришёл.');
    }
    return resultToken;
  }

  saveNewManagementTokens(response: any): boolean {
    let resultToken = false;
    if (response.token !== undefined) {
      this.storage.setValue('token', response.token);
      this.storage.setValue('token-management', response.token);
      resultToken = true;
    } else {
      console.error('Токен авторизации не пришёл.');
    }
    return resultToken;
  }

  authTokenValidate() {
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    } );
    return this.httpClient.post(
      this.authValidateTokenUrl,
      null,
      { headers },
    );
  }

  authSignIn( email: string, password: string ) {
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': '',
    });
    return this.httpClient.post(
      this.authSignInUrl,
      { email: email, password: password },
      { headers }
    );
  }

  authManagementSignIn( email: string, password: string ) {
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': '',
    });
    return this.httpClient.post(
      this.authManagementSignInUrl,
      { email: email, password: password },
      { headers }
    );
  }

  authPasswordUpdate(email: string) {
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': '',
    } );
    return this.httpClient.post(
      this.authPasswordUpdateUrl,
      { email: email },
      { headers },
    );
  }

  authGetRules() {
    return this.getRulesUrl;
  }

  authLogOut() {
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': '',
    });
    return this.httpClient.post(
      this.authLogOutUtl,
      null,
      { headers }
    );
  }

  getAuthPermissions() {
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    } );
    return this.httpClient.post(
      this.getAuthPermissionsUrl,
      null,
      { headers },
    );
  }

  getUserInfo( id = null ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.getUserInfoUrl,
      { id: id },
      { headers }
    );
  }

  getUserList(docId, limit, offset, filters = null, search = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    if (filters) {
      return this.httpClient.post(
        this.getUserListUrl,
        { doc_id: docId, limit: limit, offset: offset, filters: filters, search: search },
        { headers }
      )
    }
    return this.httpClient.post(
      this.getUserListUrl,
      { doc_id: docId, limit: limit, offset: offset },
      { headers }
    );
  }

  getParams() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.getParamsUrl,
      null,
      { headers }
    );
  }

  updateUser(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.updateUsersUrl,
      data,
      { headers }
    );
  }

  updateUserAvatar(data) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.updateUserAvatarUrl,
      data,
      { headers }
    );
  }

  resetUserPassword(old_password, new_password) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.resetUserPasswordUrl,
      { old_password: old_password, new_password: new_password },
      { headers }
    );
  }

}
