import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { ExtraService } from 'services/extra.service';

@Injectable({
  providedIn: 'root'
})

export class MetricsService {

  view = 0;
  duration = 0;
  getTimer: any;

  constructor(
    private storage: StorageService,
    private extraService: ExtraService,
  ) {}

  timer() {
    this.duration = 0;

    this.getTimer = window.setInterval(() => {
      this.duration += 1;
    }, 1000);
  }

  takeTime(section, id, created) {

    if (!this.storage.getValue('metrics')) {
      this.storage.setValue('metrics', {'time_intervals': []});
    }

    const time_intervals = this.storage.getValue('metrics')['time_intervals'];

    switch (this.extraService.inDocument) {
      case 'document':
        time_intervals.push(
          {
            'action': 'view',
            'section_id': section,
            'model_name': 'Document',
            'entity_id': id,
            'duration': this.duration,
            'created': created
          }
        );
        break;
      case 'folder':
        if (section === 'main') {
          time_intervals.push(
            {
              'action': 'in',
              'section_id': section,
              'entity_id': id,
              'duration': this.duration,
              'created': created
            }
          );
          break;
        }
        time_intervals.push(
          {
            'action': 'in',
            'section_id': section,
            'model_name': 'Folder',
            'entity_id': id,
            'duration': this.duration,
            'created': created
          }
        );
        break;
      case 'section':
        time_intervals.push(
          {
            'action': 'in',
            'section_id': section,
            'entity_id': id,
            'duration': this.duration,
            'created': created
          }
        );
        break;
    }

    this.storage.setValue(
      'metrics',
      {
        'time_intervals': time_intervals
      }
    );

  }

  clearTimer() {
    clearInterval(this.getTimer);
  }
}
