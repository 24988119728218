import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-attestation-test-result-dialog',
  templateUrl: './attestation-test-result-dialog.component.html',
  styleUrls: ['./attestation-test-result-dialog.component.scss'],
})

export class AttestationTestResultDialogComponent {
  testTitle: string
  result: any
  themes: any
  text: any

  constructor(
    private dialogRef: MatDialogRef<AttestationTestResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {testTitle: string, result: any, themes: any, text: any}
  ) {
  ({
      testTitle: this.testTitle,
      result: this.result,
      themes: this.themes,
      text: this.text
  } = this.dialogData)
  }

  onConfirm() {
    this.dialogRef.close();
  }

}
