<app-header [noShowMenu]="true"></app-header>
<div class="outer">
  <div class="workspace management"
       [ngClass]="{'mobile_menu': extraService.showUserMobile}"
  >
    <section class="content" [@show_preview]="showPrev" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}">

      <div class="back_button" (click)="goBack()">
        <div class="back_icon"></div>
        <div class="back_title">Назад</div>
      </div>

      <div class="actions_container create" *ngIf="section !== 'auth' && section !== 'notifications' && section !== 'feedback'">
        <ng-container *ngIf="section ==='main'">
          <app-section-filters 
            [section]="section"
            (valueChanged)="filterChange($event)">
          </app-section-filters>
        </ng-container>
        <div class="action_button" *ngIf="
        section !== 'test' &&
        section !== 'attestation-tests' &&
        section !== 'banner' &&
        section !== 'attestation' &&
        section !== 'business'"
             (click)="goAdd('add-folder')">
          Добавить папку
        </div>
        <div class="action_button" *ngIf="
        section !== 'test' &&
        section !== 'attestation-tests' &&
        section !== 'attestation'"
             (click)="goAdd(section !== 'banner' ? 'add-doc' : 'add-banner')">
          {{section !== 'banner' ? 'Добавить документ' : 'Добавить баннер'}}
        </div>
        <div class="action_button" *ngIf="section === 'attestation'" (click)="goAdd('add-attestation')">Добавить документ</div>
        <div class="action_button" *ngIf="section === 'test' && section !== 'attestation-tests'" (click)="goAdd('add-test')">Добавить тест</div>
        <div class="action_button" *ngIf="section !== 'test' && section === 'attestation-tests'" (click)="goAdd('add-attestation-test')">Добавить тест</div>
      </div>

      <div class="actions_container import" *ngIf="section === 'auth'">
        <div class="no_show">
          <input type="file" #fileInput name="files[]" (change)="processWebFile($event)" />
        </div>
        <div class="action_button" (click)="getTemplate()">Скачать шаблон</div>
        <div class="action_button" (click)="usersExport()">Выгрузить пользователей</div>
        <span *ngIf="file">{{file.name}}</span>
        <div class="upload_container">
          <div class="action_button" (click)="getFile()">{{file ? 'Изменить файл' : 'Прикрепить файл'}}</div>
          <div class="action_button" (click)="sendFile()" *ngIf="file">Отправить файл</div>
        </div>
      </div>

      <div class="actions_container import" *ngIf="section === 'notifications'">
        <div class="action_button" (click)="goAdd('add-notification')">Новое письмо</div>
      </div>

      <div *ngIf="section === 'feedback'">
        <div class="container-statistics" *ngIf="feedbackStatistics">
          <div class="statistics">
            <div class="statistic-block">
              <div class="statistic-text bold-all">{{feedbackStatistics.total}}</div>
              <div class="statistic-text">Всего<br> запросов</div>
            </div>
            <div class="statistic-block">
              <div
                class="statistics-icons"
                [ngClass]="{
                  dislike: feedbackStatistics.valid_time.value < 70,
                  normal: feedbackStatistics.valid_time.value >= 70 && feedbackStatistics.valid_time.value <= 90,
                  like: feedbackStatistics.valid_time.value > 90
                }"
              ></div>
              <div class="statistic-text bold">{{feedbackStatistics.valid_time.value}}%</div>
              <div class="statistic-text">Отвечено<br> вовремя</div>
            </div>
            <div class="statistic-block">
              <div
                class="statistics-icons"
                [ngClass]="{
                  dislike: feedbackStatistics.wrong_time.value > 50,
                  normal: feedbackStatistics.wrong_time.value >= 20 && feedbackStatistics.wrong_time.value <= 50,
                  like: feedbackStatistics.wrong_time.value < 20
                }"
              ></div>
              <div class="statistic-text bold">{{feedbackStatistics.wrong_time.value}}%</div>
              <div class="statistic-text">Отвечено<br> не вовремя</div>
            </div>
            <div class="statistic-block">
              <div
                class="statistics-icons"
                [ngClass]="{
                  dislike: feedbackStatistics.not_answered.value > 20,
                  normal: feedbackStatistics.not_answered.value >= 5 && feedbackStatistics.not_answered.value <= 20,
                  like: feedbackStatistics.not_answered.value < 5
                }"
              ></div>
              <div class="statistic-text bold">{{feedbackStatistics.not_answered.value}}%</div>
              <div class="statistic-text">Без<br> ответа</div>
            </div>
          </div>
          <div class="statistics bottom">
            <div class="statistic-text down">
              Среднее время ответа:
              <b>{{feedbackStatistics.avg_time}} ч.</b>
            </div>
            <div class="statistic-text down">
              Средняя оценка:
              <b>{{feedbackStatistics.avg_rating}}</b>
              <img class="icon-star" src="assets/images/icons/star-statistics.svg">
            </div>
          </div>
        </div>
      </div>

      <div class="input_container" *ngIf="section !== 'auth'">
        <input placeholder="Введите название документа" [(ngModel)]="searchStringDocument" (keydown.enter)="searchDocument()">
        <div class="input_button_container">
          <div class="input_button delete" *ngIf="!!searchStringDocument" (click)="clearSearchDocument()"></div>
          <div class="input_button search" (click)="searchDocument()"></div>
        </div>
      </div>

      <div *ngIf="section === 'notifications'">
        <div *ngFor="let doc of docs">
          <div  *ngIf="doc.date === null" class="info_container" (click)="goEdit('edit-notification', doc.id)">
            <div class="info">
              <div class="icon mail"></div>
              <div class="title">{{doc.title}}</div>
              <div class="date" *ngIf="doc.date !== null"><span>Дата отправки:</span> {{doc.date | dateFormat: 'mediumDate'}}</div>
            </div>
            <div class="button_container">
              <div class="button delete" (click)="doDelete($event, doc.id, 'notifications')"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="actions_container import" *ngIf="section === 'notifications'">
        <div class="action_button" (click)="openHistory()">История</div>
      </div>

      <div *ngIf="section === 'notifications' && historyMail">
        <div class="input_container">
          <input placeholder="Введите название письма" [(ngModel)]="searchStringMail" (keydown.enter)="searchMail()">
          <div class="input_button_container">
            <div class="input_button delete" *ngIf="!!searchStringMail" (click)="clearSearchMail()"></div>
            <div class="input_button search" (click)="searchMail()"></div>
          </div>
        </div>
        <div *ngFor="let mail of mailSend">
          <div class="info_container send_mail">
            <div class="info mail">
              <div class="icon mail"></div>
              <div class="title">{{mail.title}}</div>
              <div class="date" ><span>Дата отправки:</span> {{mail.date | dateFormat: 'mediumDate'}}</div>
              <div class="text_mail_send"><div [innerHTML]="mail.text | safeHtml"></div>...</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="section === 'feedback'">
        <form [formGroup]="formFeedback" class="form_filter" *ngIf="formFeedback">
          <div class="form-container">

            <mat-select class="custom_select for-feedback-management" placeholder="Выберите Отдел" formControlName="department" (selectionChange)="searchFeedback()">
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let department of department"
                [value]="department.id">
                {{ department.title }}
              </mat-option>
            </mat-select>

            <mat-select class="custom_select for-feedback-management" placeholder="Выберите Тему" formControlName="theme" (selectionChange)="searchFeedback()">
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let theme of theme"
                [value]="theme.id">
                {{ theme.title }}
              </mat-option>
            </mat-select>

            <mat-select class="custom_select for-feedback-management" placeholder="Выберите Подтему" formControlName="subtheme" (selectionChange)="searchFeedback()">
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let subtheme of subtheme"
                [value]="subtheme.id">
                {{ subtheme.title }}
              </mat-option>
            </mat-select>

            <mat-select class="custom_select for-feedback-management" placeholder="Выберите Статус" formControlName="status" (selectionChange)="searchFeedback()">
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let status of status"
                [value]="status.id">
                {{ status.title }}
              </mat-option>
            </mat-select>

          </div>
          <div class="button-container">
            <div class="button" (click)="searchFeedback()">Применить</div>
          </div>
        </form>
        <div class="card">
          <app-management-card-feedback *ngFor="let request of requests" [feedback]="request"></app-management-card-feedback>
        </div>
      </div>

      <div *ngFor="let folder of folders" class="info_container" (click)="goEdit('edit-folder', folder.id)">
        <div class="info">
          <div class="icon folder"></div>
          <div class="title">{{folder.title}}</div>
          <div class="date"><span>Дата создания:</span> {{folder.created | dateFormat: 'mediumDate'}}</div>
        </div>
        <div class="button_container">
          <div class="button delete" (click)="doDelete($event, folder.id, 'folder')"></div>
        </div>
      </div>

      <div class="trash_container"
           *ngIf="section !== 'test' && section !== 'attestation-tests' && section !== 'banner' && section !== 'notifications'  && section !== 'feedback'"
           (click)="goTrash()"
      >
        <div class="count" *ngIf="trashCount">{{trashCount}}</div>
        <div class="trash_icon"></div>
      </div>

      <div *ngIf="section !== 'test' && section !== 'attestation-tests' && section !== 'notifications'  && section !== 'feedback'">
        <div *ngFor="let doc of docs" class="info_container" (click)="goEdit(section !== 'banner' ? 'edit-doc' : 'edit-banner', doc.id)">
          <div class="info">
            <div class="icon" [ngClass]="{doc: section !== 'banner', banner: section === 'banner'}"></div>
            <div class="title">{{doc.title}}</div>
            <div class="date"><span>Дата создания:</span> {{doc.created | dateFormat: 'mediumDate'}}</div>
          </div>
          <div class="button_container">
            <div class="button copy" *ngIf="section !== 'banner'" (click)="doCopy($event, doc.id, doc.type)"></div>
            <div class="button archive" *ngIf="section === 'main' || section === 'attestation'" (click)="doArchive($event, doc.id, true)"></div>
            <div class="button delete" (click)="doDelete($event, doc.id, doc.type)"></div>
          </div>
        </div>
      </div>

      <div *ngIf="section === 'test'">
        <div *ngFor="let doc of docs" class="info_container" (click)="goEdit('edit-test', doc.id)">
          <div class="info">
            <div class="icon test"></div>
            <div class="title">{{doc.title}}</div>
            <div class="date"><span>Минимальный результат:</span> {{doc.min_result}}</div>
          </div>
          <div class="button_container">
            <div class="button delete" (click)="doDelete($event, doc.id, doc.type)"></div>
          </div>
        </div>
      </div>

      <div *ngIf="section === 'attestation-tests'">
        <div *ngFor="let doc of docs" class="info_container" (click)="goEdit('edit-attestation-test', doc.id)">
          <div class="info">
            <div class="icon test"></div>
            <div class="title">{{doc.title}}</div>
<!--            <div class="date"><span>Минимальный результат:</span> {{doc.min_result}}</div>-->
          </div>
          <div class="button_container">
            <div class="button delete" (click)="doDelete($event, doc.id, 'attestation_test')"></div>
          </div>
        </div>
      </div>

      <div *ngIf="section === 'main' || section === 'attestation'">
        <h2 *ngIf="archiveDocs?.length">Архивные документы</h2>

        <div *ngFor="let doc of archiveDocs" class="info_container" (click)="goEdit('edit-doc', doc.id)">
          <div class="info">
            <div class="icon doc"></div>
            <div class="title">{{doc.title}}</div>
            <div class="date"><span>Дата создания:</span> {{doc.created | dateFormat: 'mediumDate'}}</div>
          </div>
          <div class="button_container">
            <div class="button copy" (click)="doCopy($event, doc.id, doc.type)"></div>
            <div class="button archive" (click)="doArchive($event, doc.id, false)"></div>
            <div class="button delete" (click)="doDelete($event, doc.id, doc.type)"></div>
          </div>
        </div>
      </div>

    </section>
  </div>
</div>
