import {Component, OnInit, ViewChild} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ExtraService } from 'services'


@Component({
  selector: 'app-rating',
  templateUrl: 'rating.component.html',
  styleUrls: ['rating.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class RatingComponent implements OnInit {

  @ViewChild('workspace') workspace;

  showPrev = 'show';
  startItem: any

  constructor(
    public extraService: ExtraService,
  ) {
  }

  goStartScroll() {
    document.getElementById('top').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  ngOnInit() {}

}
