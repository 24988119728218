import {Pipe, PipeTransform} from '@angular/core';

@Pipe( {
  name: 'currentGalleryVideo',
} )

export class CurrentGalleryVideoPipe implements PipeTransform{
  transform(item: any): any {
    return `{"source": [{"src":"${item.path}", "type":"video/${item.ext}"}], "attributes": {"preload": false, "controls": true}}`
  }
}
