import { Component, OnInit } from '@angular/core';

import { ExtraService, DialogService } from 'services';

import { StatisticsService } from 'services/api';

import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-attestation-reports',
  templateUrl: 'attestation-reports.component.html',
  styleUrls: ['attestation-reports.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class AttestationReportsComponent implements OnInit {

  showPrev = 'show';

  constructor(
    public extraService: ExtraService,
    private statisticsService: StatisticsService,
    private dialogService: DialogService,
  ) {
  }

  documents = []
  selectedDocument = null
  selectedRegion = null

  regions = []

  tableColumns = undefined
  tableRows = undefined

  selectSection(event) {
    if (event.source.placeholder === 'Регион') {
      this.selectedRegion = event.value
    }
    this.tableColumns = undefined
    this.tableRows = undefined
    this.statisticsService.getInfographicsTableAttestation(this.selectedDocument, this.selectedRegion).subscribe((responseTable: any) => {
      this.tableColumns = responseTable.columns
      this.tableRows = responseTable.rows
    })
  }

  ngOnInit() {
    this.statisticsService.getInfographicsParams('attestation').subscribe((response: any) => {
      this.documents = response.documents
      this.selectedDocument = response.documents[0].id
      this.regions = response.regions
      this.statisticsService.getInfographicsTableAttestation(this.selectedDocument).subscribe((responseTable: any) => {
        this.tableColumns = responseTable.columns
        this.tableRows = responseTable.rows
        console.log(responseTable)
      })
    })
  }

}
