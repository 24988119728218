import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';

import { Location } from '@angular/common';

import { Router, ActivatedRoute } from '@angular/router';

import { DialogService, ExtraService, MetricsService } from 'services'
import { BaseService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';

import * as moment from 'moment';
import { DocumentsViewedDialogComponent } from 'components/dialogs'
import { MatDialog } from '@angular/material/dialog'

@Component({
    selector: 'app-document',
    templateUrl: 'document.component.html',
    styleUrls: ['document.component.scss'],
    animations: [
      trigger('show_preview', [
        state('none', style({
          opacity: 0,
        })),
        state('show', style({
          opacity: 1,
        })),
        transition('void => show', [
          style({
            opacity: 0,
          }),
          animate(500, style({
            opacity: 1,
          })),
        ]),
      ]),
    ],
  })

export class DocumentComponent implements OnInit, OnDestroy {

  @ViewChild( 'support' ) support;
  @ViewChild('workspace') workspace;

  showPrev = 'show';

  section: string;
  docId = 0;
  docBody: any;

  testAvailable: any;
  attestationAvailable: any;

  created = moment().format('x');

  chatId = undefined;

  startItem: any

  declination = (number, ending, cases = [2, 0, 1, 1, 1, 2]) => ending[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private baseService: BaseService,
    public extraService: ExtraService,
    private metricsService: MetricsService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {}

  goBack() {
    if (this.section === 'business') {
      this.router.navigate(['business/null'])
    } else {
      this.location.back();
    }
  }

  getSupport() {
    this.support.nativeElement.click();
  }

  goTest(id) {
    this.router.navigate(['test/' + id])
  }

  goAttestationTest(id) {
    this.router.navigate(['attestation-test/' + id])
  }

  goDocument(info: any) {
    if (!info.is_view) {
      this.baseService.getDocumentViewed(info.next_id.id).subscribe(() => {
          this.router.navigate([this.section + '/documents/' + info.next_id.id]);
        },
        () => {
          return;
        }
      )
    } else {
      this.router.navigate([this.section + '/documents/' + info.next_id.id]);
    }
    this.extraService.inDocument = 'document';
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.section = params.section;
      this.docId = params.id;
      this.baseService.getDocument(this.section, this.docId).subscribe((response: any) => {
        if(response.system_message === null || response.system_message.is_viewed) {
          this.docBody = response;
          response.elements.map((item) => {
            if (item.type === 'scorm') {
              item.active = false
            }
          })
          this.metricsService.timer();
          this.chatId = response.chat;
          if (this.docBody.test) {
            this.baseService.testAvailable(this.docBody.test).subscribe((response: any) => {
              this.testAvailable = response.is_available;
            });
          }
          if (this.docBody.attestation) {
            this.baseService.attestationTestAvailable(this.docBody.attestation.id).subscribe((response: any) => {
              this.attestationAvailable = response.is_available
            })
          }
        } else {
          let data = {
            'title': response.system_message.title,
            'text': response.system_message.text,
            'id': response.system_message.id,
            'instructions': response.system_message.instructions,
            'check_agree': response.system_message.check_agree,
            'button': response.system_message.button,
            'docId': params.id,
          }

          const dialogRef = this.dialog.open(DocumentsViewedDialogComponent, {data, disableClose: true});

          dialogRef.afterClosed().subscribe( () => {
            this.ngOnInit()
          })
        }
      });
    });
  }

  ngOnDestroy() {
    this.metricsService.takeTime(this.section, this.docBody.id, Number(this.created));
    this.metricsService.clearTimer();
  }
}
