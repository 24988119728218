import {Component, OnInit} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DialogService, ExtraService } from 'services'
import { FormControl, FormGroup } from '@angular/forms'
import { BaseService } from 'services/api'
import { DocumentsViewedDialogComponent, InfoUserRatingDialogComponent } from 'components/dialogs'
import { MatDialog } from '@angular/material/dialog'


@Component({
  selector: 'app-current',
  templateUrl: 'current.component.html',
  styleUrls: ['current.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class CurrentComponent implements OnInit {

  showPrev = 'show';

  menuOpenFilter = true;
  formFilter = undefined;
  offset: number = 0;
  limit: number = 10;
  endScroll: boolean = false;

  searchString = '';

  titleRating = 'Рейтинг текущего месяца';

  brands = undefined;
  channels = undefined;
  positions = undefined;
  regions = undefined;
  states = undefined;

  users = undefined;
  leaders = undefined;
  currentUser = undefined;

  clientWidth: number
  open = true

  checked = false

  constructor(
    public extraService: ExtraService,
    private baseService: BaseService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
  }

  paginationOn() {
    if(this.checked) {
      this.offset += this.limit;
      this.baseService.ratingGet(this.limit, this.offset, 'year', this.formFilter.value, this.searchString).subscribe((response: any) => {
        Array.prototype.push.apply(this.users, response.rating);
        if (response.length < this.limit) {
          this.endScroll = true;
        }
      })
    } else {
      this.offset += this.limit;
      this.baseService.ratingGet(this.limit, this.offset, 'month', this.formFilter.value, this.searchString).subscribe((response: any) => {
        Array.prototype.push.apply(this.users, response.rating);
        if (response.length < this.limit) {
          this.endScroll = true;
        }
      })
    }
  }

  clearSearchUser() {
    this.searchString = '';
    this.searchUser();
  }

  checkRating(event) {
    this.checked = !this.checked
    if(this.checked) {
      this.titleRating = 'Рейтинг с начала года';
    } else {
      this.titleRating = 'Рейтинг текущего месяца';
    }
    this.searchUser()
  }

  searchUser() {
    if(this.checked) {
      this.offset = 0;
      this.limit = 10;
      this.baseService.ratingGet(this.limit, this.offset, 'year', this.formFilter.value, this.searchString).subscribe((response: any) => {
        this.users = response.rating;
        this.leaders = response.leaders;
        this.currentUser = response.current_user;
      })
    } else {
      this.offset = 0;
      this.limit = 10;
      this.baseService.ratingGet(this.limit, this.offset, 'month', this.formFilter.value, this.searchString).subscribe((response: any) => {
        this.users = response.rating;
        this.leaders = response.leaders;
        this.currentUser = response.current_user;
      })
    }
  }

  showLeaders() {
    if(this.open) {
      this.open = false
    } else {
      this.open = true
    }
  }

  getUser(userId, userName, userSurname, login, city, region, position, channel, brand) {
    let data = {
      'userId': userId,
      'userName': userName,
      'userSurname': userSurname,
      'login': login,
      'city': city,
      'region': region,
      'position': position,
      'channel': channel,
      'brand': brand
    }
    this.dialog.open(InfoUserRatingDialogComponent, {data});
  }

  resize() {
    this.clientWidth = document.documentElement.clientWidth
    if (this.clientWidth <= 1024) {
      this.open = false
    } else {
      this.open = true
    }
  }

  ngOnInit() {
    this.baseService.ratingParams().subscribe((response: any) => {
      this.brands = response.brand;
      this.channels = response.channel;
      this.positions = response.position;
      this.regions = response.region;
      this.states = response.state;
      this.formFilter = new FormGroup({
        'brand': new FormControl(null),
        'channel': new FormControl(null),
        'position': new FormControl(null),
        'region': new FormControl(null),
        'state': new FormControl(null),
      })
      this.searchUser()
    })
    this.resize()
  }

}
