<div class="custom_dialog document-viewed">
  <div class="container-documents-viewed">
    <div class="container-title" [innerHTML]="this.title | safeHtml"></div>
    <div class="text-agree" [innerHTML]="this.text | safeHtml"></div>
    <div class="container-text" [innerHTML]="this.instructions | safeHtml"></div>
    <div class="container-agreement">
      <div class="agreement">
        <mat-checkbox class="agreement-checkbox" (change)="changeAgreement($event)"></mat-checkbox>
        <div class="agreement-text" [innerHTML]="this.check_agree | safeHtml"></div>
      </div>
      <div class="container-button-agree">
        <button class="button-agree" [disabled]="!this.rulesCheck" (click)="sendViewed()" [innerHTML]="this.button | safeHtml"></button>
      </div>
    </div>
  </div>
</div>
