import {Component, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { ExtraService, DialogService } from 'services';
import { BaseService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-attestation',
  templateUrl: 'attestation.component.html',
  styleUrls: ['attestation.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class AttestationComponent implements OnInit {

  @ViewChild('workspace') workspace;

  showPrev = 'show';

  section: string;

  offset: number = 0;
  limit: number = 10;
  documents = undefined;
  endScroll: boolean = false;

  constructor(
    public extraService: ExtraService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private baseService: BaseService,
    private dialogService: DialogService
  ) {
  }

  paginationOn() {
    this.offset += this.limit;
    this.baseService.getDocumentList(this.section, this.offset, this.limit).subscribe((response: any) => {
      Array.prototype.push.apply(this.documents, response.documents);
      if (response.documents.length < this.limit) {
        this.endScroll = true;
      }
    });
  }

  goDocument(info: any) {
    if (!info.is_view) {
      this.baseService.getDocumentViewed(info.id).subscribe(() => {
          this.router.navigate([this.section + '/documents/' + info.id]);
        },
        () => {
          return;
        }
      )
    } else {
      this.router.navigate([this.section + '/documents/' + info.id]);
    }
    this.extraService.inDocument = 'document';
  }

  ngOnInit() {
    this.route.url.subscribe((urlSegment: any) => {
      this.section = urlSegment[0].path;
    });
    this.baseService.getDocumentList(this.section, this.offset, this.limit).subscribe((response: any) => {
      this.documents = response.documents;
      if (response.documents.length < this.limit) {
        this.endScroll = true;
      }
    });
  }
}
