import {Directive, HostListener} from "@angular/core";
import {ExtraService} from "services";

@Directive({
  selector: '[scrollButtonListener]'
})

export class ScrollButtonListener {

  constructor(
    private extraService: ExtraService
  ) {}

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    this.extraService.scrollButtonTop$.next(event.target.scrollTop);
  }

}
