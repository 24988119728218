<section class="content" [@show_preview]="showPrev" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}">
  <div class="select_container">
    <div>Аттестация: </div>
    <mat-select
      class="custom_select for_management_filters"
      [(value)]="selectedDocument"
      (selectionChange)="selectSection($event)"
    >
      <mat-option
        *ngFor="let document of documents"
        [value]="document.id"
      >
        {{document.title}}
      </mat-option>
    </mat-select>
    <div>Выберите регион: </div>
    <mat-select
      class="custom_select for_management_filters"
      (selectionChange)="selectSection($event)"
      placeholder="Регион"
    >
      <mat-option [value]="null">--</mat-option>
      <mat-option
        *ngFor="let region of regions"
        [value]="region.id"
      >
        {{region.title}}
      </mat-option>
    </mat-select>
  </div>
  <div class="table_container" *ngIf="tableColumns && tableRows">

      <div class="header_table">
        <div class="tr header_tr">
          <div class="td"
               *ngFor="let item of tableColumns; let i = index"
               [ngStyle]="{
             position: item ? 'static' : 'sticky',
             top: '0',
             left: '0',
             border: item ? '1px solid #000000' : '0'
             }"
          >
            {{item}}
          </div>
        </div>
      </div>
    <ng-scrollbar
      [track]="'all'"
      class="table_scroll"
      style="
    --scrollbar-thumb-color: '#bababa';
    --scrollbar-size: 5px;
    "
    >
      <div class="users" *ngFor="let row of tableRows; let i = index">
        <div class="tr table_osn">
          <div class="td title_row" [ngStyle]="{position: 'sticky', left: '0', borderRight: '1px solid #dfdfdd'}">{{row.title}}</div>
          <div class="td value_row"
               *ngFor="let value of row.value"
               [ngStyle]="{display: value.title ? 'flex' : 'none', borderLeft: '1px solid #dfdfdd'}">
            {{value.value}}
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</section>
