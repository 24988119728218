import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})

export class SearchPipe implements PipeTransform{
  transform(arr, value, item) {

    if (arr.length === 0 || value === '') {
      return arr;
    }
    return arr.filter((p) => p[item].toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
}
