<div class="custom_dialog feedback-rating">
  <div class="title">Как бы вы оценили ответ на Ваш запрос:</div>
  <div class="container-star">
    <div class="rating-area">
      <input type="radio" id="star-5" name="rating" value="5">
      <label for="star-5" (click)="countStar(5)"></label>
      <input type="radio" id="star-4" name="rating" value="4">
      <label for="star-4" (click)="countStar(4)"></label>
      <input type="radio" id="star-3" name="rating" value="3">
      <label for="star-3" (click)="countStar(3)"></label>
      <input type="radio" id="star-2" name="rating" value="2">
      <label for="star-2" (click)="countStar(2)"></label>
      <input type="radio" id="star-1" name="rating" value="1">
      <label for="star-1" (click)="countStar(1)"></label>
    </div>
  </div>
</div>
