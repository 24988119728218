import { Component, OnInit, ViewChild } from '@angular/core';

import { AuthService } from 'services/api';
import { ExtraService, DialogService } from 'services';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { AlertDialogComponent, UserChangeDialogComponent } from 'components/dialogs';
@Component({
  selector: 'app-profile',
  templateUrl: 'profile.component.html',
  styleUrls: ['profile.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class ProfileComponent implements OnInit {

  @ViewChild( 'photoInput' ) photoInput;

  showPrev = 'show';

  file: any;
  loadPhoto = '';

  constructor(
    public extraService: ExtraService,
    private dialogService: DialogService,
    private authService:  AuthService,
  ) {
  }

  getPicture() {
    this.photoInput.nativeElement.click();
  }

  processWebImage(event) {
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png') {
      this.file = event.target.files[0];

      const reader: FileReader = new FileReader();
      const self = this;
      reader.onload = (readerEvent: any) => {
        self.loadPhoto = readerEvent.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);

      const formData: FormData = new FormData();
      formData.append('id', null);
      formData.append( 'photo', this.file );

      this.authService.updateUserAvatar(formData).subscribe(
        (img) => {
          this.extraService.profileInfo.photo = img['photo'];
        }, () => {
          const warning = {
            'title': 'Произошла ошибка. Попробуйте еще раз.',
            confirmButtonText: 'ОK',
          };
          this.dialogService.openDialog(AlertDialogComponent, warning);
        },
      );
    }
  }

  changePassword() {
    const data = {
      confirmButtonText: 'Сменить пароль',
    }
    this.dialogService.openDialog(UserChangeDialogComponent, data);
  }

  ngOnInit() {

  }
}
