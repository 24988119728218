import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DialogService, ExtraService} from "services";
import {AlertDialogComponent, FinalTestChangeDialogComponent, UpdateUserDialogComponent} from "components/dialogs";
import {AuthService, BaseService, StatisticsService} from "services/api";

@Component({
  selector: 'app-team-dialog',
  templateUrl: './team-dialog.component.html',
  styleUrls: ['./team-dialog.component.scss'],
})

export class TeamDialogComponent {

  userId: any;
  profileInfo: any;
  params: any;

  testAvailable = undefined;
  testNonStarted = undefined;
  testComplited: any;

  testGet: any;


  constructor(
    public extraService: ExtraService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<TeamDialogComponent>,
    private authService: AuthService,
    private statisticsService: StatisticsService,
    private baseService: BaseService,
    @Inject(MAT_DIALOG_DATA) private dialogData: { 'userId': any, params: any },
  ) {
    ({
      userId: this.userId,
      params: this.params
    } = this.dialogData);
  }


  changeAttempts(profileId, section, numAttempts) {
    let data = {
      title: 'Добавить попытки',
      text: 'Установите попытки',
      numAttempts: numAttempts,
      section: section,
      userId: this.userId,
      profileId: profileId,
      type: 'attempts'
    }
    this.dialogService.openDialog(FinalTestChangeDialogComponent, data).subscribe(() => {

    });
  }

  changeDeadline(profileId, section, currentDate) {
    let data = {
      title: 'Продлить дедлайн',
      text: 'Установите дату',
      currentDate: currentDate,
      userId: this.userId,
      profileId: profileId,
      section: section,
      type: 'deadline'
    }
    this.dialogService.openDialog(FinalTestChangeDialogComponent, data).subscribe(() => {

    });
  }

  updateUser(userId) {
    let data = {
      'userId': userId,
      profileInfo: this.profileInfo,
      params: this.params,
    }
    this.dialogService.openDialog(UpdateUserDialogComponent, data)
  }

  ngOnInit() {
    this.authService.getUserInfo(this.userId).subscribe((response: any) => {
      this.profileInfo = response;
    });
    this.statisticsService.getTestsAvailable(this.userId).subscribe((response: any) => {
      this.testAvailable = response;
    });
    this.statisticsService.getTestsComplited(this.userId).subscribe((response: any) => {
      this.testComplited = response;
    });
    this.statisticsService.getTestsNonStarted(this.userId).subscribe((response: any) => {
      this.testNonStarted = response;
    });
  }

}
