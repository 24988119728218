import { Injectable } from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root',
})

export class ManagementService {

  public selectedType$ = new Subject();

  public changeSelectedType(data: any) {
    this.selectedType$.next(data);
  }

  collectObject(type, formData, form) {
    if (type.includes('add')) {
      for (let control in form.controls) {
        if ((form.get(control) as FormGroup).controls) {
          for (let control_in in (form.get(control) as FormGroup).controls) {
            formData.append( control, form.get(control).get(control_in).value );
          }
        } else {
          if (form.get(control).value) {
            if (form.get(control).value._d) {
              formData.append( control, form.get(control).value._d.toISOString() );
            } else {
              if (Array.isArray(form.get(control).value)) {
                formData.append( control, JSON.stringify(form.get(control).value) );
              } else {
                formData.append( control, form.get(control).value );
              }
            }
          } else {
            if (Array.isArray(form.get(control).value)) {
              formData.append( control, JSON.stringify(form.get(control).value) );
            } else {
              formData.append( control, form.get(control).value );
            }
          }
        }
      }
    } else {
      for (let control in form.controls) {
        if ((form.get(control) as FormGroup).controls) {
          for (let control_in in (form.get(control) as FormGroup).controls) {
            if (!form.get(control).get(control_in).pristine) {
              formData.append( control, form.get(control).get(control_in).value );
            }
          }
        } else {
          if (!form.get(control).pristine) {
            if (form.get(control).value) {
              if (form.get(control).value._d) {
                formData.append( control, form.get(control).value._d.toISOString() );
              } else {
                formData.append( control, form.get(control).value );
              }
            } else {
              formData.append( control, form.get(control).value );
            }
          }
        }
      }
    }
    return formData;
  }

  makeFormGroupElements(form, controls?) {
    const group = {};
    if (controls) {
      Object.keys(form).forEach((key: any) => {
        if (form[key].input.required) {
          group[form[key].input.name] = [controls[key], Validators.required]
        } else {
          group[form[key].input.name] = controls[key]
        }
      })
    } else {
      Object.keys(form).forEach((key: any) => {
        if (form[key].input.required) {
          group[form[key].input.name] = [form[key].default, Validators.required]
        } else {
          group[form[key].input.name] = [form[key].default ? form[key].default : '']
        }
      })
    }
    return group;
  }

}
