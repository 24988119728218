<form [formGroup]="filterForm" class="form">
    <ng-container *ngFor="let filter of filters$ | async; let i = index">
        <button mat-button class="filter">
            <mat-select 
                multiple
                [placeholder]="filter.label"
                [formControlName]="filter.controlName"
            >
                <ng-container *ngFor="let option of filter.options">
                    <mat-option [value]="option.id">
                        {{option.title}}
                    </mat-option>
                </ng-container>
            </mat-select>
        </button>
    </ng-container>
</form>