import {Component, OnInit, ViewChild} from '@angular/core';

import { Location } from '@angular/common';

import { ActivatedRoute } from '@angular/router';

import { BaseService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {ExtraService} from "services";
import {MatTabGroup} from "@angular/material/tabs";

@Component({
    selector: 'app-attempts',
    templateUrl: './attempts.component.html',
    styleUrls: ['./attempts.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class AttemptsComponent implements OnInit {

  @ViewChild('mtg') tg: MatTabGroup;
  @ViewChild('workspace') workspace;

  showPrev = 'show';

  activeTab = 0;

  testBody = undefined;

  attempts = undefined;
  tabs = undefined;

  section = undefined;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private baseService: BaseService,
    private extraService: ExtraService
  ) {

  }

  selectedIndexChange(evt: any) {
    switch (this.section) {
      case 'main':
        this.baseService.testAttemptGet(this.tabs[evt].id).subscribe((responseAnswers: any) => {
          this.testBody = responseAnswers;
        });
        break
      case 'attestation':
        this.baseService.attestationTestAttemptGet(this.tabs[evt].id).subscribe((responseAnswers: any) => {
          this.testBody = responseAnswers;
        });
        break
    }

  }

  goBack() {
    this.location.back();
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.section = params.section
    })
    this.tabs = this.extraService.attempts;
    if (this.tabs) {
      this.selectedIndexChange(0);
    }
  }
}
