<app-header [noShowMenu]="true"></app-header>

<div class="outer">
  <div class="workspace management"
       [ngClass]="{'mobile_menu': extraService.showUserMobile}"
  >
    <section class="content" [@show_preview]="showPrev" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}">

      <div class="back_button back_button_management" (click)="goBack()">
        <div class="back_icon"></div>
        <div class="back_title">Назад</div>
      </div>

      <app-form-management
        [form]="form"
        [type]="editType"
        [controls]="types"
        [id]="id"
        [section]="section"
      ></app-form-management>


      <div class="elements_container" *ngIf="elements && section !== 'banner' && section !== 'attestation-tests' && section !== 'notifications'">
        <h1>{{editType === 'edit-doc' ? 'Элементы документа' : 'Вопросы теста'}}</h1>
        <div (click)="addElement()" class="button">{{editType === 'edit-doc' ? 'Добавить элемент' : 'Добавить вопрос'}}</div>
          <div class="element_container" cdkDropList (cdkDropListDropped)="drop($event, elements)">
            <div class="element" cdkDrag *ngFor = "let element of elements; let i = index" (click)="editElement(element)">
              <div class="info_container">
                <div class="element_index">{{i + 1}}</div>
                <div class="element_type"><span>{{editType === 'edit-doc' ? 'Тип элемента ' : 'Тип вопроса '}}</span>{{element.type}}</div>
                <div class="element_title">{{element.title}}</div>
                <div class="element_text" *ngIf="editType !== 'edit-doc'" [innerHTML]="element.text | safeHtml"></div>
              </div>
              <div class="element_button_container">
                <div class="delete_button" (click)="deleteElement($event, element)"></div>
              </div>
            </div>
          </div>
      </div>

      <div class="elements_container" *ngIf="section === 'notifications'">
        <h1>Файлы письма</h1>
        <div (click)="addElement()" class="button">Выбрать файл для рассылки</div>
        <div class="element_container" cdkDropList (cdkDropListDropped)="drop($event, elements)">
          <div class="element" cdkDrag *ngFor = "let element of elements; let i = index" (click)="editElement(element)">
            <div class="info_container">
              <div class="element_index">{{i + 1}}</div>
<!--              <div class="element_type"><span>{{editType === 'edit-notification' ? 'Тип файла ' : 'Тип файла '}}</span>{{element.type}}</div>-->
              <div class="element_title">{{element.file}}</div>
              <div class="element_text" [innerHTML]="element.text | safeHtml"></div>
            </div>
            <div class="element_button_container">
              <div class="delete_button" (click)="deleteElement($event, element)"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="elements_container" *ngIf="elements && section === 'banner'">
        <h1>Изображения</h1>
        <div (click)="addBannerImage()" class="button">Добавить изображения</div>
        <div class="element_container">
          <div class="element" *ngFor = "let image of elements; let i = index" (click)="editBannerImage(image)">
            <div class="info_container">
              <div class="element_index">{{i + 1}}</div>
              <div class="element_type"><span>Формат изображения </span>{{image.format}}</div>
            </div>
            <div class="element_button_container">
              <div class="delete_button" (click)="deleteElement($event, image)"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="elements_container" *ngIf="elements && section === 'attestation-tests'">
        <h1>Вопросы теста</h1>
        <div class="button" (click)="downloadQuestionTemplate()" [id]="'buttonTemplate'">Скачать шаблон импорта</div>
        <div class="preloader" [id]="'preloaderTemplate'">
          <div class="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="button_container">
          <div class="no_show">
            <input type="file" #fileInput name="files[]" (change)="processWebFile($event)" />
          </div>
          <div class="button no_margin" (click)="getFile()">{{file ? 'Изменить файл' : 'Прикрепить файл'}}</div>
          <div *ngIf="file" class="file_title">{{file.name}}</div>
          <div class="button no_margin" *ngIf="file" [id]="'buttonImport'" (click)="sendFile()">Импортировать вопросы</div>
          <div class="preloader" [id]="'preloaderImport'">
            <div class="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div (click)="addElement()" class="button">Добавить вопрос</div>
        <div class="help_text">Фильтр по:</div>
        <form [formGroup]="formFilter" class="form_filter" *ngIf="formFilter" (ngSubmit)="filterForm()">
          <mat-select
            class="custom_select for_management_filters"
            placeholder="Тема"
            formControlName="theme"
          >
            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let theme of params.themes"
              [value]="theme.id"
            >
              {{theme.title}}
            </mat-option>
          </mat-select>
          <mat-select
            class="custom_select for_management_filters"
            placeholder="Микротема"
            formControlName="microtheme"
          >
            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let microtheme of params.microthemes"
              [value]="microtheme.id"
            >
              {{microtheme.title}}
            </mat-option>
          </mat-select>
          <button class="button" type="submit" [disabled]="inactiveFilter">Применить</button>
        </form>
        <div class="help_text">Поиск</div>
        <input [(ngModel)]="search">
        <div class="element_container" cdkDropList (cdkDropListDropped)="drop($event, elements)">
          <div class="element" cdkDrag *ngFor = "let element of elements | search:search:'text'; let i = index" (click)="editElement(element)">
            <div class="info_container">
              <div class="element_index">{{i + 1}}</div>
              <div class="element_type"><span>Тип вопроса </span>{{element.type}}</div>
              <div class="element_title">{{element.title}}</div>
              <div class="element_text" [innerHTML]="element.text | safeHtml"></div>
            </div>
            <div class="element_button_container">
              <div class="delete_button" (click)="deleteElement($event, element)"></div>
              <mat-checkbox class="custom_checkbox" (change)="handleElementClick(element)" (click)="$event.stopPropagation()"></mat-checkbox>
            </div>
          </div>
          <div class="button" (click)="deleteElementArr(elements)">Удалить выбранные</div>
        </div>
      </div>

      <div class="elements_container">
        <h1>Фильтры</h1>
        <div class="actions">
          <div class="button" (click)="addFilter()">Добавить фильтры</div>
          <ng-container *ngIf="filters?.length">
            <div class="button grey" (click)="onDeleteSelectedFilters()">Удалить выбранные</div>
            <div class="button grey" (click)="onDeleteAllFilters()">Удалить все</div>
          </ng-container>
        </div>
        <ng-container *ngIf="filters?.length">
          <form class="element_container" [formGroup]="filterFormGroup">
            <div 
              class="element disabled_edit"
              *ngFor ="let filter of filters; let i = index"
              formArrayName="filters"
            >
              <div class="info_container">
                <div class="element_index">{{i + 1}}</div>
                <div class="element_type"><span></span>{{filter.title}}</div>
              </div>
              <div class="element_button_container">
                <div class="delete_button" (click)="deleteFilter(filter.id); $event.stopPropagation()"></div>
                <mat-checkbox [formControlName]="i"></mat-checkbox>
              </div>
            </div>
          </form>
        </ng-container>
      </div>

      <div class="elements_container" *ngIf="section === 'notifications'">
        <h1>Отправка письма</h1>
        <div class="button" (click)="sendMail()">Отправить письмо</div>
      </div>

    </section>
  </div>
</div>
