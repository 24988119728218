import {Pipe, PipeTransform} from '@angular/core';

@Pipe( {
  name: 'getMonthForIndex',
} )

export class GetMonthForIndex implements PipeTransform{
  transform(timestamp: number): string {
    let monthNames = [ 'январь', 'февраль', 'март', 'апрель', 'май', 'июнь',
      'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь' ];
    return monthNames[timestamp - 1];
  }
}
