import { NgModule } from '@angular/core'

import { FileDialogComponent } from './file-dialog.component'
import { FileDialogTriggerDirective } from './file-dialog-trigger.directive'

@NgModule({
  declarations: [
    FileDialogComponent,
    FileDialogTriggerDirective
  ],
  exports: [
    FileDialogComponent,
    FileDialogTriggerDirective
  ]
})
export class FileDialogModule {}
