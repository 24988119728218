import {Component, OnInit, ViewChild} from '@angular/core';

import {ActivatedRoute, Router } from '@angular/router';

import { ExtraService } from 'services';
import { BaseService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {Location} from "@angular/common";

@Component({
  selector: 'app-important',
  templateUrl: 'important.component.html',
  styleUrls: ['important.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class ImportantComponent implements OnInit{

  @ViewChild('workspace') workspace;

  showPrev = 'show';

  section: string;
  offset: number = 0;
  limit: number = 10;
  folders = undefined;
  documents = undefined;
  checkBack = false;
  folderId = undefined;
  prevFolderIdArr = [];
  folderLevel = 0;
  endScroll: boolean = false;

  constructor(
    public extraService: ExtraService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private baseService: BaseService
  ) {
  }

  goBack() {
    this.offset = 0;
    this.endScroll = false;
    this.location.back();
  }

  paginationOn() {
    this.offset += this.limit;
    if (this.folderId) {
      this.baseService.getDocumentList(this.section, this.offset, this.limit, this.folderId).subscribe((response: any) => {
        Array.prototype.push.apply(this.folders, response.folders);
        Array.prototype.push.apply(this.documents, response.documents);
        if (response.documents.length < this.limit) {
          this.endScroll = true;
        }
      });
    } else {
      this.baseService.getDocumentList(this.section, this.offset, this.limit).subscribe((response: any) => {
        Array.prototype.push.apply(this.documents, response.documents);
        if (response.documents.length < this.limit) {
          this.endScroll = true;
        }
      });
    }
  }

  goDocument(info: any) {
    if (!info.is_view) {
      this.baseService.getDocumentViewed(info.id).subscribe(() => {
          this.router.navigate([this.section + '/documents/' + info.id]);
        },
        () => {
          return;
        }
      )
    } else {
      this.router.navigate([this.section + '/documents/' + info.id]);
    }
    this.extraService.inDocument = 'document';
  }

  goFolder(folderId) {
    this.endScroll = false;
    this.offset = 0;
    this.folderId = folderId;
    this.router.navigate([this.section + '/' + this.folderId])
  }

  ngOnInit() {
    this.route.url.subscribe((urlSegment: any) => {
      this.section = urlSegment[0].path;
    });
    this.route.params.subscribe((params: any) => {
      this.checkBack = params.id !== 'null';
      this.baseService.getDocumentList(this.section, this.offset, this.limit, params.id).subscribe((response: any) => {
        this.folders = response.folders;
        this.documents = response.documents;
        if (response.documents.length < this.limit) {
          this.endScroll = true;
        }
      });
    })
  }
}
