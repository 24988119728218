import {Component, OnInit, AfterViewInit, OnDestroy, HostListener, ViewChild} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from '@angular/router';

import { StorageService, DialogService } from "services";
import { BaseService } from 'services/api';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { AlertDialogComponent, AttestationTestResultDialogComponent } from "components/dialogs";

import { animate, state, style, transition, trigger } from '@angular/animations';

import * as moment from "moment";

@Component({
  selector: 'app-attestation-test',
  templateUrl: 'attestation-test.component.html',
  styleUrls: ['attestation-test.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class AttestationTestComponent implements OnInit, OnDestroy {

  constructor(
    private location: Location,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private storage: StorageService,
    private baseService: BaseService
  ) {

  }

  @ViewChild('workspace') workspace;

  showPrev = 'show';

  form: FormGroup = this.formBuilder.group({});

  testBody: any;
  attemptId: any;
  questionBody: any;
  currentQuestionNumber: any;
  totalQuestionNumber: any;

  blockSend = false; // Блокировка отправки ответов
  errors = {}; // Неотвеченные вопросы

  // Таймер
  timeOut = false;
  time = 60;
  timeleft = this.time;
  timer = 0;
  startMoment = 0;
  endMoment = this.startMoment + this.timeleft * 1000;

  initiateTimer = false;
  anywaySubmit = false;
  inactiveSubmit = false;

  matchingArrays: any = [];

  goBack() {
    const data = {
      title: 'Вы действительно хотите покинуть тест?',
      text: 'Внимание, если вы покините тест, то попытка будет засчитана и ответы будут отправлены.',
      confirmButtonText: 'ДА',
      notButtonText: 'НЕТ'}
    this.dialogService.openDialog(AlertDialogComponent, data, true).subscribe((res: any) => {
      if (res) {
        this.anywaySubmit = true;
        this.submit();
      } else {
        return;
      }
    })
  }

  initCountdown() {
    this.timeOut = false
    this.initiateTimer = true;
    this.startMoment = Date.now();
    this.endMoment = this.startMoment + this.timeleft * 1000;
    this.timer = window.setInterval(() => {
      const now = Date.now();
      this.timeleft = Math.round((this.endMoment - now) / 1000);
      if (this.timeleft <= 0) {
        this.timeleft = this.time;
        this.timeOut = true;
        this.submit();
      }
    }, 1000);
  }

  drop(event: CdkDragDrop<string[]>, controlName: any, arr: any) {
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
    this.form.get(String(controlName)).setValue(arr.map((inf: any) => inf.id));
  }

  matchingDrop(event: CdkDragDrop<string[]>, controlName: any) {
    moveItemInArray(this.matchingArrays.find(x => x.id === controlName).array, event.previousIndex, event.currentIndex);
    this.form.get(String(controlName)).setValue(this.matchingArrays.find(x => x.id === controlName).array.map((inf: any) => inf.id))
  }

  makeFormGroup(form: any) {
    const group = {};
    switch (form.type) {
      case 'radio':
        group[form.id] = [null, Validators.required];
        break
      case 'checkbox':
        group[form.id] = this.formBuilder.array(
          form.options.map(() => this.formBuilder.control(false)),
        );
        break
      case 'sort':
        group[form.id] = [form.options.map((arr: any) => arr.id), Validators.required];
        break
      case 'matching':
        this.matchingArrays.push({
          id: form.id,
          array: form.options.slice(form.options.length / 2, form.options.length)
        })
        group[form.id] = [form.options.slice(form.options.length / 2, form.options.length).map((arr: any) => arr.id), Validators.required];
        break
      case 'open':
        group[form.id] = ['', Validators.required];
        break
      case 'input':
        group[form.id] = ['', Validators.required];
        break
      case 'rating':
        group[form.id] = ['', Validators.required];
        break
    }
    this.form = this.formBuilder.group(group);
  }

  submit() {
    const answers = [];
    this.blockSend = false;
    this.errors = {};
    this.inactiveSubmit = true;
    switch (this.questionBody.type) {
      case 'radio':
        if (this.form.get(String(this.questionBody.id)).value) {
          this.errors[this.questionBody.id] = false;
          answers.push({
            id: this.questionBody.answer_id,
            options: [+this.form.get(String(this.questionBody.id)).value],
            text: '',
          });
        } else {
          this.errors[this.questionBody.id] = true;
          this.blockSend = true;
          answers.push({
            id: this.questionBody.answer_id,
            options: [],
            text: '',});
        }
        break
      case 'checkbox':
        const checkboxAnswers = [];
        Object.keys(this.form.get(String(this.questionBody.id)).value).forEach((i) => {
          if (this.form.get(String(this.questionBody.id)).value[i]) {
            checkboxAnswers.push(this.questionBody.options[i].id);
          }
        });
        if (checkboxAnswers.length > 0) {
          this.errors[this.questionBody.id] = false;
        } else {
          this.errors[this.questionBody.id] = true;
          this.blockSend = true;
        }
        answers.push({
          id: this.questionBody.answer_id,
          options: checkboxAnswers,
          text: '',
        });
        break
      case 'sort':
        this.errors[this.questionBody.id] = false;
        answers.push({
          id: this.questionBody.answer_id,
          options: this.form.get(String(this.questionBody.id)).value,
          text: '',
        });
        break
      case 'open':
        if (this.form.get(String(this.questionBody.id)).value !== '') {
          this.errors[this.questionBody.id] = false;
        } else {
          this.errors[this.questionBody.id] = true;
          this.blockSend = true;
        }
        answers.push({
          id: this.questionBody.answer_id,
          options: [],
          text: this.form.get(String(this.questionBody.id)).value,
        });
        break
      case 'rating':
        const ratingValue = String(this.form.get(String(this.questionBody.id)).value);
        const ratingOptions = this.questionBody.options;
        const ratingAnswer = ratingOptions.find( item => item.text === ratingValue);
        if (this.form.get(String(this.questionBody.id)).value !== '') {
          this.errors[this.questionBody.id] = false;
        } else {
          this.errors[this.questionBody.id] = true;
          this.blockSend = true;
        }
        answers.push({
          id: this.questionBody.answer_id,
          options: [ratingAnswer.id],
          text: '',
        });
        break
      case 'matching':
        this.errors[this.questionBody.id] = false;
        answers.push({
          id: this.questionBody.answer_id,
          options: this.form.get(String(this.questionBody.id)).value,
          text: '',
        });
        break
      case 'input':
        if (this.form.get(String(this.questionBody.id)).value !== '') {
          this.errors[this.questionBody.id] = false;
        } else {
          this.errors[this.questionBody.id] = true;
          this.blockSend = true;
        }
        answers.push({
          id: this.questionBody.answer_id,
          options: [],
          text: this.form.get(String(this.questionBody.id)).value,
        });
        break
    }
    if (this.anywaySubmit) {
      this.errors = {};
      this.saveQuestion(answers, true)
      return;
    } else {
      if (this.blockSend && !this.timeOut) {
        const data = {
          'title': 'Не на все вопросы даны ответы.',
          confirmButtonText: 'ОK',
        };
        this.dialogService.openDialog(AlertDialogComponent, data, true);
        this.inactiveSubmit = false;
      } else {
        if (this.timeOut) {
          this.saveQuestion(answers)
          return;
        }
        this.saveQuestion(answers)
        return
      }
    }
  }

  getQuestion(attemptId) {
    this.baseService.attestationTestQuestionGet(attemptId).subscribe((questionBody: any) => {
      this.questionBody = questionBody
      this.currentQuestionNumber = questionBody.current_num
      this.totalQuestionNumber = questionBody.total_num
      this.makeFormGroup(questionBody)
    })
  }

  saveQuestion(answers, anywaySubmit?) {
    if (anywaySubmit) {
      this.baseService.attestationTestQuestionSave(answers).subscribe()
      this.baseService.attestationTestFinish(this.attemptId).subscribe()
      this.location.back()
      return
    }
    this.baseService.attestationTestQuestionSave(answers).subscribe(() => {
      this.inactiveSubmit = false;
      if (this.currentQuestionNumber === this.totalQuestionNumber || this.timeOut) {
        this.baseService.attestationTestFinish(this.attemptId).subscribe((response: any) => {
          clearInterval(this.timer)
          let data: any
          if (this.timeOut) {
            data = {title: 'Время тестирования вышло.', confirmButtonText: 'ОК'}
            this.dialogService.openDialog(AlertDialogComponent, data, true).subscribe(() => {
              data = {
                testTitle: this.testBody.title,
                result: response.result,
                themes: response.themes,
                text: `Свои ответы Вы сможете увидеть после ${moment(response.deadline).locale('ru').format('L')} в своем профиле`
              }
              this.dialogService.openDialog(AttestationTestResultDialogComponent, data, true).subscribe(() => {
                this.location.back()
              })
            })
            return
          }
          data = {
            testTitle: this.testBody.title,
            result: response.result,
            themes: response.themes,
            text: `Свои ответы Вы сможете увидеть после ${moment(response.deadline).locale('ru').format('L')} в своем профиле`
          }
          this.dialogService.openDialog(AttestationTestResultDialogComponent, data, true).subscribe(() => {
            this.location.back()
          })
        })
      } else {
        this.form.reset()
        this.getQuestion(this.attemptId)
      }
    })
  }

  @HostListener('window:beforeunload')
  doUnload() {
    return false
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.baseService.attestationTestStart(params.id).subscribe((attempt: any) => {
        this.testBody = attempt
        this.attemptId = attempt.attempt_id
        if (attempt.time) {
          this.time = +attempt.time;
          this.timeleft = this.time * 60;
          this.initCountdown();
        }
        this.getQuestion(this.attemptId)
      })
    });
  }

  ngOnDestroy() {
    clearInterval(this.timer)
  }

}
