<section class="content" [@show_preview]="showPrev" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}">
  <div class="container-user" *ngIf="!inactiveSubmit">
    <div class="title-block"><div class="title-block bold">Текущие результаты команды:</div></div>
    <div class="title-block">Команда<div class="title-block bold">{{infoTeamNumber}}</div></div>
    <div class="title-block">Прошли текущие тесты<div class="title-block bold">{{infoTeamPassed}}%</div></div>
    <div class="title-block">
      Результат
      <div class="circle" [ngStyle]="{background: '#' + infoTeamResult.color ? '#' + infoTeamResult.color : '#ffffff'}"></div>
      <div class="title-block bold">{{infoTeamResult.value}}%</div>
    </div>
  </div>

  <div *ngIf="!inactiveSubmit">Выберите курс: </div>

  <form [formGroup]="formFilter" class="form_filter" *ngIf="formFilter">
    <mat-select class="custom_select for_infographics_filters" placeholder="Выберите курс" (selectionChange)="formFilterSearch()" formControlName="doc_id">
      <mat-option [value]="null">--</mat-option>
      <mat-option
        *ngFor="let document of documents"
        [value]="document.id">
        {{document.title}}
      </mat-option>
    </mat-select>
    <div class="container-filters">
      <mat-select class="custom_select for_management" placeholder="Регион" (selectionChange)="formFilterSearch()" formControlName="region_id">
        <mat-option [value]="null">--</mat-option>
        <mat-option
          *ngFor="let region of regions"
          [value]="region.id">
          {{ region.title }}
        </mat-option>
      </mat-select>
      <mat-select class="custom_select for_management" placeholder="Должность" (selectionChange)="formFilterSearch()" formControlName="position_id">
        <mat-option [value]="null">--</mat-option>
        <mat-option
          *ngFor="let position of positions"
          [value]="position.id">
          {{ position.title }}
        </mat-option>
      </mat-select>
      <mat-select class="custom_select for_management" placeholder="Бренд" (selectionChange)="formFilterSearch()" formControlName="brand_id">
        <mat-option [value]="null">--</mat-option>
        <mat-option
          *ngFor="let brand of brands"
          [value]="brand.id">
          {{ brand.title }}
        </mat-option>
      </mat-select>
      <mat-select class="custom_select for_management" placeholder="Канал" (selectionChange)="formFilterSearch()" formControlName="channel_id">
        <mat-option [value]="null">--</mat-option>
        <mat-option
          *ngFor="let channel of channels"
          [value]="channel.id">
          {{ channel.title }}
        </mat-option>
      </mat-select>
      <mat-select class="custom_select for_management" placeholder="Статус" (selectionChange)="formFilterSearch()" formControlName="status_id">
        <mat-option [value]="null">--</mat-option>
        <mat-option
          *ngFor="let status of statuses"
          [value]="status.id">
          {{ status.title }}
        </mat-option>
      </mat-select>
      <div class="button" (click)="formFilterSearch()">Применить</div>
    </div>
    <div class="input_container">
      <input placeholder="Введите фамилию пользователя или Email" [(ngModel)]="searchStringDocument" formControlName="search" (keydown.enter)="formFilterSearch()">
      <div class="input_button_container">
        <div class="input_button delete" (click)="clearSearchDocument()"></div>
        <div class="input_button search" (click)="formFilterSearch()"></div>
      </div>
    </div>
  </form>

  <div class="table_container" *ngIf="tableColumns && tableRows">
    <ng-scrollbar
      [track]="'all'"
      class="table_scroll"
      style="
    --scrollbar-thumb-color: '#bababa';
    --scrollbar-size: 12px;
    "
    >
    <div class="header_table">
      <div class="tr header_tr">
        <div class="td"
             *ngFor="let item of tableColumns; let i = index"
             [ngStyle]="{
             position: item ? 'static' : 'sticky',
             top: '0',
             left: '0',
             border: item ? '1px solid #000000' : '0'
             }"
        >
          {{item}}
        </div>
      </div>
    </div>

      <div class="users" *ngFor="let row of tableRows; let i = index">
        <div class="tr table_osn">
          <div class="td value_row"
               *ngFor="let value of row"
               [ngStyle]="{background: value.color ? value.color : '#ffffff'}">
            {{value.value}}
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>

  <div class="preloader" *ngIf="inactiveSubmit">
    <div class="lds-default">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

</section>
