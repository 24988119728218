<app-header></app-header>

<div class="outer" *ngIf="extraService.profileInfo">
  <div class="workspace personal"
       #workspace
       scrollButtonListener
       [ngClass]="{'mobile_menu': extraService.showUserMobile}">
    <app-side-menu></app-side-menu>
    <div class="container_menu" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}" [@show_preview]="showPrev">
      <div class="group_menu">
        <div
        class="group_profile"
        routerLinkActive="active"
        [routerLink]="['/personal/profile']"
        >Профиль</div>
        <div
        class="group_action"
        routerLinkActive="active"
        [routerLink]="['/personal/statistics']"
        >Действия</div>
        <div
          *ngIf="extraService.profileInfo.role === 'ADMIN' || extraService.profileInfo.role === 'SUPERVISOR' || extraService.profileInfo.role === 'MODERATOR'"
          class="group_action"
          routerLinkActive="active"
          [routerLink]="['/personal/team']"
        >Команда</div>
      </div>
      <router-outlet></router-outlet>

    </div>

    <app-scroll-to-top [workspace]="workspace"></app-scroll-to-top>

  </div>
</div>




