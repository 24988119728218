<div class="team_container" *ngIf="documents">

  <div class="filters_container">
    <div class="button_filtred_mobile" *ngIf="mobileFilter" (click)="openFiltredMobile()">
      Фильтры
      <div class="arrow_filtred_mobile" [ngClass]="{'open': menuOpenFilter}"></div>
    </div>
      <div class="select_container" *ngIf="menuOpenFilter">
        <form [formGroup]="formFilter" class="form_filter" *ngIf="formFilter">
          <mat-select class="custom_select for_management" placeholder="ТЕСТ OFF" (selectionChange)="searchUser()" formControlName="test_off">
            <mat-option [value]="null">
              --
            </mat-option>
            <mat-option [value]="false">
              Нет
            </mat-option>
            <mat-option [value]="true">
              Да
            </mat-option>
          </mat-select>
          <mat-select class="custom_select for_management" placeholder="Бренд" (selectionChange)="searchUser()" formControlName="brand_id">
            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let brand of brands"
              [value]="brand.id">
              {{ brand.title }}
            </mat-option>
          </mat-select>
          <mat-select class="custom_select for_management" placeholder="Должность" (selectionChange)="searchUser()" formControlName="position_id">

            <ngx-mat-select-search
              [formControl]="searchFilterCtrl"
              placeholderLabel="Поиск"
              noEntriesFoundLabel="Нет результатов"
              [(ngModel)]="selectSearchString"
            ></ngx-mat-select-search>

            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let position of positions | search:selectSearchString:'title'"
              [value]="position.id">
              {{ position.title }}
            </mat-option>

          </mat-select>
          <mat-select class="custom_select for_management" placeholder="Штат" (selectionChange)="searchUser()" formControlName="state_id">
            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let state of states"
              [value]="state.id">
              {{ state.title }}
            </mat-option>
          </mat-select>
          <mat-select class="custom_select for_management" placeholder="Регион" (selectionChange)="searchUser()" formControlName="region_id">

            <ngx-mat-select-search
              [formControl]="searchFilterCtrl"
              placeholderLabel="Поиск"
              noEntriesFoundLabel="Нет результатов"
              [(ngModel)]="selectSearchString"
            ></ngx-mat-select-search>

            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let region of regions | search:selectSearchString:'title'"
              [value]="region.id">
              {{ region.title }}
            </mat-option>

          </mat-select>
          <mat-select class="custom_select for_management" placeholder="Канал" (selectionChange)="searchUser()" formControlName="channel_id">
            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let channel of channels"
              [value]="channel.id">
              {{ channel.title }}
            </mat-option>
          </mat-select>
          <mat-select class="custom_select for_management" placeholder="Партнер" (selectionChange)="searchUser()" formControlName="partner_id">

            <ngx-mat-select-search
              [formControl]="searchFilterCtrl"
              placeholderLabel="Поиск"
              noEntriesFoundLabel="Нет результатов"
              [(ngModel)]="selectSearchString"
            ></ngx-mat-select-search>

            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let partner of partners | search:selectSearchString:'title'"
              [value]="partner.id">
              {{ partner.title }}
            </mat-option>

          </mat-select>
        </form>
      </div>
      <div class="search_container">
        <div class="help_text">Поиск по:</div>
        <mat-select
          class="custom_select for_management"
          placeholder="Выберите нужное тестирование"
          (selectionChange)="searchUser()"
          [(value)]="docId">

          <ngx-mat-select-search
            [formControl]="searchFilterCtrl"
            placeholderLabel="Поиск"
            noEntriesFoundLabel="Нет результатов"
            [(ngModel)]="selectSearchString"
          ></ngx-mat-select-search>

          <mat-option [value]="null">--</mat-option>
          <mat-option
            *ngFor="let document of documents | search:selectSearchString:'title'"
            [value]="document.id">
            {{ document.title }}
          </mat-option>
        </mat-select>
        <div class="input_container">
          <input placeholder="Введите фамилию/имя" [(ngModel)]="searchString" (keydown.enter)="searchUser()">
          <div class="input_button_container">
            <div class="input_button delete" *ngIf="!!searchString" (click)="clearSearchUser()"></div>
            <div class="input_button search" (click)="searchUser()"></div>
          </div>
        </div>

      </div>
  </div>

  <div
    class="table_container"
    infiniteScroll
    [infiniteScrollDisabled]="endScroll"
    (scrolled)="paginationOn()"
  >
    <div class="table_header">
      <div class="td">Результат</div>
      <div class="td photo"></div>
      <div class="td">ФИО</div>
      <div class="td">Бренд</div>
      <div class="td">Должность</div>
      <div class="td">Регион</div>
      <div class="td">Канал</div>
      <div class="td"></div>
    </div>
    <div class="tr" *ngFor="let user of team; let i = index">
        <div class="button mobile" (click)="getUser(user.id)"></div>
        <div class="td result">{{user.result}}</div>
        <div class="td photo">
          <div class="profile_img" [ngStyle]="{'backgroundImage': 'url(' + (user.photo !== null ? (user.photo) : '/assets/images/icons/avatar_none.png') + ')'}"></div>
        </div>
        <div class="td">
          {{user.surname}} {{user.name}}
        </div>
        <div class="td brand">
          <span *ngFor="let brand of user.brands">{{ brand }}</span>
        </div>
        <div class="td">{{user.position}}</div>
        <div class="td">
          <span *ngFor="let region of user.regions">{{ region }}</span>
        </div>
        <div class="td">{{user.channel}}</div>
        <div class="td no_show">
          <div class="button" (click)="getUser(user.id)"></div>
        </div>
    </div>
  </div>

</div>
