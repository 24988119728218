import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DestroyerService } from 'core/services';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { ManagementApiService } from 'services/api';

export type ShortFilter = {
  label: string;
  controlName: string;
  options: {id: number; title: string}[];
}

@Component({
  selector: 'app-section-filters',
  templateUrl: './section-filters.component.html',
  styleUrls: ['./section-filters.component.scss'],
  providers: [DestroyerService]
})
export class SectionFiltersComponent implements OnInit, OnChanges {
  @Input() section: string;
  @Output() valueChanged = new EventEmitter<any>();

  #filters = new BehaviorSubject<ShortFilter[]>([]);

  get filters$(): Observable<ShortFilter[]> {
    return this.#filters.asObservable();
  }

  filterForm: FormGroup = this.fb.group({
    query: '',
    section__reference: ''
  });

  constructor(
    private fb: FormBuilder,
    private destory$: DestroyerService,
    private managementApiService: ManagementApiService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.section) {
      this.filterForm.patchValue({
        section__reference: changes.section.currentValue
      })
    }
  }

  ngOnInit(): void {
    this.initOptions();
  }

  private initOptions(): void {
    this.managementApiService.managementGetTypesElementFilter()
    .pipe(
      switchMap((value) => from(Object.values(value)).pipe(
        filter(filter => !filter.input.name.includes('user')),
        map((filter) => {
          let control = '';

          if(filter.input.name.includes('country')) {
            control = 'countries';
          }else {
            control = filter.input.name + 's';
          }

          const transformedFilter:ShortFilter = {
            label: filter.input.label,
            controlName: control,
            options: [...filter.choices]
          }

          return transformedFilter
        }),
        tap((filter) => {
          this.filterForm.addControl( 
            filter.controlName, 
            this.fb.control([])
          );
        }),
        toArray()
      )),
      tap((filters) => {
        this.#filters.next(filters);
        this.initFormListener();
      }),
      takeUntil(this.destory$)
    ).subscribe();
  }

  private initFormListener(): void {
    this.filterForm.valueChanges
    .pipe(
      tap((v) => console.log(v)),
      debounceTime(300),
      tap(v => this.valueChanged.emit(v)),
      takeUntil(this.destory$)
    ).subscribe();
  }

}
