import { Component, OnInit } from '@angular/core';

import { ExtraService, DialogService } from 'services';

import { StatisticsService } from 'services/api';

import { AlertDialogComponent } from 'components/dialogs';

import { animate, state, style, transition, trigger } from '@angular/animations';

import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core"
import { FormGroup, FormControl } from "@angular/forms";

@Component({
  selector: 'app-reports',
  templateUrl: 'reports.component.html',
  styleUrls: ['reports.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
  providers:[
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})

export class ReportsComponent implements OnInit{

  showPrev = 'show';

  reports = undefined;

  statisticsEventForm: FormGroup

  inactiveButton = false;

  openReportArchive = false;

  constructor(
    public extraService: ExtraService,
    private statisticsService: StatisticsService,
    private dialogService: DialogService,
  ) {
  }

  openReports(report) {
    if (!report.open) {
      report.open = true
    } else {
      report.open = false
    }
  }

  openArchive(report) {
    if (!report.open_archive) {
      report.open_archive = true
    } else {
      report.open_archive = false
    }
  }

  downloadReport(type, reference?, reportId?) {
    if (!this.inactiveButton) {
      switch (type) {
        case 'result':
          this.inactiveButton = true;
          document.getElementById('button' + reportId).style.display = 'none';
          document.getElementById(reportId).style.display = 'flex';
          this.statisticsService.getReport(reference, reportId).subscribe((response: any) => {
            this.inactiveButton = false;
            location.assign(response.url);
            document.getElementById('button' + reportId).style.display = 'flex';
            document.getElementById(reportId).style.display = 'none';
          }, () => {
            const data = {
              'title': 'Произошла ошибка, попробуйте ещё раз.',
              'confirmButtonText': 'OK'
            }
            this.inactiveButton = false;
            document.getElementById('button' + reportId).style.display = 'flex';
            document.getElementById(reportId).style.display = 'none';
            this.dialogService.openDialog(AlertDialogComponent, data);
          });
          break
        case 'content-views':
          this.inactiveButton = true;
          document.getElementById('content-views_button').style.display = 'none';
          document.getElementById('content-views_preloader').style.display = 'flex';
          this.statisticsService.getStatisticsReportContentViews(this.statisticsEventForm.get('start').value._i, this.statisticsEventForm.get('finish').value._i).subscribe((response: any) => {
            this.inactiveButton = false;
            location.assign(response.url);
            document.getElementById('content-views_button').style.display = 'flex';
            document.getElementById('content-views_preloader').style.display = 'none';
          }, () => {
            const data = {
              'title': 'Произошла ошибка, попробуйте ещё раз.',
              'confirmButtonText': 'OK'
            }
            this.inactiveButton = false;
            document.getElementById('content-views_button').style.display = 'flex';
            document.getElementById('content-views_preloader').style.display = 'none';
            this.dialogService.openDialog(AlertDialogComponent, data);
          });
          break
      }

    }
    return;
  }

  // downloadUsersReport() {
  //   if (!this.inactiveButton) {
  //     this.inactiveButton = true;
  //     this.statisticsService.getReportUsers().subscribe((response: any) => {
  //       this.inactiveButton = false;
  //       location.assign(response.url);
  //     }, () => {
  //       const data = {
  //         'title': 'Произошла ошибка, попробуйте ещё раз.',
  //         'confirmButtonText': 'OK'
  //       }
  //       this.inactiveButton = false;
  //       this.dialogService.openDialog(AlertDialogComponent, data);
  //     });
  //   }
  //   return;
  // }

  ngOnInit() {
    this.statisticsService.getReportsList().subscribe((response: any) => {
      this.reports = response;
    });
    this.statisticsEventForm = new FormGroup({
      'start': new FormControl(''),
      'finish': new FormControl('')
    })
  }
}
