import { Component, Input, OnInit } from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {DialogService, ExtraService} from "services";
import {ManagementApiService} from "services/api";
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertDialogComponent} from "components/dialogs";

@Component({
  selector: 'app-trash-management',
  templateUrl: 'trash-management.component.html',
  styleUrls: ['trash-management.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class TrashManagementComponent implements OnInit {

  @Input() noShowMenu = false;

  showPrev = 'show';
  section = undefined;
  docs = undefined;

  constructor(
    public extraService: ExtraService,
    private dialogService: DialogService,
    private managementService: ManagementApiService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  goBack() {
    this.location.back()
  }

  changeDialogData(type, text?) {
    let data = {};
    switch (type) {
      case 'delete-doc':
        data = {
          title: 'Вы действительно хотите удалить документ?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'untrash-doc':
        data = {
          title: 'Вы действительно хотите переместить документ из корзины?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'suc-delete-doc':
        data = {
          title: 'Документ успешно удален',
          confirmButtonText: 'OK',
        }
        break
      case 'suc-untrash-doc':
        data = {
          title: 'Документ успешно перемещен',
          confirmButtonText: 'OK',
        }
        break
      case 'error':
        data = {
          title: 'Произошла ошибка',
          confirmButtonText: 'OK'
        }
        break
    }
    return data
  }

  doDelete(event, id, type) {
    event.stopPropagation();
    switch (type) {
      case 'article':
      case 'course':
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('delete-doc')).subscribe((res) => {
          if (res) {
            this.managementService.managementDeleteDocs(type, id).subscribe(() => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-delete-doc'))
              this.managementService.managementGetTrash(this.section).subscribe((response: any) => {
                this.docs = response
              })
            }, () => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'))
            });
          }
        })
        break
    }
  }

  doUntrash(event, id) {
    event.stopPropagation();
    this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('untrash-doc')).subscribe((res) => {
      if (res) {
        this.managementService.managementUpdateDoc(this.section, { is_trash: false }, id).subscribe(() => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-untrash-doc'))
          this.managementService.managementGetTrash(this.section).subscribe((response: any) => {
            this.docs = response
          })
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'))
        });
      }
    })
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.section = params.section;
      this.managementService.managementGetTrash(this.section).subscribe((response: any) => {
        this.docs = response
      })
    })
  }

}
