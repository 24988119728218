<app-header [noShowMenu]="true"></app-header>
<div class="outer">

  <div class="workspace management" [ngClass]="{'mobile_menu': extraService.showUserMobile}">

    <section class="content" [@show_preview]="showPrev" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}">

      <div class="back_button" (click)="goBack()">
        <div class="back_icon"></div>
        <div class="back_title">Назад</div>
      </div>

      <div *ngIf="docs.length">
        <div *ngFor="let doc of docs" class="info_container">
          <div class="info">
            <div class="icon doc"></div>
            <div class="title">{{doc.title}}</div>
            <div class="date"><span>Дата создания:</span> {{doc.created | dateFormat: 'mediumDate'}}</div>
          </div>
          <div class="button_container">
            <div class="button untrash" (click)="doUntrash($event, doc.id)"></div>
            <div class="button delete" (click)="doDelete($event, doc.id, doc.type)"></div>
          </div>
        </div>
      </div>

    </section>

  </div>

</div>
