<app-header [noShowMenu]="true"></app-header>
<div class="outer">
    <div class="workspace doc test"
         #workspace
         scrollButtonListener
    >
        <div class="back_button" (click)="goBack()">
            <div class="back_icon"></div>
            <div class="back_title">Просмотр ответов</div>
        </div>

        <section class="content" [@show_preview]="showPrev">
          <mat-tab-group class="custom_attempts_tab" [(selectedIndex)]="activeTab" #mtg (selectedIndexChange)="selectedIndexChange($event)">

            <ng-container *ngFor="let tab of tabs;let k = index">
              <mat-tab label="Попытка {{k+1}}" *ngIf="testBody">
                <div class="greeting">
                  Тест:<div class="question_text" translate="no">{{testBody.test}}</div>
                </div>
                <div class="greeting">
                  Дата завершения теста:<div class="question_text" translate="no">{{testBody.finish | timestampToDate : 'shortDate'}} в {{testBody.finish | timestampToDate : 'shortTime'}}</div>
                </div>
                <div class="question_body" *ngFor="let question_body of testBody.questions; let i = index">
                  <div class="greeting">
                    Вопрос №{{i + 1}} <span [ngClass]="{
                              correct: question_body.is_right,
                              incorrect: !question_body.is_right
                              }">
                              {{ question_body.is_right ? 'Верно' : 'Неверно' }}</span>
                  </div>
                  <div class="question_text" translate="no" [innerHTML]="question_body.text | safeHtml"></div>

                  <div class="question_info">
                    {{question_body.type === 'matching' ? 'Сопоставьте значения левой и правой части'
                    : question_body.type === 'input' ? 'Введите число'
                      : 'Варианты ответа:'}}
                    <span *ngIf="question_body.type === 'checkbox'"> (выбери несколько вариантов ответа)</span>
                    <span *ngIf="question_body.type === 'radio'"> (выбери один правильный ответ)</span>
                    <span *ngIf="question_body.type === 'sort'"> (сортировка по приоритету)</span>
                    <span *ngIf="question_body.type === 'rating'"> (поставьте оценку)</span>
                  </div>
                  <div class="question_options">
                    <!--Rating-->
                    <ng-container *ngIf="question_body.type === 'rating'">
                      <mat-slider class="custom-slider"
                                  min="{{question_body.options[0].text}}"
                                  max="{{question_body.options[question_body.options.length - 1].text}}"
                                  step="1"
                                  thumbLabel="true"
                                  tickInterval="1"
                                  [disabled]="true"
                      ></mat-slider>
                    </ng-container>

                    <!--Radio-->
                    <ng-container *ngIf="question_body.type === 'radio'">
                      <mat-radio-group class="options-group" [disabled]="true">
                        <mat-radio-button
                          value="{{option.id}}"
                          translate="no"
                          class="options-button"
                          [checked]="option.is_selected"
                          [ngClass]="{correct: option.is_right, incorrect: option.is_selected && !option.is_right}"
                          *ngFor="let option of question_body.options; let i = index"
                        >
                          <span [innerHTML]="option.text | safeHtml"></span>
                        </mat-radio-button>
                      </mat-radio-group>
                    </ng-container>

                    <!--Checkbox-->
                    <ng-container *ngIf="question_body.type === 'checkbox'">
                      <div class="options-group">
                        <mat-checkbox
                          [disabled]="true"
                          value="{{option.id}}"
                          translate="no"
                          class="options-button"
                          [checked]="option.is_selected"
                          [ngClass]="{correct: option.is_selected && option.is_right, incorrect: option.is_selected && !option.is_right}"
                          *ngFor="let option of question_body.options; let i = index"
                        >
                          <span [innerHTML]="option.text | safeHtml"></span>
                        </mat-checkbox>
                      </div>
                    </ng-container>

                    <!--Sort-->
                    <ng-container *ngIf="question_body.type === 'sort'">
                      <div class="sort_container">
                        <div class="number_container">
                          <div class="number" *ngFor="let number of question_body.options; let i = index">{{i + 1}}</div>
                        </div>
                        <div cdkDropList class="options-group">
                          <div
                            translate="no"
                            cdkDrag
                            [cdkDragDisabled]="true"
                            class="options-button options-DropList"
                            [ngClass]="{correct: option.is_selected && option.is_right, incorrect: option.is_selected && !option.is_right}"
                            *ngFor="let option of question_body.options"
                          >
                            <span class="text disabled" [innerHTML]="option.text | safeHtml"></span>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="question_body.type === 'matching'">
                      <div class="matching_container">
                        <div class="options-group for_matching">
                          <div
                            translate="no"
                            class="options-button half_left options-DropList default_cursor"
                            *ngFor="let option of question_body.options | halfArray: 'left'; let i = index"
                          >
                            <span class="text" [innerHTML]="option.text | safeHtml"></span>
                          </div>
                        </div>
                        <div class="equals">
                          <div class="equal" *ngFor="let equal of question_body.options | halfArray: 'left';"></div>
                        </div>
                        <div class="options-group for_matching">
                          <div
                            translate="no"
                            class="options-button options-DropList default_cursor"
                            [ngClass]="{correct: option.is_selected && option.is_right, incorrect: option.is_selected && !option.is_right}"
                            *ngFor="let option of question_body.options | halfArray: 'right'; let i = index"
                          >
                            <span class="text" [innerHTML]="option.text | safeHtml"></span>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <!--open-->
                    <div class="options-textarea" *ngIf="question_body.type === 'open'">
                                    <textarea
                                      type="text"
                                      placeholder="Введите текст ответа"
                                      [value]="question_body.open"
                                      [ngStyle]="{cursor: 'default'}"
                                      [readOnly]="true"
                                    ></textarea>
                    </div>

                    <ng-container *ngIf="question_body.type === 'input'">
                      <div class="input" [ngClass]="{correct: question_body.is_right, incorrect: !question_body.is_right}">{{'Верный ответ:' + question_body.options[0].text + ' Ваш ответ:' + question_body.input}}</div>
                    </ng-container>

                  </div>
                </div>
              </mat-tab>
            </ng-container>

          </mat-tab-group>
        </section>
      <app-scroll-to-top [workspace]="workspace"></app-scroll-to-top>
    </div>

</div>
