<div class="custom_dialog team">
  <ng-scrollbar class="team_scrollbar">
    <div class="user_container" *ngIf="profileInfo">
      <div class="avatar_container">
        <div class="avatar" [ngStyle]="{'backgroundImage': 'url(' + (profileInfo.photo !== null ? (profileInfo.photo) : '/assets/images/icons/avatar.png') + ')'}"></div>
        <div class="button" *ngIf="extraService.profileInfo.role === 'ADMIN'" (click)="updateUser(profileInfo.id)">РЕДАКТИРОВАТЬ</div>
      </div>
      <div class="user_info_container">
        <div class="info">
          <div class="name">{{profileInfo.surname}} {{profileInfo.name}}</div>
          <div class="desc_container" *ngIf="profileInfo.email">
            <span>ЛОГИН, E-MAIL:</span>
            <div class="desc blue"><a [href]="'mailto:'+profileInfo.email">{{profileInfo.email}}</a></div>
          </div>
          <div class="desc_container" *ngIf="profileInfo.phone">
            <span>ТЕЛЕФОН:</span>
            <div class="desc blue"><a [href]="'tel:'+profileInfo.phone">{{profileInfo.phone}}</a></div>
          </div>
          <div class="desc_container" *ngIf="profileInfo.city">
            <span>ГОРОД:</span>
            <div class="desc">{{profileInfo.city.title}}</div>
          </div>
          <div class="desc_container" *ngIf="profileInfo.regions">
            <span>РЕГИОН:</span>
            <div class="desc">
              <div *ngFor="let region of profileInfo.regions">{{region.title}}</div>
            </div>
          </div>
          <div class="desc_container" *ngIf="profileInfo.district">
            <span>МЕГАРЕГИОН:</span>
            <div class="desc">{{profileInfo.district}}</div>
          </div>
          <div class="desc_container" *ngIf="profileInfo.position">
            <span>ДОЛЖНОСТЬ:</span>
            <div class="desc">{{profileInfo.position.title}}</div>
          </div>
          <div class="desc_container">
            <span>ТЕСТ OFF:</span>
            <div class="desc">
              <mat-checkbox [disabled]="true" [checked]="profileInfo.test_off"></mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tests_tables_container">
      <div class="table" *ngIf="testComplited">
        <span class="desc">Пройденные курсы и тесты</span>
        <div class="horizontal_line"></div>
        <div class="tr"  *ngFor="let test of testComplited; let i = index">
          <div class="td title">{{test.document__title}}</div>
          <div class="td deadline"><span>Результат: {{test.attempts | maxResult}}%</span></div>
                    <div class="td attempts">
                      Попытки:
                      <div class="bullet_attempt_container">
                        <div class="bullet_attempt" *ngFor="let bulliets of (test.attempts.length) | times"
                             [ngClass]="{
                                  gray: !test.attempts[bulliets - 1].is_over || test.attempts[bulliets - 1].result === 0,
                                  crimson: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 1 && test.attempts[bulliets - 1].result <= 49,
                                  red: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 50 && test.attempts[bulliets - 1].result <= 79,
                                  yellow: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 80 && test.attempts[bulliets - 1].result <= 89,
                                  light_green: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 90 && test.attempts[bulliets - 1].result <= 99,
                                  green: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result === 100
                                  }"></div>
                        <div class="bullet_attempt" *ngFor="let bulliets of (test.number_attempts - test.attempts.length) | times"></div>
                      </div>
                    </div>
          <div class="td empty"></div>
          <div class="td button" *ngIf="extraService.profileInfo.role === 'ADMIN'" (click)="changeDeadline( test.profile_id, test.section, test.deadline)">ПРОДЛИТЬ ДЕДЛАЙН</div>
          <div class="td button" *ngIf="extraService.profileInfo.role === 'ADMIN'" (click)="changeAttempts( test.profile_id, test.section, test.number_attempts)">ДОБАВИТЬ ПОПЫТКИ</div>
        </div>
      </div>
      <div class="table" *ngIf="testNonStarted">
        <span class="desc">Назначенные тесты и курсы</span>
        <div class="horizontal_line"></div>
        <div class="tr" *ngFor="let test of testNonStarted">
          <div class="td title">{{test.document__title}}</div>
          <div class="td deadline"></div>
          <div class="td attempts">
            <div class="bullet_attempt_container">
            </div>
          </div>
          <div class="td empty"></div>
          <div class="td button" *ngIf="extraService.profileInfo.role === 'ADMIN'" (click)="changeDeadline(test.profile_id, test.section, test.deadline)">ПРОДЛИТЬ ДЕДЛАЙН</div>
          <div class="td button" *ngIf="extraService.profileInfo.role === 'ADMIN'" (click)="changeAttempts(test.profile_id, test.section, test.number_attempts)">ДОБАВИТЬ ПОПЫТКИ</div>
        </div>
      </div>
      <div class="table" *ngIf="testAvailable">
        <span class="desc">Незавершенные тесты и курсы</span>
        <div class="horizontal_line"></div>
        <div class="tr" *ngFor="let test of testAvailable; let i = index">
          <div class="td title">{{test.document__title}}</div>
          <div class="td deadline" [ngClass]="{no_show: !test.attempts[test.attempts.length - 1].result}"><span>Результат:&nbsp;{{test.attempts[test.attempts.length - 1].result}}%</span></div>
                    <div class="td attempts">
                      Попытки:
                      <div class="bullet_attempt_container">
                        <div class="bullet_attempt" *ngFor="let bulliets of (test.attempts.length) | times"
                             [ngClass]="{
                                  gray: !test.attempts[bulliets - 1].is_over || test.attempts[bulliets - 1].result === 0,
                                  crimson: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 1 && test.attempts[bulliets - 1].result <= 49,
                                  red: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 50 && test.attempts[bulliets - 1].result <= 79,
                                  yellow: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 80 && test.attempts[bulliets - 1].result <= 89,
                                  light_green: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 90 && test.attempts[bulliets - 1].result <= 99,
                                  green: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result === 100
                                  }"></div>
                        <div class="bullet_attempt" *ngFor="let bulliets of (test.number_attempts - test.attempts.length) | times"></div>
                      </div>
                    </div>
          <div class="td empty"></div>
          <div class="td button" *ngIf="extraService.profileInfo.role === 'ADMIN'" (click)="changeDeadline(test.profile_id, test.section, test.deadline)">ПРОДЛИТЬ ДЕДЛАЙН</div>
          <div class="td button" *ngIf="extraService.profileInfo.role === 'ADMIN'" (click)="changeAttempts(test.profile_id, test.section, test.number_attempts)">ДОБАВИТЬ ПОПЫТКИ</div>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>
