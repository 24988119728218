import { Component, OnInit } from '@angular/core';

import { ExtraService } from 'services';

import { StatisticsService } from 'services/api';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup } from '@angular/forms'

@Component({
  selector: 'app-infographics',
  templateUrl: 'infographics.component.html',
  styleUrls: ['infographics.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class InfographicsComponent implements OnInit {

  showPrev = 'show';

  constructor(
    public extraService: ExtraService,
    private statisticsService: StatisticsService,
  ) {
  }

  formFilter = null;
  documents = undefined

  regions = undefined
  brands = undefined
  channels = undefined
  positions = undefined
  statuses = undefined

  tableColumns = undefined
  tableRows = undefined

  infoTeamResult: any
  infoTeamPassed: any
  infoTeamNumber: any

  inactiveSubmit = false

  searchStringDocument = '';

  formFilterSearch() {
    this.statisticsService.getInfographicsTable(this.formFilter.value).subscribe((responseTable: any) => {
      this.tableColumns = responseTable.columns
      this.tableRows = responseTable.rows
      this.infoTeamResult = responseTable.statistics.result
      this.infoTeamPassed = responseTable.statistics.passed
      this.infoTeamNumber = responseTable.statistics.number
      this.inactiveSubmit = false;
    })
  }

  clearSearchDocument() {
    this.searchStringDocument = '';
    this.formFilter.value.search = '';
    this.formFilterSearch();
  }

  ngOnInit() {
    this.inactiveSubmit = true;
    this.statisticsService.getInfographicsParams('main').subscribe((response: any) => {
      this.documents = response.documents
      this.brands = response.brands
      this.regions = response.regions
      this.channels = response.channels
      this.positions = response.positions
      this.statuses = response.statuses
      this.formFilter = new FormGroup({
        'brand_id': new FormControl(null),
        'channel_id': new FormControl(null),
        'position_id': new FormControl(null),
        'region_id': new FormControl(null),
        'doc_id': new FormControl(null),
        'status_id': new FormControl(null),
        'search': new FormControl(''),
      })
      this.formFilterSearch();
    })
  }

}
