import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe( {
  name: 'chatDateFormat',
} )

export class ChatDateFormatPipe implements PipeTransform{
  transform(timestamp: any): any {
    return moment(timestamp).locale('RU').format('D MMM YYYY, HH:mm');
  }
}
