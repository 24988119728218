<div class="header_osn" [ngClass]="{'doc': noShowMenu}">
  <div class="logo_container" [ngClass]="{'doc': noShowMenu}">
    <div class="logo" (click)="goMain()"></div>
    <div class="logo" (click)="goMain()"></div>
    <div class="logo" (click)="goMain()"></div>
    <div class="logo" (click)="goMain()"></div>
<!--    <div class="logo" (click)="goMain()"></div>-->
  </div>
  <div class="user_container" [ngClass]="{'doc': noShowMenu}" (click)="goPersonal()" *ngIf="extraService.profileInfo">
    <div class="avatar" [ngStyle]="{'backgroundImage': 'url(' + (extraService.profileInfo.photo !== null ? (extraService.profileInfo.photo) : '/assets/images/icons/avatar_none.png') + ')'}"></div>
    <div class="name">{{extraService.profileInfo.name + ' ' + extraService.profileInfo.surname}}</div>
  </div>
  <div class="logout" (click)="logout()" [ngClass]="{'doc': noShowMenu}"></div>
  <a #support href="mailto:support@ppdportal.loreal.com.ru" style="display: none"></a>
  <div class="support" (click)="getSupport()"></div>
  <div class="menu_burger" (click)="openMenu()" [ngClass]="{'close_menu': extraService.showUserMobile, 'doc': noShowMenu}"></div>
</div>
