import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root',
})

export class ExtraService {
  // Для отображения меню на мобильных девайсах
  openMenu = false;
  showUserMobile = false;

  // Информация о пользователе (запрос вызывается в 'header')
  profileInfo: any;

  // Массив попыток (из "Статистики")
  attempts: any;

  inDocument = 'section'; // Проверка на нахождение в документе (для метрики)

  nowSections: any;

  scrollButtonTop$: Subject<any> = new Subject();
  scrollMenuTop: number;
}
