<div class="side_menu_container" *ngIf="extraService.openMenu" [@show_preview]="showPrev">
  <div class="user_container" *ngIf="extraService.showUserMobile">
    <div *ngIf="extraService.profileInfo">
      <div class="avatar" [ngStyle]="{'backgroundImage': 'url(' + (extraService.profileInfo.photo !== null ? (extraService.profileInfo.photo) : '/assets/images/icons/avatar_none.png') + ')'}"></div>
      <div class="name_and_button">
        <span>{{extraService.profileInfo.name}}</span>
        <span>{{extraService.profileInfo.surname}}</span>
        <div class="user_button" (click)="goPersonal('personal/profile')">Мой профиль</div>
      </div>
    </div>
  </div>
  <div class="menu" #menu scrollMenuListener *ngIf="sections">
    <div *ngFor="let section of sections">
      <div class="side_menu_button" [ngClass]="{'active': section.reference === refName, 'close': section.is_closed, 'lftf': section.reference === 'lftf', 'main': section.reference === 'main'}" (click)="goPage(section)">
        <div class="count" *ngIf="!section.is_closed && section.count !== 0">+{{section.count}}</div>
        <div class="button_icon"
        [ngClass]="{
          'main': section.reference.includes('main'),
          'archive': section.reference.includes('archive'),
          'important': section.reference.includes('important'),
          'attestation': section.reference.includes('attestation'),
          'integration': section.reference.includes('integration'),
          'reports': section.reference.includes('reports'),
          'library': section.reference.includes('library'),
          'lftf': section.reference.includes('lftf'),
          'management': section.reference.includes('management'),
          'business': section.reference.includes('business'),
          'feedback': section.reference.includes('feedback'),
          'rating': section.reference.includes('rating'),
          'lock': section.is_closed
        }"
        ></div>
        <div class="button_name">{{section.title}}</div>
      </div>
    </div>
  </div>
  <div class="side_menu_button" *ngIf="extraService.showUserMobile" (click)="getSupport()">
    <a #support href="mailto:support@ppdportal.loreal.com.ru" style="display: none"></a>
    <div class="button_icon" [ngClass]="{'support': true}"></div>
    <div class="button_name">Тех. поддрежка</div>
  </div>
  <div class="side_menu_button" *ngIf="extraService.showUserMobile" (click)="logout()">
    <div class="button_icon" [ngClass]="{'logout': true}"></div>
    <div class="button_name">Выход</div>
  </div>
</div>
