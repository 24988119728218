import { Component, Input, OnInit } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ExtraService } from 'services'


@Component({
  selector: 'app-card-rating',
  templateUrl: 'card-rating.component.html',
  styleUrls: ['card-rating.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class CardRatingComponent implements OnInit {

  @Input() history: any

  showPrev = 'show';

  constructor(
    public extraService: ExtraService,
  ) {
  }

  ngOnInit() {

  }

}
