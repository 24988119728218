import {Component, ViewChild, Input} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {DialogService, ExtraService, ManagementService} from 'services';
import { ManagementApiService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AlertDialogComponent } from 'components/dialogs';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {NgxMaterialTimepickerTheme} from "ngx-material-timepicker";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-form-management',
  templateUrl: 'form-management.component.html',
  styleUrls: ['form-management.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
  providers:[
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})

export class FormManagementComponent {

  public Editor = ClassicEditor;

  configEditor = {
    toolbar: {
      viewportTopOffset: 80,
    },
    background: '#fa2c2c'
  }

  @Input() form = undefined;
  @Input() type = undefined;
  @Input() controls = undefined;
  @Input() section = undefined;
  @Input() id = undefined;

  @ViewChild( 'fileInput' ) fileInput;

  file: any;
  loadFile = '';
  files = [];

  errorMessage = '';

  inactiveSubmit = false;

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#424242',
      buttonColor: '#fff'
    },
    dial: {
      dialBackgroundColor: '#555',
    },
    clockFace: {
      clockFaceBackgroundColor: '#555',
      clockHandColor: '#9fbd90',
      clockFaceTimeInactiveColor: '#fff'
    }
  };

  public searchFilterCtrl: FormControl = new FormControl();
  searchString = '';

  constructor(
    public extraService: ExtraService,
    private managementApiService: ManagementApiService,
    public managementService: ManagementService,
    private dialogService: DialogService,
    private location: Location,
    private router: Router
  ) {
  }

  getControlsName(controlsArr) {
    return Object.keys(controlsArr);
  }

  timestampToMoment(value: number) {
    if (value) {
      return moment((value * 1000) / 1000);
    }
    return '';
  }

  timestampToTime(value: number) {
    if (value) {
      return moment((value * 1000) / 1000).locale('ru').format('LT')
    }
    return ''
  }

  dateChange(event, control) {
    control.setValue(moment(event.value.valueOf()));
    control.markAsDirty();
  }

  timeChange(event, control) {
    let dateWithTime = moment((control.value * 1000) / 1000).locale('ru').format('L') + ' ' + event
    control.setValue(moment(dateWithTime, 'DD.MM.YYYY HH:mm'));
    control.markAsDirty();
  }

  getFile() {
    this.fileInput.nativeElement.click();
  }

  processWebFile(event, controlName) {
    if (event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0];

      const reader: FileReader = new FileReader();
      reader.onload = (readerEvent: any) => {
        this.loadFile = readerEvent.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
      this.form.get(controlName).setValue(this.file);
      this.form.get(controlName).markAsDirty();
    }
  }

  changeDialogData(type) {
    let data = {};
    switch (type) {
      case 'add-folder':
        data = {
          title: 'Папка успешно создана',
          confirmButtonText: 'OK'
        }
        break
      case 'add-doc':
      case 'add-attestation':
        data = {
          title: 'Документ успешно создан',
          confirmButtonText: 'OK'
        }
        break
      case 'add-banner':
        data = {
          title: 'Баннер успешно создан',
          confirmButtonText: 'OK'
        }
        break
      case 'add-test':
      case 'add-attestation-test':
        data = {
          title: 'Тест успешно создан',
          confirmButtonText: 'OK'
        }
        break
      case 'edit-folder':
        data = {
          title: 'Папка успешно отредактирована',
          confirmButtonText: 'OK'
        }
        break
      case 'edit-doc':
      case 'edit-attestation':
        data = {
          title: 'Документ успешно отредактирован',
          confirmButtonText: 'OK'
        }
        break
      case 'edit-banner':
        data = {
          title: 'Баннер успешно отредактирован',
          confirmButtonText: 'OK'
        }
        break
      case 'edit-test':
      case 'edit-attestation-test':
        data = {
          title: 'Тест успешно отредактирован',
          confirmButtonText: 'OK'
        }
        break
      case 'add-elements':
        data = {
          title: 'Элемент успешно добавлен',
          confirmButtonText: 'OK'
        }
        break
      case 'add-notification':
        data = {
          title: 'Письмо успешно создано',
          confirmButtonText: 'OK'
        }
        break
      case 'edit-notification':
        data = {
          title: 'Письмо успешно изменено',
          confirmButtonText: 'OK'
        }
        break
      case 'error':
        data = {
          title: 'Произошла ошибка',
          confirmButtonText: 'OK'
        }
        break
      case 'error-form':
        data = {
          title: 'Проверьте правильность заполнения полей формы.',
          confirmButtonText: 'OK'
        }
        break
    }
    return data;
  }

  selectSectionChange(event) {
    if (typeof (event.value) === 'number') {
      return
    }
    this.managementService.changeSelectedType(event.value)
  }

  changeErrorMessage(control, beforeSubmit = false) {
    if (this.form.get(control).touched && this.form.get(control).invalid) {
      this.errorMessage = 'Заполните поле!'
      return true
    }
    if (beforeSubmit) {
      this.form.get(control).markAsTouched();
      this.changeErrorMessage(control);
    }
    return false
  }

  validationForm(form) {
    if (form.status === 'INVALID') {
      this.getControlsName(this.form.controls).forEach((controlName) => {
        if (this.form.get(controlName).errors) {
          this.changeErrorMessage(controlName, true);
        }
      });
      return false;
    }
    return true;
  }

  submit() {
    this.inactiveSubmit = true;
    if (this.validationForm(this.form)) {
      const formData: FormData = new FormData();
      this.managementService.collectObject(this.type, formData, this.form);
      switch (this.type) {
        case 'add-folder':
          formData.append('section', this.section);
          this.managementApiService.managementCreateFolder(formData).subscribe(() => {
            this.inactiveSubmit = false;
            this.router.navigate(['section-management/' + this.section]);
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type));
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            this.inactiveSubmit = false;
          });
          break
        case 'edit-folder':
          this.managementApiService.managementUpdateFolder(formData, this.id).subscribe(() => {
            this.inactiveSubmit = false;
            this.router.navigate(['section-management/' + this.section]);
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type));
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            this.inactiveSubmit = false;
          });
          break
        case 'add-doc':
        case 'add-attestation':
          formData.append('section', this.section);
          this.managementApiService.managementCreateDoc(this.section, formData).subscribe((response: any) => {
            this.inactiveSubmit = false;
            this.id = response.id;
            this.router.navigate(['section-management/' + this.section + '/' + 'edit-doc' + '/' + this.id]);
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type));
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            this.inactiveSubmit = false;
          });
          break
        case 'edit-doc':
          this.managementApiService.managementUpdateDoc(this.section, formData, this.id).subscribe(() => {
            this.inactiveSubmit = false;
            this.router.navigate(['section-management/' + this.section]);
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type));
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            this.inactiveSubmit = false;
          });
          break
        case 'add-test':
          this.managementApiService.managementCreateTest(formData).subscribe((response: any) => {
            this.inactiveSubmit = false;
            this.id = response.id;
            this.router.navigate(['section-management/' + this.section + '/' + 'edit-test' + '/' + this.id]);
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type));
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            this.inactiveSubmit = false;
          });
          break
        case 'add-attestation-test':
          this.managementApiService.managementCreateAttestationTest(formData).subscribe((response: any) => {
            this.inactiveSubmit = false;
            this.id = response.id;
            this.router.navigate(['section-management/' + this.section + '/' + 'edit-attestation-test' + '/' + this.id]);
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type));
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            this.inactiveSubmit = false;
          });
          break
        case 'edit-test':
          this.managementApiService.managementUpdateTest(formData, this.id).subscribe(() => {
            this.inactiveSubmit = false;
            this.router.navigate(['section-management/' + this.section]);
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type));
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            this.inactiveSubmit = false;
          });
          break
        case 'edit-attestation-test':
          this.managementApiService.managementUpdateAttestationTest(formData, this.id).subscribe(() => {
            this.inactiveSubmit = false;
            this.router.navigate(['section-management/' + this.section]);
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type));
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            this.inactiveSubmit = false;
          });
          break
        case 'add-banner':
          this.managementApiService.managementCreateBanner(formData).subscribe((response: any) => {
            this.inactiveSubmit = false;
            this.id = response.id;
            this.router.navigate(['section-management/' + this.section + '/' + 'edit-banner' + '/' + this.id]);
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type));
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            this.inactiveSubmit = false;
          });
          break
        case 'edit-banner':
          this.managementApiService.managementUpdateBanner(formData, this.id).subscribe(() => {
            this.inactiveSubmit = false;
            this.router.navigate(['section-management/' + this.section]);
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type));
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            this.inactiveSubmit = false;
          });
          break
        case 'add-notification':
          formData.append('section', this.section);
          this.managementApiService.managementNotificationCreate(formData).subscribe((response: any) => {
            this.inactiveSubmit = false;
            this.id = response.id;
            this.router.navigate(['section-management/' + this.section + '/' + 'edit-notification' + '/' + this.id]);
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type));
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            this.inactiveSubmit = false;
          });
          break
        case 'edit-notification':
          this.managementApiService.managementUpdateNotificationDraft(formData, this.id).subscribe(() => {
            this.inactiveSubmit = false;
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type));
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            this.inactiveSubmit = false;
          });
          break
      }
    } else {
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error-form'));
      this.inactiveSubmit = false;
    }
  }
}
