<div class="statistics_container" [@show_preview]="showPrev">

    <div class="score_container">
        <span class="desc">Мои баллы</span>
        <span>
            <span class="number_score">{{score}}</span> {{score_desc}}
        </span>
    </div>

    <div class="course_list_container">
        <span class="desc">Список моих курсов</span>
        <div class="table_course_list" *ngIf="testComplited">
            <p>Пройденные</p>
            <div class="horizontal_line"></div>
            <div class="tr_course_list" *ngFor="let test of testComplited; let i = index">
                <div class="td_course_list title">{{test.document__title}}</div>
                <div class="td_course_list result">Результат: {{test.attempts | maxResult}}%</div>
                <div class="td_course_list attempts">
                    Попытки:
                    <div class="bullet_attempt_container">
                    <div class="bullet_attempt" *ngFor="let bulliets of (test.attempts.length) | times"
                         [ngClass]="{
                        gray: !test.attempts[bulliets - 1].is_over || test.attempts[bulliets - 1].result === 0,
                        crimson: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 1 && test.attempts[bulliets - 1].result <= 49,
                        red: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 50 && test.attempts[bulliets - 1].result <= 79,
                        yellow: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 80 && test.attempts[bulliets - 1].result <= 89,
                        light_green: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 90 && test.attempts[bulliets - 1].result <= 99,
                        green: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result === 100
                        }"></div>
                    <div class="bullet_attempt" *ngFor="let bulliets of (test.number_attempts - test.attempts.length) | times"></div>
                    </div>
                </div>
                <div class="td_course_list button_list_container">
                    <div class="button_list" [ngClass]="{no_show: !test.show_answers}" (click)="goAttempts(test.show_answers, test.section, test.attempts)">Посмотреть ответы</div>
                    <div class="button_list" (click)="goDocument(test.section, test.document)">На страницу курса</div>
                </div>
            </div>
        </div>
        <div class="table_course_list" *ngIf="testAvailable">
            <p>Незавершенные</p>
            <div class="horizontal_line"></div>
            <div class="tr_course_list" *ngFor="let test of testAvailable; let i = index">
                <div class="td_course_list title">{{test.document__title}}</div>
                <div class="td_course_list result" [ngClass]="{no_show: !test.attempts[test.attempts.length - 1].result}">Результат: {{test.attempts[test.attempts.length - 1].result}}%</div>
                <div class="td_course_list attempts">
                    Попытки:
                    <div class="bullet_attempt_container">
                    <div class="bullet_attempt" *ngFor="let bulliets of (test.attempts.length) | times"
                    [ngClass]="{
                        gray: !test.attempts[bulliets - 1].is_over || test.attempts[bulliets - 1].result === 0,
                        crimson: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 1 && test.attempts[bulliets - 1].result <= 49,
                        red: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 50 && test.attempts[bulliets - 1].result <= 79,
                        yellow: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 80 && test.attempts[bulliets - 1].result <= 89,
                        light_green: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result >= 90 && test.attempts[bulliets - 1].result <= 99,
                        green: test.attempts[bulliets - 1].is_over && test.attempts[bulliets - 1].result === 100
                        }"></div>
                    <div class="bullet_attempt" *ngFor="let bulliets of (test.number_attempts - test.attempts.length) | times"></div>
                    </div>
                </div>
                <div class="td_course_list button_list_container">
                    <div class="button_list" [ngClass]="{no_show: test.attempts[test.attempts.length - 1].is_over}" (click)="goTest(test.attempts[test.attempts.length - 1].is_over, test.id)">Вернуться к прохождению</div>
                    <div class="button_list" (click)="goDocument(test.section, test.document)">На страницу курса</div>
                </div>
            </div>
        </div>
        <div class="table_course_list" *ngIf="testNonStarted">
            <p>Назначенные</p>
            <div class="horizontal_line"></div>
            <div class="tr_course_list" *ngFor="let test of testNonStarted">
                <div class="td_course_list title">{{test.document__title}}</div>
                <div class="td_course_list result"></div>
                <div class="td_course_list attempts">
                    Попытки:
                    <div class="bullet_attempt_container">
                    <div class="bullet_attempt" *ngFor="let bulliets of test.number_attempts | times"></div>
                    </div>
                </div>
                <div class="td_course_list button_list_container">
                    <div class="button_list no_show">Посмотреть ответы</div>
                    <div class="button_list" (click)="goDocument(test.section, test.document)">На страницу курса</div>
                </div>
            </div>
        </div>
    </div>

    <div class="history_enrollment_list_container">
        <span class="desc">История начислений</span>
        <div class="table_history_enrollment_list" *ngIf="historyScore">
            <div class="tr_desc_list">
                <p>Дата</p>
                <p>Наименование</p>
                <p>Балл</p>
            </div>
            <div class="horizontal_line"></div>
            <div class="tr_history_enrollment_list" *ngFor="let history of historyScore">
                <div class="td_history_enrollment_list">{{ history.date | dateFormat:'mediumDate' }}</div>
                <div class="td_history_enrollment_list">{{ history.text }}</div>
                <div class="td_history_enrollment_list">{{ history.value }}</div>
            </div>
        </div>
    </div>

</div>
