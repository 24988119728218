import {Component, OnInit, ViewChild} from '@angular/core';

import { ExtraService } from "services";

import { animate, state, style, transition, trigger } from '@angular/animations';
import {ActivatedRoute, Router} from "@angular/router";
import {BaseService} from "services/api";
import {FormControl, FormGroup} from "@angular/forms";
import {Location} from "@angular/common";

@Component({
  selector: 'app-business-code',
  templateUrl: 'business-code.component.html',
  styleUrls: ['business-code.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class BusinessCodeComponent {

  @ViewChild('workspace') workspace;

  showPrev = 'show';

  section: string;
  offset: number = 0;
  limit: number = 50;
  documents = undefined;
  folders = undefined;
  checkBack = false;
  folderId = undefined;

  endScroll = false

  formSearch: FormGroup;
  inactiveSearch = false;
  offsetSearch: number = 0;
  limitSearch: number = 50;
  searchValue = '';
  searched = false;

  viewBack = false

  constructor(
    public extraService: ExtraService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private baseService: BaseService
  ) {
  }

  goBack() {
    this.formSearch = new FormGroup({
      'query': new FormControl('')
    });
    this.route.url.subscribe((urlSegment: any) => {
      this.section = urlSegment[0].path;
    });
    this.route.params.subscribe((params: any) => {
      this.checkBack = params.id !== 'null';
      this.baseService.getDocumentList(this.section, this.offset, this.limit, params.id).subscribe((response: any) => {
        this.folders = response.folders;
        this.documents = response.documents;
        if (response.documents.length < this.limit) {
          this.endScroll = true;
        }
      });
    })
    this.viewBack = false
  }

  searchForm() {
    this.inactiveSearch = true;
    if (this.formSearch.get('query').value === '') {
      this.searched = false;
      this.baseService.getDocumentList(this.section, this.offset, this.limit).subscribe((response: any) => {
        this.folders = response.folders;
        this.documents = response.documents;
        if (response.documents.length < this.limit) {
          this.endScroll = true;
        }
        this.viewBack = false
      });
    } else {
      this.offsetSearch = 0;
      this.limitSearch = 50;
      this.baseService.documentSearch(this.formSearch.get('query').value, this.offsetSearch, this.limitSearch, 'business').subscribe((response: any) => {
        this.documents = response.documents;
        this.folders = [];
        this.searched = true;
        this.inactiveSearch = false;
        this.searchValue = this.formSearch.get('query').value;
        this.formSearch.get('query').setValue('');
      });
      this.viewBack = true
    }
  }

  paginationOn() {
    if (this.searched) {
      this.offsetSearch += this.limitSearch;
      this.baseService.documentSearch(this.searchValue, this.offsetSearch, this.limitSearch, this.section).subscribe((response: any) => {
        Array.prototype.push.apply(this.documents, response);
        if (response.length < this.limitSearch) {
          this.endScroll = true;
        }
      })
    } else {
      this.offset += this.limit;
      if (this.folderId) {
        this.baseService.getDocumentList(this.section, this.offset, this.limit, this.folderId).subscribe((response: any) => {
          Array.prototype.push.apply(this.folders, response.folders);
          Array.prototype.push.apply(this.documents, response.documents);
          if (response.documents.length < this.limit) {
            this.endScroll = true;
          }
        });
      } else {
        this.baseService.getDocumentList(this.section, this.offset, this.limit).subscribe((response: any) => {
          Array.prototype.push.apply(this.documents, response.documents);
          if (response.documents.length < this.limit) {
            this.endScroll = true;
          }
        });
      }
    }
  }

  goDocument(info: any) {
    if (!info.is_view) {
      this.baseService.getDocumentViewed(info.id).subscribe(() => {
          this.router.navigate([this.section + '/documents/' + info.id]);
        },
        () => {
          return;
        }
      )
    } else {
      this.router.navigate([this.section + '/documents/' + info.id]);
    }
    this.extraService.inDocument = 'document';
  }

  goFolder(folderId) {
    if (this.folderId === undefined) {
      this.folderId = folderId
      document.getElementById(folderId).style.display = 'block'
      this.endScroll = false;
      this.offset = 0;
      this.folderId = folderId;
      this.baseService.getDocumentList(this.section, this.offset, this.limit, folderId).subscribe((response: any) => {
        this.documents = response.documents;
        console.log(this.documents)
        if (response.documents.length < this.limit) {
          this.endScroll = true;
        }
      });
    } else {
      if (this.folderId === folderId) {
        document.getElementById(this.folderId).style.display = 'none'
      } else {
        document.getElementById(this.folderId).style.display = 'none'
        document.getElementById(folderId).style.display = 'block'
        this.endScroll = false;
        this.offset = 0;
        this.folderId = folderId;
        this.baseService.getDocumentList(this.section, this.offset, this.limit, folderId).subscribe((response: any) => {
          this.documents = response.documents;
          console.log(this.documents)
          if (response.documents.length < this.limit) {
            this.endScroll = true;
          }
        });
      }
    }
  }

  ngOnInit() {
    this.formSearch = new FormGroup({
      'query': new FormControl('')
    });
    this.route.url.subscribe((urlSegment: any) => {
      this.section = urlSegment[0].path;
    });
    this.route.params.subscribe((params: any) => {
      this.checkBack = params.id !== 'null';
      this.baseService.getDocumentList(this.section, this.offset, this.limit, params.id).subscribe((response: any) => {
        this.folders = response.folders;
        this.documents = response.documents;
        if (response.documents.length < this.limit) {
          this.endScroll = true;
        }
      });
    })
  }

}
