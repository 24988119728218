import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from 'services/api';
import { DialogService } from 'services';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { AlertDialogComponent } from 'components/dialogs';
@Component({
  selector: 'app-auth-management',
  templateUrl: 'auth-management.component.html',
  styleUrls: ['auth-management.component.scss'],
  animations: [
    trigger('show_form', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(600, style({
          opacity: 1,
        })),
      ]),
      transition('none => show', [
        style({
          opacity: 0,
        }),
        animate(600, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class AuthManagementComponent implements OnInit{

  passwFieldType: 'password' | 'text' = 'password';

  showForm = 'show';

  auth: FormGroup | any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService,
  ) {}

  showPassw() {
    this.passwFieldType = (this.passwFieldType === 'password') ? 'text' : 'password';
  }

  onSubmit(): void {
    this.authService.authManagementSignIn(this.auth.get('email').value.toLowerCase(), this.auth.get('password').value).subscribe((response: any) => {
      this.authService.saveNewManagementTokens(response);
      this.router.navigate(['management']);
    }, (() => {
      const data = {
        'title': 'Произошла ошибка. Попробуйте еще раз.',
        'confirmButtonText': 'ОК'
      }
      this.dialogService.openDialog(AlertDialogComponent, data);
    }));
  }

  ngOnInit() {
    this.auth = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }
}

