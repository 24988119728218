import {Component, OnInit} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ExtraService } from 'services'
import { BaseService } from 'services/api'
import { FormControl, FormGroup } from '@angular/forms'


@Component({
  selector: 'app-hall-of-fame',
  templateUrl: 'hall-of-fame.component.html',
  styleUrls: ['hall-of-fame.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class HallOfFameComponent implements OnInit {

  showPrev = 'show';
  history: undefined

  offset: number = 0;
  limit: number = 3;
  endScroll: boolean = false;

  formFilter = undefined;
  intervals = undefined;

  constructor(
    public extraService: ExtraService,
    private baseService: BaseService,
  ) {
  }

  paginationOn() {
    if (this.formFilter.value.finish === null) {
      this.offset += this.limit;

      this.baseService.ratingLeaders(this.limit, this.offset, this.formFilter.value).subscribe((response: any) => {
        Array.prototype.push.apply(this.history, response.leaders);

        if (response.leaders.length < this.limit) {
          this.endScroll = true;
        }
      })
    }

  }

  searchLeaders(event) {
    this.offset = 0;
    this.endScroll = false;

    if (event) {
      this.formFilter.reset();
    }

    this.baseService.ratingLeaders(this.limit, this.offset, this.formFilter.value).subscribe((response: any) => {
      this.history = response.leaders
    })
  }

  ngOnInit() {
    this.baseService.ratingParams().subscribe((response: any) => {

      this.intervals = response.date

      this.formFilter = new FormGroup({
        'start': new FormControl(null),
        'finish': new FormControl(null),
      })

      this.baseService.ratingLeaders(this.limit, this.offset, this.formFilter.value).subscribe((response: any) => {
        this.history = response.leaders
      })
    })
  }
}
