import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanActivate } from '@angular/router';
import { AuthService } from 'services/api';
import { StorageService } from 'services';
import { Router, NavigationCancel } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})

export class AuthManagementGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private storage: StorageService,
    private matDialogService: MatDialog,
  ) {}


  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable( (subscriber) => {
      if (this.storage.getValue('token-management') === '') {
        this.router.navigate(['/auth-management']);
        // Закрываем все открытые окна
        this.matDialogService.closeAll();
        subscriber.next(false);
      } else {
        subscriber.next(true);
      }
    });
  }
}
