import {Component, OnInit, ViewChild} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { DialogService, ExtraService } from 'services'
import { BaseService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';

import SwiperCore, { Autoplay, Pagination } from 'swiper/core';
import { DocumentsViewedDialogComponent } from 'components/dialogs'
import { MatDialog } from '@angular/material/dialog'

SwiperCore.use([Autoplay, Pagination]);


@Component({
  selector: 'app-main',
  templateUrl: 'main.component.html',
  styleUrls: ['main.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class MainComponent implements OnInit {

  @ViewChild('workspace') workspace;

  showPrev = 'show';

  section: string;

  bannersType: any;
  banners: any;

  offset: number = 0;
  limit: number = 10;
  documents = undefined;
  endScroll: boolean = false;

  constructor(
    public extraService: ExtraService,
    private baseService: BaseService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
  }

  paginationOn() {
    this.offset += this.limit;
    this.baseService.getDocumentList(this.section, this.offset, this.limit).subscribe((response: any) => {
      Array.prototype.push.apply(this.documents, response.documents);
      if (response.documents.length < this.limit) {
        this.endScroll = true;
      }
    });
  }

  goDocument(info: any) {
    if (!info.is_view) {
      this.baseService.getDocumentViewed(info.id).subscribe(() => {
        this.router.navigate([this.section + '/documents/' + info.id]);
      },
      () => {
        return;
      }
      )
    } else {
      this.router.navigate([this.section + '/documents/' + info.id]);
    }
    this.extraService.inDocument = 'document';
  }

  goToLink(link) {
    if (link) {
      location.assign(link);
    }
  }

  getContentOnInit() {

  }

  ngOnInit() {
    this.baseService.systemMessageAuth('auth').subscribe((response: any) => {
      if(response == null || response.is_viewed) {
        this.resize();
        this.route.url.subscribe((urlSegment: any) => {
          this.section = urlSegment[0].path;
        });
        this.baseService.getDocumentList(this.section, this.offset, this.limit).subscribe((response: any) => {
          this.documents = response.documents;
          if (response.documents.length < this.limit) {
            this.endScroll = true;
          }
        });
        this.baseService.getBanners().subscribe((response: any) => {
          this.banners = response;
        });
      } else {
        let data = {
          'title': response.title,
          'text': response.text,
          'id': response.id,
          'instructions': response.instructions,
          'check_agree': response.check_agree,
          'button': response.button,
        }
        const dialogRef = this.dialog.open(DocumentsViewedDialogComponent, {data, disableClose: true});

        dialogRef.afterClosed().subscribe( () => {
          this.ngOnInit()
        })
      }
    });
  }

  resize() {
    let clientWidth = document.documentElement.clientWidth;
    if (clientWidth >= 1024) {
      this.bannersType = 'desktop';
    } else if (clientWidth <= 1023 && window.innerWidth >= 600) {
      this.bannersType = 'tablet';
    } else if (clientWidth <= 599) {
      this.bannersType = 'phone';
    }
  }

}
