import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AlertDialogComponent} from "components/dialogs";
import {DialogService} from "services";

@Component({
  selector: 'app-video-quality',
  templateUrl: './video-quality.component.html',
  styleUrls: ['./video-quality.component.scss'],
})

export class VideoQualityComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
  ) {
  }

  inactiveDownload = false;

  @Input() arrayVideos = [];
  @Input() blockTitle: string;

  selectedQuality: any;

  @Output() emitQuality: EventEmitter<any> = new EventEmitter<any>()

  selectionChange(event) {
    this.emitQuality.emit(event.value)
  }

  downloadVideo() {
    if (!this.inactiveDownload) {
      this.inactiveDownload = true;
      document.getElementById('title' + this.selectedQuality.src).style.display = 'none'
      document.getElementById('preloader' + this.selectedQuality.src).style.display = 'block'
      this.http.get(this.selectedQuality.src, { responseType: 'blob', observe: 'response' })
        .subscribe(response => {
          let link = document.createElement('a');
          link.href = URL.createObjectURL(response.body);
          if (this.blockTitle !== '') {
            link.download = this.blockTitle + '_' + this.selectedQuality.quality
          } else if (this.selectedQuality.title) {
            link.download = this.selectedQuality.title + '_' + this.selectedQuality.quality
          } else {
            link.download = this.selectedQuality.quality
          }
          link.click()
          link.remove()
          this.inactiveDownload = false;
          document.getElementById('title' + this.selectedQuality.src).style.display = 'block'
          document.getElementById('preloader' + this.selectedQuality.src).style.display = 'none'
        }, () => {
          const data = {
            'title': 'Произошла ошибка, попробуйте ещё раз.',
            'confirmButtonText': 'OK'
          }
          this.inactiveDownload = false;
          document.getElementById('title' + this.selectedQuality.src).style.display = 'block'
          document.getElementById('preloader' + this.selectedQuality.src).style.display = 'none'
          this.dialogService.openDialog(AlertDialogComponent, data);
        });
    }
  }

  ngOnInit() {
    this.selectedQuality = this.arrayVideos[0]
    this.emitQuality.emit(this.selectedQuality)
  }

}
