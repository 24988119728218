<div *ngFor="let block of arrayContent;let i = index;" class="content">

    <div *ngIf="block.type === 'scorm'">
      <h2 *ngIf="block.title !== ''">{{block.title}}</h2>
      <div class="scorm_container" [id]="'scorm_container' + block.id">
        <div class="overlay" [id]="'scorm_overlay' + block.id" [ngClass]="{'active': block.active, 'not_active': !block.active}">
          <div class="button" (click)="startScorm(block)">Начать просмотр</div>
        </div>
        <iframe [src]="block.link | safeURL" width="100%" height="100%" [id]="block.id" class="scorm"></iframe>
        <div
          class="zoom_button"
          [id]="'zoom_button' + block.id"
          [ngStyle]="{backgroundImage: 'url(' + '/assets/images/icons/zoom_in.svg' + ')'}"
          (click)="zoomScorm('scorm_container', 'zoom_button', block.id)"
        >
        </div>
      </div>
    </div>

    <div class="html_text" *ngIf="block.type === 'html' && block.text !== ''" [innerHTML]="block.text | safeHtml"></div>

    <div class="image_block" *ngIf="block.type === 'image' && !!block.files">
      <h2 *ngIf="block.title !== ''">{{block.title}}</h2>

      <img [src]="(file.src)" *ngFor="let file of block.files">
    </div>



    <div class="video" *ngIf="block.type === 'video' && block.files.length !== 0">
        <h2 *ngIf="block.title !== ''">{{block.title}}</h2>
        <vg-player (onPlayerReady)="onPlayerReady($event, i)">
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>

        <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>

        <vg-controls [vgAutohide]="true">
            <vg-play-pause></vg-play-pause>
            <vg-playback-button [playbackValues]="[ '0.5', '1.0', '1.25', '1.5', '2.0' ]"></vg-playback-button>

            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

            <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

            <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>


            <vg-mute></vg-mute>
            <vg-volume></vg-volume>
            <vg-fullscreen></vg-fullscreen>
        </vg-controls>
        <video [vgMedia]="$any(media)" #media [id]="'singleVideo' + i" preload="auto" crossorigin poster="{{block.preview}}">
            <source [id]="'source' + i" type="video/mp4">
        </video>
        </vg-player>
      <div class="container">
        <div class="reaction_container">
          <div class="like_container">
            <div [ngClass]="{like_icon: !block.is_liked, fill_like_icon: block.is_liked}" (click)="likedBlock(block)"></div>
            <div class="like_count">{{block.likes}}</div>
          </div>
          <div class="dislike_container">
            <div [ngClass]="{dislike_icon: !block.is_disliked, fill_dislike_icon: block.is_disliked}" (click)="dislikedBlock(block)"></div>
            <div class="dislike_count" *ngIf="this.extraService.profileInfo && this.extraService.profileInfo.role === 'ADMIN'">{{block.dislikes}}</div>
          </div>
        </div>
        <app-video-quality [arrayVideos]="block.files" [blockTitle]="block.title" (emitQuality)="setQuality($event, i)"></app-video-quality>
      </div>
    </div>


    <div class="grid" *ngIf="!block.type && block.elements">
      <div class="video"
           [ngClass]="{small_amount: block.elements.length < 5, medium_amount: block.elements.length >= 5 && block.elements.length <= 9}"
           *ngFor="let element of block.elements; let k = index">
        <h2 *ngIf="element.title !== ''">{{element.title}}</h2>
        <vg-player (onPlayerReady)="onPlayerReady($event, k)">
          <vg-overlay-play></vg-overlay-play>
          <vg-buffering></vg-buffering>

          <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
          </vg-scrub-bar>

          <vg-controls [vgAutohide]="true">
            <vg-play-pause></vg-play-pause>
            <vg-playback-button [playbackValues]="[ '0.5', '1.0', '1.25', '1.5', '2.0' ]"></vg-playback-button>

            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

            <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

            <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>


            <vg-mute></vg-mute>
            <vg-volume></vg-volume>
            <vg-fullscreen></vg-fullscreen>
          </vg-controls>
          <video [vgMedia]="$any(media)" #media [id]="'singleVideo' + k" preload="auto" crossorigin poster="{{element.preview}}">
            <source [id]="'source' + k" type="video/mp4">
          </video>
        </vg-player>
        <div class="container">
          <div class="reaction_container">
            <div class="like_container">
              <div [ngClass]="{like_icon: !element.is_liked, fill_like_icon: element.is_liked}" (click)="likedBlock(element)"></div>
              <div class="like_count">{{element.likes}}</div>
            </div>
            <div class="dislike_container">
              <div [ngClass]="{dislike_icon: !element.is_disliked, fill_dislike_icon: element.is_disliked}" (click)="dislikedBlock(element)"></div>
              <div class="dislike_count" *ngIf="this.extraService.profileInfo && this.extraService.profileInfo.role === 'ADMIN'">{{element.dislikes}}</div>
            </div>
          </div>
          <app-video-quality [arrayVideos]="element.files" [blockTitle]="element.title" (emitQuality)="setQuality($event, k)"></app-video-quality>
        </div>
      </div>
    </div>


    <div *ngIf="block.type === 'gallery' && block.files.length > 0" class="gallery_block">
      <h2 *ngIf="block.title !== ''">{{block.title}}</h2>
      <lightgallery [settings]="settings" [onInit]="onInit" class="gallery_items">
        <a *ngFor="let galleryItem of block.files; let j = index" href="{{galleryItem.src}}">
          <img [src]="galleryItem.src" >
        </a>
      </lightgallery>
    </div>

    <div *ngIf="block.type === 'link' && block.link !== ''" class="link">
      <h2 *ngIf="block.title !== ''">{{block.title}}</h2>
      <a href="{{block.link}}">{{block.link}}</a>
    </div>

    <div *ngIf="block.type === 'files' && block.files.length > 0" class="files_container" [ngClass]="{column: block.files.length === 1}">
      <h2
        [ngClass]="{
        block_files_title: block.files.length !== 1,
        file_title: block.files.length === 1,
        substrate_solo: block.files.length === 1
        }"
        *ngIf="block.title !== '' && block.files.length !== 1"
      >
        {{block.title}}
      </h2>
      <div class="download_button" *ngIf="block.files.length !== 1">
        <span [id]="'title_download_archive' + block.id" (click)="downloadFileArchive(block.id)">СКАЧАТЬ ВСЕ ФАЙЛЫ</span>
        <div class="preloader" [id]="'preloader_download_archive' + block.id">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>
      <div class="files" [ngClass]="{column: block.files.length === 1}">
        <h2
          [ngClass]="{
          block_files_title: block.files.length !== 1,
          file_title: block.files.length === 1,
          substrate_solo: block.files.length === 1
          }"
          *ngIf="block.title !== '' && block.files.length === 1"
        >
          {{block.title}}
        </h2>
        <div class="file" *ngFor="let file of block.files; let i = index" [ngClass]="{substrate: block.files.length !== 1, substrate_solo: block.files.length === 1}">
          <div class="file_icon" (click)="goFile(file, block.files.length, block.title, i, block.id)" [id]="'file:' + i + '_' + block.id" [ngClass]="{
           'doc': file.extension === 'doc',
           'docx': file.extension === 'docx',
           'pdf': file.extension === 'pdf',
           'xls': file.extension === 'xls',
           'xlsx': file.extension === 'xlsx',
           'ppt': file.extension === 'ppt',
           'pptx': file.extension === 'pptx'
           }">
          </div>
          <div class="preloader" [id]="'preloader:' + i + '_' + block.id">
            <div class="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="file_title">{{file.title}}</div>
        </div>
      </div>
    </div>

</div>
