import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private STORAGE_KEY = 'ppd-v-01';
  private AUTH_KEY = 'auth-v-01';
  private FORM_DATA = 'form-v-01';

  constructor() {
    if (!localStorage.getItem(this.STORAGE_KEY)) {
      localStorage.setItem(this.STORAGE_KEY, '{}');
    }
    if (!localStorage.getItem(this.AUTH_KEY)) {
      localStorage.setItem(this.AUTH_KEY, '{}');
    }
    if (!localStorage.getItem(this.FORM_DATA)) {
      localStorage.setItem(this.FORM_DATA, '{}');
    }
  }

  setValue(key: string, value: any): void {
    let data = JSON.parse( <string>localStorage.getItem(this.STORAGE_KEY) );
    if (!data) {
      data = {};
      data[key] = {};
    } else if (!data[key]) {
      data[key] = {};
    }
    if (typeof(value) === 'object') {
      data[key] = Object.assign(data[key], value);
    } else {
      data[key] = value;
    }
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(data));
  }

  setWithKey(value: any, key: string = 'auth'): void {
    let storKey = this.AUTH_KEY;
    if (key === 'form') {
      storKey = this.FORM_DATA;
    }
    let data = JSON.parse( <string>localStorage.getItem(storKey) );
    if (!data) {
      data = {};
    }
    if (typeof(value) === 'object') {
      data = Object.assign(data, value);
    } else {
      data = value;
    }
    localStorage.setItem(storKey, JSON.stringify(data));
  }

  getWithKey(key: string = 'auth'): any {
    let storKey = this.AUTH_KEY;
    if (key === 'auth') {
      storKey = this.AUTH_KEY;
    } else if (key === 'form') {
      storKey = this.FORM_DATA;
    }
    return JSON.parse( <string>localStorage.getItem(storKey) );
  }

  setAll(value: any): void {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(value));
  }

  getValue(key: string): any {
    const data = JSON.parse( <string>localStorage.getItem(this.STORAGE_KEY) );
    if (!data) { return null; }
    return data[key];
  }

  getAll(): any {
    return JSON.parse( <string>localStorage.getItem(this.STORAGE_KEY) );
  }

}
