import {Pipe, PipeTransform} from '@angular/core';

@Pipe( {
  name: 'findUrlOnString',
} )

export class FindUrlOnStringPipe implements PipeTransform{
  transform(string: any): any {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
    return string.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    })
  }
}
