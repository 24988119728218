import {Component, OnInit, Inject, HostListener, Input, ElementRef, OnDestroy} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {ExtraService} from "services";

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent implements OnInit, OnDestroy {
  windowScrolled: boolean;

  @Input() workspace: any;

  position: number = 0;
  constructor(private extraService: ExtraService) {}
  onWindowScroll() {
    if (this.position > 100) {
      this.windowScrolled = true;
    }
    else if (this.position < 10) {
      this.windowScrolled = false;
    }

    if (this.windowScrolled) {
      setTimeout(() => {
        this.windowScrolled = false
      }, 5000)
    }
  }
  scrollToTop() {
    if (this.position > 0) {
      this.workspace.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
  ngOnInit() {
    this.extraService.scrollButtonTop$.subscribe((position) => {
      this.position = position;
      this.onWindowScroll()
    })
  }

  ngOnDestroy() {
    this.extraService.scrollButtonTop$.next(0)
  }

}

