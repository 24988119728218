import { Component, Input } from '@angular/core'
import { BaseService, ManagementApiService } from 'services/api'
import { FeedbackDialogComponent } from 'components/dialogs'
import { DialogService } from 'services'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-management-card-feedback',
  templateUrl: 'management-card-feedback.component.html',
  styleUrls: ['management-card-feedback.component.scss'],
})

export class ManagementCardFeedbackComponent {

  @Input() feedback: any

  feedbackAnswers: undefined;
  feedbackAnswersMessages: undefined;
  feedbackAnswersMessagesFile: undefined;
  feedbackAnswersFiles: undefined;
  open = false

  stars: number[] = [1, 2, 3, 4, 5];

  constructor(
    private baseService: BaseService,
    private managementService: ManagementApiService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
  }

  showFeedback() {
    if (!this.feedbackAnswers) {
      this.managementService.managementFeedbackGet(this.feedback.id).subscribe( (response: any) => {
        this.feedbackAnswers = response;
        this.managementService.managementFeedbackMessageGet(response.id).subscribe( (response: any) => {
          this.feedbackAnswersMessages = response
        })
      })
      this.managementService.managementFeedbackFileGet(this.feedback.id).subscribe( (response: any) => {
        this.feedbackAnswersFiles = response
      })
    }
    this.open = !this.open
  }

  viewFile(url) {
    window.open(url)
  }

  sendAnswerFeedback(feedbackId) {
    let data = {
      'title': 'Ответить',
      'feedbackId': feedbackId,
      showForm: false,
      managementAnswer: true,
    }
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {data});

    dialogRef.afterClosed().subscribe( () => {
      this.getFeedback()
    })
  }

  getFeedback() {
    this.managementService.managementFeedbackGet(this.feedback.id).subscribe( (response: any) => {
      this.feedbackAnswers = response;
      this.managementService.managementFeedbackMessageGet(response.id).subscribe( (response: any) => {
        this.feedbackAnswersMessages = response
      })
    })
    this.managementService.managementFeedbackFileGet(this.feedback.id).subscribe( (response: any) => {
      this.feedbackAnswersFiles = response
    })
  }

}
