<div class="custom_dialog feedback">
  <div class="container-feedback">
    <div class="title">{{this.title}}</div>

    <form [formGroup]="formFeedback" class="form_filter" *ngIf="formFeedback">

      <mat-select class="custom_select for-feedback" placeholder="Выберите Отдел" (selectionChange)="paramsFilters()" formControlName="department" *ngIf="showForm">
        <mat-option [value]="null">--</mat-option>
        <mat-option
          *ngFor="let department of department"
          [value]="department.id">
          {{ department.title }}
        </mat-option>
      </mat-select>

      <ng-container *ngIf="this.formFeedback.value.department">
        <mat-select class="custom_select for-feedback" placeholder="Выберите Тему" (selectionChange)="paramsFilters()" formControlName="theme" *ngIf="showForm">
          <mat-option [value]="null">--</mat-option>
          <mat-option
            *ngFor="let theme of theme"
            [value]="theme.id">
            {{ theme.title }}
          </mat-option>
        </mat-select>
      </ng-container>

      <ng-container *ngIf="this.formFeedback.value.department && this.formFeedback.value.theme">
        <mat-select class="custom_select for-feedback" placeholder="Выберите Подтему"(selectionChange)="paramsFilters()" formControlName="subtheme" *ngIf="showForm">
          <mat-option [value]="null">--</mat-option>
          <mat-option
            *ngFor="let subtheme of subtheme"
            [value]="subtheme.id">
            {{ subtheme.title }}
          </mat-option>
        </mat-select>
      </ng-container>

      <input class="input_feedback" formControlName="title" placeholder="Введите название запроса" *ngIf="showForm">

      <div class="textarea-container">
        <div class="title text" *ngIf="showForm">Вопрос:</div>
        <textarea
          type="text"
          placeholder="Введите текст"
          formControlName="text"
        ></textarea>
      </div>

    </form>

    <div class="title text">
      <div class="string-block">
        Прикрепленные файлы:
        <div class="icon-file" [appFileDialogTriggerFor]="fileDialog"></div>
      </div>
      <div class="red">Чтобы прикрепить несколько файлов зажмите клавишу Ctrl</div>
      <app-file-dialog #fileDialog multiple (files)="addFiles($event)"></app-file-dialog>
    </div>
    <div class="container-files">
      <div class="file" *ngFor="let file of files; index as index">
        <div class="title text-file">
          <app-image-preview *ngIf="file.type === 'image/jpeg' || file.type === 'image/png'" class="preview" [file]="file"></app-image-preview>
          <img class="preview-file" *ngIf="file.type != 'image/jpeg' && file.type != 'image/png'" src="assets/images/icons/doc_management.svg">
          {{file.name}}
        </div>
        <div class="icon-delete" (click)="deleteFile(index)"></div>
      </div>
    </div>

    <div class="button-container">
      <div *ngIf="showForm" class="button" [id]="'buttonTemplate'" (click)="sendFeedback(this.formFeedback.value.theme, this.formFeedback.value.subtheme, this.formFeedback.value.title, this.formFeedback.value.text)">Отправить</div>
      <div *ngIf="!showForm" class="button" (click)="sendAnswerFeedback(this.formFeedback.value.text)">ОТПРАВИТЬ</div>
      <div class="preloader" [id]="'preloaderTemplate'">
        <div class="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
