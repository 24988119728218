import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DialogService} from "services";
import {BaseService} from "services/api";
import {AlertDialogComponent, TeamDialogComponent, UpdateUserDialogComponent} from 'components/dialogs';
import { FormGroup, FormControl } from '@angular/forms';

import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-final-test-change-dialog',
  templateUrl: './final-test-change-dialog.component.html',
  styleUrls: ['./final-test-change-dialog.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})

export class FinalTestChangeDialogComponent {

  profileId: any;
  userId: any;
  title: string;
  text: string;
  section: string;
  currentDate: any;

  form: FormGroup;

  numAttempts: any;

  type: string;

  constructor(
    private dialogService: DialogService,
    private baseService: BaseService,
    private dialogRef: MatDialogRef<FinalTestChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { 'title': string, 'text': string, 'currentDate': any, 'numAttempts': any, 'profileId': any, 'userId': any, 'type': string, 'section': string },
  ) {
    ({
      title: this.title,
      text: this.text,
      currentDate: this.currentDate,
      numAttempts: this.numAttempts,
      profileId: this.profileId,
      userId: this.userId,
      type: this.type,
      section: this.section,
    } = this.dialogData);
  }

  dateChange(event, control) {
    control.setValue(moment(event.value.valueOf()));
    control.markAsDirty();
  }

  checkDate(timeStamp) {
    return moment(timeStamp)
  }

  onConfirmDeadline() {
    let data;
    this.baseService.testProfileEdit(this.profileId, this.type,  this.section, +this.form.get('deadline').value._i, null).subscribe(() => {
      data = {
        title: 'Дедлайн продлен успешно',
        confirmButtonText: 'OK'
      }
      this.dialogService.openDialog(AlertDialogComponent, data).subscribe(() => {
        this.dialogService.closeDialog()
        this.dialogService.openDialog(TeamDialogComponent, {userId: this.userId})
      })
    }, () => {
      data = {
        title: 'Произошла ошибка',
        confirmButtonText: 'OK'
      }
      this.dialogService.openDialog(AlertDialogComponent, data).subscribe(() => {
        this.dialogService.closeDialog()
        this.dialogService.openDialog(TeamDialogComponent, {userId: this.userId})
      })
    })
  }

  onConfirmAttempts() {
    let data;
    this.baseService.testProfileEdit(this.profileId, this.type,  this.section, null, this.form.get('numAttempts').value).subscribe(() => {
      data = {
        title: 'Попытки добавлены успешно',
        confirmButtonText: 'OK'
      }
      this.dialogService.openDialog(AlertDialogComponent, data).subscribe(() => {
        this.dialogService.closeDialog()
        this.dialogService.openDialog(TeamDialogComponent, {userId: this.userId})
      })
    }, () => {
      data = {
        title: 'Произошла ошибка',
        confirmButtonText: 'OK'
      }
      this.dialogService.openDialog(AlertDialogComponent, data).subscribe(() => {
        this.dialogService.closeDialog()
        this.dialogService.openDialog(TeamDialogComponent, {userId: this.userId})
      })
    })
  }

  ngOnInit() {
    if (this.type === 'deadline') {
      this.form = new FormGroup({
        'deadline': new FormControl(null)
      })
    }
    if (this.type === 'attempts') {
      this.form = new FormGroup({
        'numAttempts': new FormControl(null)
      })
    }
  }

}
