import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from 'services';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class ChatService {
  private readonly root = environment.url + 'api/';

  constructor(
    private httpClient: HttpClient,
    private storage: StorageService,
  ) {}

  getToken(): string {
    const token = this.storage.getValue('token');
    return 'Token ' + token;
  }

  getChatsToken() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'chats/token/',
      null,
      { headers }
    )
  }

  getChatList(chat_id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + `chats/${chat_id}/message/list/`,
      null,
      { headers },
    );
  }

  sendMessage(chat_id, data) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + `chats/${chat_id}/message/create/`,
      data,
      { headers },
    );
  }

  viewMessage(chat_id, id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + `chats/${chat_id}/message/view/${id}/`,
      null,
      { headers }
    )
  }

  nonviewedMessage(chat_id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + `chats/${chat_id}/message/nonviewed/`,
      null,
      { headers }
    )
  }

  deleteMessage(chat_id, message_id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + `chats/${chat_id}/message/delete/${message_id}/`,
      null,
      { headers },
    );
  }

  likeMessage(chat_id, message_id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + `chats/${chat_id}/message/like/${message_id}/`,
      null,
      { headers },
    );
  }

  dislikeMessage(chat_id, message_id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + `chats/${chat_id}/message/dislike/${message_id}/`,
      null,
      { headers },
    );
  }
}
