import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'services/api'

@Component({
  selector: 'app-info-user-rating-dialog',
  templateUrl: './info-user-rating-dialog.component.html',
  styleUrls: ['./info-user-rating-dialog.component.scss'],
})
export class InfoUserRatingDialogComponent {

  userId: number;
  profileInfo: any;
  userName: string;
  userSurname: string;
  login: string;
  city: string;
  region: any;
  position: string;
  channel: string;
  brand: any;

  constructor(
    private authService: AuthService,
    private dialogRef: MatDialogRef<InfoUserRatingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {
      userId: number,
      userName: string,
      userSurname: string,
      login: string,
      city: string,
      region: any,
      position: string,
      channel: string,
      brand: any,
    },
  ) {
    ({
      userId: this.userId,
      userName: this.userName,
      userSurname: this.userSurname,
      login: this.login,
      city: this.city,
      region: this.region,
      position: this.position,
      channel: this.channel,
      brand: this.brand
    } = this.dialogData);
  }

  dialogClose(): void {
    this.dialogRef.close(true);
  }

}
