import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { SwiperModule } from "swiper/angular";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LightgalleryModule } from 'lightgallery/angular';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

import { NgxMaskModule, IConfig } from 'ngx-mask'
import { StickyModule } from "ngx-sticky-kit";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AutosizeModule } from 'ngx-autosize';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { NgxFileDropModule } from 'ngx-file-drop';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NgScrollbarModule } from 'ngx-scrollbar'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  AlertDialogComponent,
  UserChangeDialogComponent,
  ManagementElementsDialogComponent,
  AttestationTestResultDialogComponent,
  TeamDialogComponent,
  UpdateUserDialogComponent,
  FinalTestChangeDialogComponent,
  FeedbackDialogComponent,
  FeedbackRatingDialogComponent,
  DocumentsViewedDialogComponent,
  InfoUserRatingDialogComponent
} from 'components/dialogs'

import {
  AuthComponent,
  AuthManagementComponent,
  MainComponent,
  PersonalComponent,
  ReportsHomeComponent,
  DocumentComponent,
  TestComponent,
  AttemptsComponent,
  ImportantComponent,
  ArchiveComponent,
  LibraryComponent,
  ManagementComponent,
  AttestationComponent,
  AttestationTestComponent,
  BusinessCodeComponent,
  FeedbackComponent,
  RatingComponent,
  LftfComponent
} from 'components/pages';

import {
  ProfileComponent,
  StatisticsComponent,
  TeamComponent,
} from 'components/pages/personal';

import {
  ReportsComponent,
  AttestationReportsComponent,
  InfographicsComponent
} from 'components/pages/reports'

import {
  SectionManagementComponent,
  FormManagementComponent,
  AddManagementComponent,
  EditManagementComponent,
  TrashManagementComponent
} from 'components/pages/management'

import {
  HeaderComponent,
  SideMenuComponent,
  ContentBlocksComponent,
  ChatComponent,
  VideoQualityComponent
} from 'components/sections';

import {
  FormSendComponent
} from 'components/sections/chat';

import {
  SafePipe,
  FilePipe,
  DateFormatPipe,
  TimesPipe,
  MsToTimePipe,
  ChatDateFormatPipe,
  SafePipeUrl,
  FindVideoIdPipe,
  FindUrlOnStringPipe,
  CurrentGalleryVideoPipe,
  TimestampToDate,
  HalfArrayPipe,
  FindArrayPipe,
  SearchPipe,
  MaxResultPipe,
  GetMonthForIndex,
  OrderByPipe
} from 'pipes'

import {
  FeedbackCardComponent
} from 'components/pages/feedback'

import {
  FileDialogModule
} from 'modules/file-dialog/file-dialog.module'

import {
  ManagementCardFeedbackComponent
} from 'components/pages/management/section-management'

import {
  ImagePreviewComponent
} from 'components/dialogs/feedback'

import {
  CurrentComponent,
  HallOfFameComponent,
  CardRatingComponent,
} from 'components/pages/rating'

import { MatCardModule } from '@angular/material/card'
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SectionFiltersComponent } from './components/pages/management/section-management/section-filters/section-filters.component'
import { MatButtonModule } from '@angular/material/button';
import {ScrollToTopComponent} from "./components/sections/scroll-to-top/scroll-to-top.component";

import {ScrollButtonListener, ScrollMenuListener} from "core/directives";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    AuthManagementComponent,
    MainComponent,
    PersonalComponent,
    TeamComponent,
    ProfileComponent,
    StatisticsComponent,
    DocumentComponent,
    TestComponent,
    AttestationComponent,
    AttemptsComponent,
    ReportsHomeComponent,
    ReportsComponent,
    ImportantComponent,
    ArchiveComponent,
    LibraryComponent,
    LftfComponent,
    AttestationReportsComponent,
    AttestationTestComponent,
    ManagementComponent,
    SectionManagementComponent,
    FormManagementComponent,
    AddManagementComponent,
    EditManagementComponent,
    TrashManagementComponent,
    HeaderComponent,
    SideMenuComponent,
    ContentBlocksComponent,
    VideoQualityComponent,
    ChatComponent,
    FormSendComponent,
    BusinessCodeComponent,
    InfographicsComponent,
    FeedbackComponent,
    FeedbackCardComponent,
    RatingComponent,
    CurrentComponent,
    HallOfFameComponent,
    CardRatingComponent,
    AlertDialogComponent,
    UserChangeDialogComponent,
    ManagementElementsDialogComponent,
    AttestationTestResultDialogComponent,
    TeamDialogComponent,
    UpdateUserDialogComponent,
    FinalTestChangeDialogComponent,
    FeedbackDialogComponent,
    FeedbackRatingDialogComponent,
    DocumentsViewedDialogComponent,
    InfoUserRatingDialogComponent,
    ImagePreviewComponent,
    ManagementCardFeedbackComponent,
    SafePipe,
    FilePipe,
    DateFormatPipe,
    TimesPipe,
    MsToTimePipe,
    ChatDateFormatPipe,
    SafePipeUrl,
    FindVideoIdPipe,
    FindUrlOnStringPipe,
    CurrentGalleryVideoPipe,
    TimestampToDate,
    HalfArrayPipe,
    FindArrayPipe,
    SearchPipe,
    MaxResultPipe,
    OrderByPipe,
    GetMonthForIndex,
    SectionFiltersComponent,
    ScrollToTopComponent,
    ScrollButtonListener,
    ScrollMenuListener
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    MatDialogModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule,
    MatSliderModule,
    DragDropModule,
    MatTabsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatIconModule,
    NgxMaterialTimepickerModule.setLocale('ru-RU'),
    NgxMatSelectSearchModule,
    SwiperModule,
    InfiniteScrollModule,
    LightgalleryModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgxMaskModule.forRoot(),
    StickyModule,
    CKEditorModule,
    AutosizeModule,
    PickerModule,
    EmojiModule,
    NgxFileDropModule,
    YouTubePlayerModule,
    NgScrollbarModule,
    FileDialogModule,
    MatCardModule,
    MatSlideToggleModule,
    MatButtonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
