<section class="content" [@show_preview]="showPrev" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}">
  <div *ngIf="reports">
    <div class="reports_list" *ngFor="let report of reports">
      <div class="title_button">
        <div class="title">{{ report.title }}</div>
        <div class="report_download_button" (click)="openReports(report)">{{!report.open ? 'Открыть' : 'Закрыть'}}</div>
      </div>

      <div *ngIf="report.open">
        <div class="horizontal_line"></div>
        <div class="report" *ngFor="let reportsActual of report.actual">
          <div class="report_title">{{ reportsActual.title }}</div>
          <div class="report_download_button" [id]="'button' + reportsActual.id" (click)="downloadReport('result', report.reference, reportsActual.id)">Скачать отчет</div>
          <div class="preloader" [id]="reportsActual.id">
            <div class="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="horizontal_line"></div>
      <div class="title_button">
        <div class="title">{{'Архив отчетов ' + report.title}}</div>
        <div class="report_download_button" (click)="openArchive(report)">{{!report.open_archive ? 'Открыть' : 'Закрыть'}}</div>
      </div>
      <div *ngIf="report.open_archive">
        <div class="report" *ngFor="let reportsArchive of report.archive">
          <div class="report_title">{{ reportsArchive.title }}</div>
          <div class="report_download_button" [id]="'button' + reportsArchive.id" (click)="downloadReport('result', report.reference, reportsArchive.id)">Скачать отчет</div>
          <div class="preloader" [id]="reportsArchive.id">
            <div class="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="reports_list">
    <div class="title">Отчет по просмотрам контента</div>
    <div class="horizontal_line"></div>
    <div class="report">
      <mat-form-field appearance="fill">
        <mat-label>Выберите дату</mat-label>
        <mat-date-range-input [formGroup]="statisticsEventForm" [rangePicker]="picker">
          <input readonly
                 matStartDate
                 formControlName="start"
                 placeholder="Начало">
          <input
            readonly
            matEndDate
            formControlName="finish"
            placeholder="Конец">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <div class="report_download_button" [id]="'content-views_button'" *ngIf="statisticsEventForm.get('start').value && statisticsEventForm.get('finish').value" (click)="downloadReport('content-views')">Скачать отчет</div>
      <div class="preloader" [id]="'content-views_preloader'">
        <div class="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>

  <!--            <div class="report_buttons_container">-->
  <!--              <div class="users_report_download_button" (click)="downloadUsersReport()">-->
  <!--                <div class="button_title_container">-->
  <!--                  <div class="button_title">Выгрузка пользователей</div>-->
  <!--                  <div class="button_desc">Скачать отчет</div>-->
  <!--                </div>-->
  <!--                <div class="button_icon"></div>-->
  <!--              </div>-->
  <!--            </div>-->
</section>
