<div class="workspace chat" *ngIf="messages">
  <ng-scrollbar #chat [visibility]="'hover'">
    <div class="messages_container" *ngFor="let message of messages" [id]="'message' + message.id">
      <div class="user_container" [ngClass]="{self_message: this.extraService.profileInfo.id === message.author.id}">
        <div class="avatar"
             [ngClass]="{no_show: this.extraService.profileInfo.id === message.author.id}"
             [ngStyle]="{'backgroundImage': 'url(' + (message.author.photo !== null ? (message.author.photo) : '/assets/images/icons/avatar_none.png') + ')'}"
        >
        </div>
        <div class="message_container">
          <div class="info_container" [id]="message.id" [ngClass]="{self_message: this.extraService.profileInfo.id === message.author.id }">
            <div class="name">{{message.author.name + ' ' + message.author.surname}}</div>
            <div class="message" *ngIf="!message.parent">
              <div [innerHTML]="message.text | findUrlOnString" class="chat_text"></div>
              <youtube-player
                *ngIf="message.text | findVideoId"
                [videoId]="message.text | findVideoId"
                suggestedQuality="highres">
              </youtube-player>
              <div class="files">
                <div class="gallery_block" *ngIf="message.files.image && message.files.image.length > 1">
                  <lightgallery [settings]="settings" class="gallery_items">
                    <a *ngFor="let galleryItem of message.files.image;" [attr.data-src]="galleryItem.path">
                      <img
                        [src]="galleryItem.path"
                      >
                    </a>
                  </lightgallery>
                </div>
                <div class="gallery_block" *ngIf="message.files.video && message.files.video.length > 1">
                  <lightgallery [settings]="settings" class="gallery_items">
                    <a *ngFor="let galleryItem of message.files.video;" [attr.data-video]="galleryItem | currentGalleryVideo">
                      <video [src]="galleryItem.path"></video>
                    </a>
                  </lightgallery>
                </div>
                <video controls *ngIf="message.files.video && message.files.video.length === 1" [src]="message.files.video[0].path"></video>
                <div class="gallery_block" *ngIf="message.files.image && message.files.image.length === 1">
                  <lightgallery [settings]="settings" class="gallery_items">
                    <a *ngFor="let galleryItem of message.files.image;" [attr.data-src]="galleryItem.path">
                      <img class="one_img" [src]="message.files.image[0].path"/>
                    </a>
                  </lightgallery>
                </div>
                <div class="gallery_block" *ngIf="message.files.application">
                  <div class="gallery_items" *ngFor="let file of message.files.application">
                    <div class="file" (click)="downloadFile(file.path)">
                      <div class="document_icon">
                        <div class="icon"></div>
                      </div>
                      <div class="document_name">{{file.name + '.' + file.ext}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="message" *ngIf="message.parent">
              <div class="parent_message" (click)="goToMessage( message.parent.id, $event)">
                <span class="name">{{message.parent.author.name + ' ' + message.parent.author.surname}}</span>
                <span class="overflow" *ngIf="message.parent.text !== ''">{{message.parent.text}}</span>
                <div class="files_item" *ngIf="message.parent.text === ''">
                  <img *ngIf="message.parent.files.image" [src]="message.parent.files.image[0].path" />
                  <video *ngIf="message.parent.files.video" [src]="message.parent.files.video[0].path | safeURL"></video>
                  <div class="file" *ngIf="message.parent.files.application">
                    <div class="document_icon">
                      <div class="icon"></div>
                    </div>
                    <div class="document_name">{{message.parent.files.application[0].name + '.' + message.parent.files.application[0].ext}}</div>
                  </div>
                </div>
              </div>
              <div [innerHTML]="message.text | findUrlOnString" class="chat_text"></div>
              <youtube-player
                *ngIf="message.text | findVideoId"
                [videoId]="message.text | findVideoId"
                suggestedQuality="highres">
              </youtube-player>
              <div class="files">
                <div class="gallery_block" *ngIf="message.files.image && message.files.image.length > 1">
                  <lightgallery [settings]="settings" class="gallery_items">
                    <a *ngFor="let galleryItem of message.files.image;" [attr.data-src]="galleryItem.path">
                      <img
                        [src]="galleryItem.path"
                      >
                    </a>
                  </lightgallery>
                </div>
                <div class="gallery_block" *ngIf="message.files.video && message.files.video.length > 1">
                  <lightgallery [settings]="settings" class="gallery_items">
                    <a *ngFor="let galleryItem of message.files.video;" [attr.data-video]="galleryItem | currentGalleryVideo">
                      <video [src]="galleryItem.path"></video>
                    </a>
                  </lightgallery>
                </div>
                <video controls *ngIf="message.files.video && message.files.video.length === 1" [src]="message.files.video[0].path"></video>
                <div class="gallery_block" *ngIf="message.files.image && message.files.image.length === 1">
                  <lightgallery [settings]="settings" class="gallery_items">
                    <a *ngFor="let galleryItem of message.files.image;" [attr.data-src]="galleryItem.path">
                      <img class="one_img" [src]="message.files.image[0].path"/>
                    </a>
                  </lightgallery>
                </div>
                <div class="gallery_block" *ngIf="message.files.application">
                  <div class="gallery_items" *ngFor="let file of message.files.application">
                    <div class="file" (click)="downloadFile(file.path)">
                      <div class="document_icon">
                        <div class="icon"></div>
                      </div>
                      <div class="document_name">{{file.name + '.' + file.ext}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="info_buttons">
              <div class="date">{{message.created | chatDateFormat}}</div>
              <span (click)="parentMessage = message">Ответить</span>
              <span *ngIf="this.extraService.profileInfo.id === message.author.id || this.extraService.profileInfo.role === 'ADMIN'" (click)="deleteMessage(message.id)">Удалить</span>
            </div>
          </div>
          <div class="reaction_container"
               [ngStyle]="{
               'justifyContent': this.extraService.profileInfo.id === message.author.id ? 'flex-end' : 'flex-start',
               'margin': this.extraService.profileInfo.id === message.author.id ? '5px 5px 0 0' : '5px 0 0 5px'
               }">
            <div class="like_container">
              <div [ngClass]="{like_icon: !message.is_liked, fill_like_icon: message.is_liked}" (click)="likedMessage(message)"></div>
              <div class="like_count">{{message.likes}}</div>
            </div>
            <div class="dislike_container">
              <div [ngClass]="{dislike_icon: !message.is_disliked, fill_dislike_icon: message.is_disliked}" (click)="dislikedMessage(message)"></div>
              <div class="dislike_count" *ngIf="this.extraService.profileInfo.id === message.author.id || this.extraService.profileInfo.role === 'ADMIN'">{{message.dislikes}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-scrollbar>
  <div class="no_messages" *ngIf="!messages.length">Нет сообщений</div>
  <div class="button_down" (click)="goToBottom($event)" [style.display]="display$ | async">
    <div class="icon"></div>
  </div>
  <app-form-send [chatId]="chatId" [answer]="parentMessage" (emitAnswer)="setAnswer($event)"></app-form-send>
</div>

<div class="preloader" *ngIf="preloaderActive">
  <div class="lds-default">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
