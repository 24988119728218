import { Component, Inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BaseService } from 'services/api'
import { DialogService } from 'services'
import { AlertDialogComponent } from 'components/dialogs'

@Component({
  selector: 'app-feedback-rating-dialog',
  templateUrl: './feedback-rating-dialog.component.html',
  styleUrls: ['./feedback-rating-dialog.component.scss'],
})

export class FeedbackRatingDialogComponent {

  @ViewChild( 'photoInput' ) photoInput;

  feedbackId: number;

  stars: number[] = [1, 2, 3, 4, 5];

  constructor(
    private dialogRef: MatDialogRef<FeedbackRatingDialogComponent>,
    private baseService: BaseService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private dialogData: { 'feedbackId': number },
  ) {
    ({
      feedbackId: this.feedbackId,
    } = this.dialogData);
  }

  countStar(star) {
    this.baseService.feedbackFinish(this.feedbackId, star, true).subscribe( () => {
      const data = {
        title: 'Спасибо за отзыв',
        confirmButtonText: 'OK'
      }
      this.dialogService.openDialog(AlertDialogComponent, data);
      this.dialogRef.close(true);
    }, () => {
      const data = {
        title: 'Произошла ошибка',
        confirmButtonText: 'OK'
      }
      this.dialogService.openDialog(AlertDialogComponent, data);
    })
  }

}
