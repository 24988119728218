<div class="custom_dialog">
    <h2 mat-dialog-title class="dialog-title" [innerHTML]="title | safeHtml"></h2>

    <div mat-dialog-content class="dialog-content" [innerHTML]="text | safeHtml">
    
    </div>
    
    <div mat-dialog-actions [ngClass]="{'dialog-actions': notButtonText}">
      <div class="dialog_button" (click)="onConfirm()">{{confirmButtonText}}</div>
      <div *ngIf="notButtonText" class="dialog_button" (click)="onNot()">{{notButtonText}}</div>
    </div>
</div>