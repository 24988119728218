<div class="container">
  <div class="select_container">
    <mat-select
      class="custom_select for_management"
      [(value)]="selectedQuality"
      (selectionChange)="selectionChange($event)"
    >
      <mat-option
        *ngFor="let video of arrayVideos"
        [value]="video"
      >
        {{video.quality}}
      </mat-option>
    </mat-select>
  </div>
  <div class="button" *ngIf="selectedQuality && selectedQuality.download" (click)="downloadVideo()">
    <span [id]="'title' + selectedQuality.src">СКАЧАТЬ</span>
    <div class="preloader" [id]="'preloader' + selectedQuality.src">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </div>
</div>
