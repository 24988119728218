<div (click)="showFeedback()">
  <div class="container-box up">
    <div class="circle" [style.background-color]="feedback.status.color"></div>
    <div class="title bold">{{feedback.title}}</div>
  </div>
  <div class="container-box">
    <div class="title bold">№ {{feedback.id}}</div>
  </div>
  <div class="container-box">
    <div class="title">{{feedback.path.join(' - ')}}</div>
    <div class="title date">{{feedback.created | dateFormat: 'medium'}}</div>
  </div>
  <div class="container-star" *ngIf="feedback.rating">
    <ng-container *ngFor="let star of stars">
      <div *ngIf="star <= feedback.rating" class="star-done"></div>
      <div *ngIf="star > feedback.rating" class="star"></div>
    </ng-container>
  </div>
</div>

<div class="container-message" *ngIf="open && feedbackAnswers && feedbackAnswersFiles">
  <div class="user-block">
    <div>
      {{feedbackAnswers.text}}
    </div>
    <div *ngFor="let file of feedbackAnswersFiles">
      <div class="file-open" (click)="viewFile(file.file)">
        <img class="avatar file-preview" *ngIf="file.type === 'image/jpeg' || file.type === 'image/png'" [src]="file.file" alt="">
        <img class="preview-file" *ngIf="file.type != 'image/jpeg' && file.type != 'image/png'" src="assets/images/icons/doc_management.svg">
        {{file.type}}
      </div>
    </div>
  </div>
  <div class="line"></div>

  <div *ngFor="let message of feedbackAnswersMessages">
    <div class="container-manager" *ngIf="message.incoming">
      <div class="bold">{{message.author}}</div>
      <div>{{message.text}}</div>
      <div *ngFor="let file of message.files">
        <div class="file-open">
          <img class="avatar file-preview" *ngIf="file.type === 'image/jpeg' || file.type === 'image/png'" [src]="file.file" alt="">
          <img class="preview-file" *ngIf="file.type != 'image/jpeg' && file.type != 'image/png'" src="assets/images/icons/doc_management.svg">
          {{file.type}}
        </div>
      </div>
      <div class="date">{{message.created | dateFormat: 'medium'}}</div>
    </div>

    <div class="container-manager personal" *ngIf="!message.incoming">
      <div class="name bold"><img class="avatar" src="assets/images/icons/avatar_none.png">{{message.author}}</div>
      <div>{{message.text}}</div>
      <div *ngFor="let file of message.files">
        <div class="file-open" (click)="viewFile(file.file)">
          <img class="avatar file-preview" *ngIf="file.type === 'image/jpeg' || file.type === 'image/png'" [src]="file.file" alt="">
          <img class="preview-file" *ngIf="file.type != 'image/jpeg' && file.type != 'image/png'" src="assets/images/icons/doc_management.svg">
          {{file.type}}
        </div>
      </div>
      <div class="date">{{message.created | dateFormat: 'medium'}}</div>
    </div>
  </div>

  <div class="button-top bottom" *ngIf="!feedback.rating">
    <div class="button" (click)="sendAnswerFeedback(feedback.id)">Ответить</div>
  </div>

</div>
