import {Component, OnInit, Input, ViewChild} from '@angular/core';
import { Router } from '@angular/router';

import { ExtraService } from 'services';

import { StorageService } from 'services';

import { AuthService } from 'services/api';

import { DialogService } from 'services';
import { AlertDialogComponent } from 'components/dialogs';
@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})

export class HeaderComponent implements OnInit {

  @ViewChild( 'support' ) support;

  @Input() noShowMenu = false;
  @Input() noGoMain = false;

  constructor(
    private router: Router,
    public extraService: ExtraService,
    private storageService: StorageService,
    private dialogService: DialogService,
    private authService: AuthService
  ) {
  }

  logout() {
    const data = {
      'title': 'Вы уверены, что хотите выйти?',
      'confirmButtonText': 'ДА',
      'notButtonText': 'НЕТ'
    }
    this.dialogService.openDialog(AlertDialogComponent, data).subscribe((res: any) => {
      if (res) {
        this.authService.authLogOut();
        this.storageService.setValue('token', '');
        this.router.navigate(['auth']);
      } else {
        return;
      }
    })
  }

  getSupport() {
    this.support.nativeElement.click();
  }

  goMain() {
    if (!this.noGoMain) {
      this.router.navigate(['main']);
    }
  }

  goPersonal() {
    this.router.navigate(['personal']);
  }

  openMenu() {
    this.extraService.openMenu = !this.extraService.openMenu;
    this.extraService.showUserMobile = !this.extraService.showUserMobile;
  }

  ngOnInit() {
    this.authService.getUserInfo().subscribe((response: any) => {
      this.extraService.profileInfo = response;
    });
  }
}
