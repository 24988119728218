import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, AuthManagementGuard } from 'guards';

import {
  AuthComponent,
  AuthManagementComponent,
  MainComponent,
  PersonalComponent,
  DocumentComponent,
  TestComponent,
  AttemptsComponent,
  ReportsHomeComponent,
  ImportantComponent,
  ArchiveComponent,
  LibraryComponent,
  LftfComponent,
  ManagementComponent,
  AttestationComponent,
  AttestationTestComponent,
  BusinessCodeComponent,
  FeedbackComponent,
  RatingComponent
} from 'components/pages'

import {
  ReportsComponent,
  AttestationReportsComponent, InfographicsComponent
} from 'components/pages/reports'

import {
  SectionManagementComponent,
  AddManagementComponent,
  EditManagementComponent,
  TrashManagementComponent
} from 'components/pages/management';

import {
  ProfileComponent,
  StatisticsComponent,
  TeamComponent
} from "components/pages/personal";

import {
  CurrentComponent,
  HallOfFameComponent
} from 'components/pages/rating'

const routes: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: 'auth', component: AuthComponent },
  { path: 'auth-management', component: AuthManagementComponent },
  { path: ':section/documents/:id', component: DocumentComponent, canActivate: [ AuthGuard ] },
  { path: 'test/:id', component: TestComponent, canActivate: [ AuthGuard ] },
  { path: 'main', component: MainComponent, canActivate: [ AuthGuard ] },
  { path: 'attestation', component: AttestationComponent, canActivate: [ AuthGuard ] },
  { path: 'attestation-test/:id', component: AttestationTestComponent, canActivate: [ AuthGuard ] },
  {
    path: 'personal',
    component: PersonalComponent,
    canActivate: [ AuthGuard ],
    children: [
      { path: 'profile', component: ProfileComponent },
      { path: 'statistics', component: StatisticsComponent },
      { path: 'team', component: TeamComponent },
      { path: '**', redirectTo: 'profile' },
    ],
  },
  { path: 'attempts/:section', component: AttemptsComponent, canActivate: [ AuthGuard ] },
  {
    path: 'reports-home',
    component: ReportsHomeComponent,
    canActivate: [ AuthGuard ],
    children: [
      { path: 'reports', component: ReportsComponent },
      { path: 'attestation', component: AttestationReportsComponent },
      { path: 'infographics', component: InfographicsComponent },
      { path: '**', redirectTo: 'infographics' }
    ]
  },
  { path: 'important/:id', component: ImportantComponent, canActivate: [ AuthGuard ] },
  { path: 'archive/:id', component: ArchiveComponent, canActivate: [ AuthGuard ] },
  { path: 'library/:id', component: LibraryComponent, canActivate: [ AuthGuard ] },
  { path: 'lftf/:id', component: LftfComponent, canActivate: [ AuthGuard ] },
  { path: 'management', component: ManagementComponent, canActivate: [ AuthManagementGuard ] },
  { path: 'section-management/:section', component: SectionManagementComponent, canActivate: [ AuthManagementGuard ] },
  { path: 'section-management/:section/:add-type', component: AddManagementComponent, canActivate: [ AuthManagementGuard ] },
  { path: 'section-management/:section/:edit-type/:id', component: EditManagementComponent, canActivate: [ AuthManagementGuard ] },
  { path: 'management/trash/:section', component: TrashManagementComponent, canActivate: [ AuthManagementGuard ] },
  { path: 'business/:id', component: BusinessCodeComponent, canActivate: [ AuthGuard ] },
  { path: 'feedback', component: FeedbackComponent, canActivate: [ AuthGuard ] },
  {
    path: 'rating',
    component: RatingComponent,
    canActivate: [ AuthGuard ],
    children: [
      { path: 'current', component: CurrentComponent },
      { path: 'hall-of-fame', component: HallOfFameComponent },
      { path: '**', redirectTo: 'current' },
    ],
  },
  { path: '**', redirectTo: '/main', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
