<div class="title">{{history.channel === 'SALON' ? 'Канал салоны' : 'Профмагазины'}}</div>
<div class="container_history">
  <div class="container_user" *ngFor="let user of history.users">
    <ng-container *ngIf="user.scores > 0">
      <div class="container_photo">
        <div class="crown"></div>
        <div class="photo" [ngStyle]="{'backgroundImage': 'url(' + (user.photo !== null ? (user.photo) : '/assets/images/icons/avatar_none.png') + ')'}"></div>
      </div>
      <div class="leader_text channel">Лидер <span style="text-transform: uppercase">{{history.channel === 'SALON' ? user.brands : 'PS'}}</span></div>
      <div class="leader_text name">{{user.surname}} {{user.name}}</div>
      <div class="leader_text">
        {{user.position}},
        <ng-container *ngFor="let userRegion of user.regions">{{userRegion}}</ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="user.scores === 0">
      <div class="container_photo">
        <div class="crown"></div>
        <div class="photo" [ngStyle]="{'backgroundImage': 'url(/assets/images/icons/avatar_none.png'}"></div>
      </div>
      <div class="leader_text channel">Лидер <span style="text-transform: uppercase">{{history.channel === 'SALON' ? user.brands : 'PS'}}</span></div>
      <div class="leader_text name">Лидер месяца ещё не определен... <br> <div class="interval">Будь первым!</div></div>
    </ng-container>
  </div>
</div>

