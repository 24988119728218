<form class="form" [formGroup]="form" (ngSubmit)="submit()" enctype="multipart/form-data" *ngIf="controls">
  <div class="help_text">* - Обязательно для заполнения</div>
  <div class="controls_body" *ngFor="let control of getControlsName(controls); let i = index">
    <div class="control_name">{{controls[control]['input']['label']}}<span *ngIf="controls[control]['input']['required']"> *</span></div>

    <!--checkbox-->
    <div *ngIf="controls[control]['input']['widget'] === 'CheckboxInput'">
      <mat-checkbox
        formControlName="{{controls[control]['input']['name']}}"
        value="{{controls[control]['default']}}"
        checked="{{controls[control]['default']}}"
      >
      </mat-checkbox>
    </div>

    <!--textInput-->
    <ng-container *ngIf="controls[control]['input']['widget'] === 'TextInput'">
      <input
        type="text"
        formControlName="{{controls[control]['input']['name']}}"
        value="''"
      >
      <div *ngIf="changeErrorMessage(controls[control]['input']['name'])" [ngStyle]="{color: 'red'}">
        {{errorMessage}}
      </div>
    </ng-container>

    <!--URLInput-->
    <ng-container *ngIf="controls[control]['input']['widget'] === 'URLInput'">
      <input
        type="url"
        formControlName="{{controls[control]['input']['name']}}"
      >
      <div *ngIf="changeErrorMessage(controls[control]['input']['name'])" [ngStyle]="{color: 'red'}">
        {{errorMessage}}
      </div>
    </ng-container>

    <!--DateTimeInput-->
    <ng-container *ngIf="controls[control]['input']['widget'] === 'DateTimeInput'">
      <input
        [matDatepicker]="picker"
        [value]="timestampToMoment(form.get(controls[control]['input']['name']).value)"
        (click)="picker.open()"
        (dateChange)="dateChange($event, form.get(controls[control]['input']['name']))"
        readonly
        class="date_input"
      >
      <input [ngxTimepicker]="timePicker"
             [format]="24"
             [value]="timestampToTime(form.get(controls[control]['input']['name']).value)"
             readonly
             class="date_input"
      >
      <ngx-material-timepicker (timeChanged)="timeChange($event, form.get(controls[control]['input']['name']))" #timePicker [theme]="darkTheme"></ngx-material-timepicker>
      <mat-datepicker #picker></mat-datepicker>
      <div *ngIf="changeErrorMessage(controls[control]['input']['name'])" [ngStyle]="{color: 'red'}">
        {{errorMessage}}
      </div>
    </ng-container>


    <!--textarea-->
    <ng-container *ngIf="controls[control]['input']['widget'] === 'Textarea'">
            <ckeditor
              [editor]="Editor"
              [config]="configEditor"
              formControlName="{{controls[control]['input']['name']}}"
            >
            </ckeditor>
      <div *ngIf="changeErrorMessage(controls[control]['input']['name'])" [ngStyle]="{color: 'red'}">
        {{errorMessage}}
      </div>
    </ng-container>

    <!--ClearableFileInput-->
    <ng-container *ngIf="controls[control]['input']['widget'] === 'ClearableFileInput'">
      <div class="no_show">
        <input type="file" #fileInput name="files[]" (change)="processWebFile($event, controls[control]['input']['name'])" />
      </div>
      <div *ngIf="controls[control]['input']['name'] === 'preview' || controls[control]['input']['name'] === 'file'">
        <div *ngIf="type === 'edit-doc' || type === 'edit-banner-image'">
          <div *ngIf="file === undefined">
            <div *ngIf="form.get(controls[control]['input']['name']).value">
              <div
                *ngIf="form.get(controls[control]['input']['name']).value.split('.').pop() === 'png'
          || form.get(controls[control]['input']['name']).value.split('.').pop() === 'jpeg'
          || form.get(controls[control]['input']['name']).value.split('.').pop() === 'jpg'
          || form.get(controls[control]['input']['name']).value.split('.').pop() === 'webp'
          || form.get(controls[control]['input']['name']).value.split('.').pop() === 'gif'"
                class="preview"
                [ngStyle]="{'backgroundImage': 'url(' + ((form.get(controls[control]['input']['name']).value)) + ')'}"
              ></div>
            </div>
          </div>

          <div *ngIf="file !== undefined">
            <div
              *ngIf="file.name.split('.').pop() === 'png'
          || file.name.split('.').pop() === 'jpg'
          || file.name.split('.').pop() === 'jpeg'
          || file.name.split('.').pop() === 'webp'
          || file.name.split('.').pop() === 'gif'"
              class="preview"
              [ngStyle]="{'backgroundImage': 'url(' + (loadFile) + ')'}"
            ></div>
          </div>
        </div>
        <div *ngIf="type === 'add-doc' || type === 'add-attestation' || type === 'add-banner-image'">
          <div *ngIf="file !== undefined">
            <div
              *ngIf="file.name.split('.').pop() === 'png'
          || file.name.split('.').pop() === 'jpg'
          || file.name.split('.').pop() === 'jpeg'
          || file.name.split('.').pop() === 'webp'
          || file.name.split('.').pop() === 'gif'"
              class="preview"
              [ngStyle]="{'backgroundImage': 'url(' + (loadFile) + ')'}"
            ></div>
          </div>
        </div>
      </div>

      <div *ngIf="type === 'add-elements' || type === 'edit-elements' || type === 'add-file-notification'">
        <div *ngIf="!!form.get(controls[control]['input']['name']).value">
          <div *ngIf="file === undefined">
            <p><span>Название файла: </span>{{form.get('title').value}}</p>
            <p><span>Формат файла: </span>{{form.get(controls[control]['input']['name']).value.split('.').pop()}}</p>
            <div
              *ngIf="form.get(controls[control]['input']['name']).value.split('.').pop() === 'png'
          || form.get(controls[control]['input']['name']).value.split('.').pop() === 'jpg'
          || form.get(controls[control]['input']['name']).value.split('.').pop() === 'jpeg'
          || form.get(controls[control]['input']['name']).value.split('.').pop() === 'webp'
          || form.get(controls[control]['input']['name']).value.split('.').pop() === 'gif'"
              class="preview gallery"
              [ngStyle]="{'backgroundImage': 'url(' + ((form.get(controls[control]['input']['name']).value)) + ')'}"
            ></div>
          </div>

          <div *ngIf="file !== undefined">
            <p><span>Название файла: </span>{{file.name}}</p>
            <p><span>Формат файла: </span>{{file.name.split('.').pop()}}</p>
            <div
              *ngIf="file.name.split('.').pop() === 'png'
          || file.name.split('.').pop() === 'jpg'
          || file.name.split('.').pop() === 'jpeg'
          || file.name.split('.').pop() === 'webp'
          || file.name.split('.').pop() === 'gif'"
              class="preview gallery"
              [ngStyle]="{'backgroundImage': 'url(' + (loadFile) + ')'}"
            ></div>
          </div>
        </div>

      </div>
      <div class="button file_button" (click)="getFile()">{{
        (type === 'add-doc' || type === 'add-attestation' || type === 'add-banner-image') && file === undefined ? 'Добавить файл'
          : (type === 'add-doc' || type === 'add-attestation' || type === 'add-banner-image') && file !== undefined ? 'Обновить файл'
          : (type === 'edit-doc' || type === 'edit-attestation' || type === 'edit-banner-image') ? 'Обновить файл'
            : (type === 'add-elements' || type === 'edit-elements') && !!form.get(controls[control]['input']['name']).value ? 'Изменить файл'
              : (type === 'add-file-notification') ? 'Выбрать файл' : 'Добавить файл'
        }}</div>
      <div *ngIf="changeErrorMessage(controls[control]['input']['name'])" [ngStyle]="{color: 'red'}">
        {{errorMessage}}
      </div>
    </ng-container>

    <!--Select-->
    <ng-container *ngIf="controls[control]['input']['widget'] === 'Select'">
      <mat-select (selectionChange)="selectSectionChange($event)" formControlName="{{controls[control]['input']['name']}}" class="custom_select for_management">
        <mat-option
          *ngFor="let option of controls[control]['choices']"
          [value]="option.id"
        >
          {{option.title}}
        </mat-option>
      </mat-select>
      <div *ngIf="changeErrorMessage(controls[control]['input']['name'])" [ngStyle]="{color: 'red'}">
        {{errorMessage}}
      </div>
    </ng-container>

    <!--SelectMultiple-->
    <ng-container *ngIf="controls[control]['input']['widget'] === 'SelectMultiple'">
      <mat-select multiple formControlName="{{controls[control]['input']['name']}}" class="custom_select for_management">
        <mat-option
          *ngFor="let option of controls[control]['choices']"
          [value]="option.id"
        >
          {{option.title}}
        </mat-option>
      </mat-select>
      <div *ngIf="changeErrorMessage(controls[control]['input']['name'])" [ngStyle]="{color: 'red'}">
        {{errorMessage}}
      </div>
    </ng-container>

    <!--SearchSelect-->
    <ng-container *ngIf="controls[control]['input']['widget'] === 'SearchSelect'">
      <mat-select formControlName="{{controls[control]['input']['name']}}" class="custom_select for_management">
        <ngx-mat-select-search
          [formControl]="searchFilterCtrl"
          placeholderLabel="Поиск"
          noEntriesFoundLabel="Нет результатов"
          [(ngModel)]="searchString"
        ></ngx-mat-select-search>

        <mat-option
          *ngFor="let option of controls[control]['choices'] | search:searchString:'title'"
          [value]="option.id"
        >
          {{option.title}}
        </mat-option>
      </mat-select>
      <div *ngIf="changeErrorMessage(controls[control]['input']['name'])" [ngStyle]="{color: 'red'}">
        {{errorMessage}}
      </div>
    </ng-container>

    <!--SearchSelectMultiple-->
    <ng-container *ngIf="controls[control]['input']['widget'] === 'SearchSelectMultiple'">
      <mat-select multiple formControlName="{{controls[control]['input']['name']}}" class="custom_select for_management">
        <ngx-mat-select-search
          [formControl]="searchFilterCtrl"
          placeholderLabel="Поиск"
          noEntriesFoundLabel="Нет результатов"
          [(ngModel)]="searchString"
        ></ngx-mat-select-search>

        <mat-option
          *ngFor="let option of controls[control]['choices'] | search:searchString:'title'"
          [value]="option.id"
        >
          {{option.title}}
        </mat-option>
      </mat-select>
      <div *ngIf="changeErrorMessage(controls[control]['input']['name'])" [ngStyle]="{color: 'red'}">
        {{errorMessage}}
      </div>
    </ng-container>

    <!--NumberInput-->
    <ng-container *ngIf="controls[control]['input']['widget'] === 'NumberInput'">
      <input
        type="number"
        value="{{controls[control]['default']}}"
        formControlName="{{controls[control]['input']['name']}}"
        [attr.step]="controls[control]['input']['attrs'].step ? controls[control]['input']['attrs'].step : 1"
      >
      <div *ngIf="changeErrorMessage(controls[control]['input']['name'])" [ngStyle]="{color: 'red'}">
        {{errorMessage}}
      </div>
    </ng-container>

  </div>

  <button type="submit" class="button submit_button" [disabled]="inactiveSubmit" [ngClass]="{no_show:
  type === 'add-elements' ||
  type === 'edit-elements' ||
  type === 'add-question' ||
  type === 'edit-question' ||
  type === 'add-question-attestation' ||
  type === 'edit-question-attestation' ||
  type === 'add-filter' ||
  type === 'add-banner-image' ||
  type === 'add-banner-filter' ||
  type === 'edit-filter' ||
  type === 'edit-banner-image' ||
  type === 'edit-banner-filter' ||
  type === 'add-file-notification' ||
  type === 'add-notification-filter' ||
  inactiveSubmit
  }">
    {{
      type === 'add-doc' || type === 'add-attestation' ? 'Добавить документ' : type === 'edit-doc' || type === 'edit-attestation' ? 'Обновить документ'
        : type === 'add-folder' ? 'Добавить папку' : type === 'edit-folder' ? 'Обновить папку'
          : type === 'add-test' || type === 'add-attestation-test' ? 'Добавить тест' : type === 'edit-test' || type === 'edit-attestation-test' ? 'Обновить тест'
            : type === 'add-banner' ? 'Добавить баннер' : type === 'edit-banner' ? 'Обновить баннер' : type === 'add-notification' ? 'Создать письмо' : type === 'edit-notification' ? 'Сохранить письмо' : ''
    }}
  </button>

  <div class="preloader" *ngIf="inactiveSubmit">
    <div class="lds-default">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

</form>
