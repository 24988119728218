<div class="main-container"  [ngClass]="{'gray': feedback.is_over}">
  <div (click)="showFeedback()">
    <div class="container-box">
      <div class="title bold">{{feedback.title}}</div>
      <div class="new" *ngIf="feedback.is_new">NEW</div>
    </div>
    <div class="container-box">
      <div class="title bold">№ {{feedback.id}}</div>
    </div>
    <div class="container-box">
      <div class="title">{{feedback.path.join(' - ')}}</div>
      <div class="title date">{{feedback.created | dateFormat: 'medium'}}</div>
    </div>
    <div class="container-star" *ngIf="feedback.rating">
      <ng-container *ngFor="let star of stars">
        <div *ngIf="star <= feedback.rating" class="star-done"></div>
        <div *ngIf="star > feedback.rating" class="star"></div>
      </ng-container>
    </div>
    <div class="container-star" *ngIf="feedback.is_over && !feedback.rating">
      <div class="title bold">Запрос решен самостоятельно</div>
    </div>
  </div>

  <div class="container-message" *ngIf="open && feedbackAnswers">
    <div class="user-block">
      <div>
        {{feedbackAnswers.text}}
      </div>
      <div *ngFor="let file of feedbackAnswersFiles">
        <div class="file-open" (click)="viewFile(file.file)">
          <img class="avatar file-preview" *ngIf="file.type === 'image/jpeg' || file.type === 'image/png'" [src]="file.file" alt="">
          <img class="preview-file" *ngIf="file.type != 'image/jpeg' && file.type != 'image/png'" src="assets/images/icons/doc_management.svg">
          {{file.type}}
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div *ngFor="let message of feedbackAnswersMessages">

      <div class="container-manager" *ngIf="!message.incoming">
        <!--      <div class="bold">{{message.author}}</div>-->
        <div>{{message.text}}</div>
        <div *ngFor="let file of message.files">
          <div class="file-open" (click)="viewFile(file.file)">
            <img class="avatar file-preview" *ngIf="file.type === 'image/jpeg' || file.type === 'image/png'" [src]="file.file" alt="">
            <img class="preview-file" *ngIf="file.type != 'image/jpeg' && file.type != 'image/png'" src="assets/images/icons/doc_management.svg">
            {{file.type}}
          </div>
        </div>
        <div class="date">{{message.created | dateFormat: 'medium'}}</div>
      </div>

      <div class="container-manager personal" *ngIf="message.incoming">
        <div class="name bold"><img class="avatar" src="assets/images/icons/avatar_none.png">{{message.author}}</div>
        <div>{{message.text}}</div>
        <div *ngFor="let file of message.files">
          <div class="file-open" (click)="viewFile(file.file)">
            <img class="avatar file-preview" *ngIf="file.type === 'image/jpeg' || file.type === 'image/png'" [src]="file.file" alt="">
            <img class="preview-file" *ngIf="file.type != 'image/jpeg' && file.type != 'image/png'" src="assets/images/icons/doc_management.svg">
            {{file.type}}
          </div>
        </div>
        <div class="date">{{message.created | dateFormat: 'medium'}}
        </div>
      </div>
    </div>

    <div class="button-top bottom" *ngIf="!feedback.is_over">
      <div class="button" (click)="sendAnswerFeedback(feedback.id)">ОТПРАВИТЬ</div>
      <div class="button" (click)="finishFeedback(feedback.id, feedback.is_answered)">Запрос решен</div>
    </div>

  </div>
</div>

