import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'maxResult'
})

export class MaxResultPipe implements PipeTransform {
  transform(arr: any): any {
    return arr.reduce((acc, curr) => acc.result > curr.result ? acc : curr).result;
  }
}
