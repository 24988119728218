<div class="custom_dialog attestation_test_result">
  <div class="test_title">{{testTitle}}</div>
  <div class="result_container">
    <div class="result">Результат: {{result + '%'}}</div>
    <div class="line" *ngIf="themes.length"></div>
    <ng-scrollbar style="height: 170px !important;">
      <div class="theme_result_container" *ngIf="themes.length">
        <div class="container" *ngFor="let theme of themes">
          <div class="theme_title">{{theme.title}}</div>
          <div class="theme_result">
            <div
              class="circle"
              [ngClass]="{
                  gray: theme.result === 0,
                  crimson: theme.result >= 1 && theme.result <= 49,
                  red: theme.result >= 50 && theme.result <= 79,
                  yellow: theme.result >= 80 && theme.result <= 89,
                  light_green: theme.result >= 90 && theme.result <= 99,
                  green: theme.result === 100
                }"
            >

            </div>
            <div class="result">{{theme.result + '%'}}</div>
          </div>
        </div>
      </div>
    </ng-scrollbar>

    <div class="text">{{text}}</div>

    <div class="dialog_button" style="align-self: center" (click)="onConfirm()">ОК</div>
  </div>

</div>
