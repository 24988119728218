import { Component, OnInit } from '@angular/core';
import { DialogService } from "services";


import { animate, state, style, transition, trigger } from '@angular/animations';
import {TeamDialogComponent} from "components/dialogs";
import {AuthService} from "services/api";

import { FormControl, FormGroup } from "@angular/forms";


@Component({
  selector: 'app-team',
  templateUrl: 'team.component.html',
  styleUrls: ['team.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class TeamComponent implements OnInit {

  formFilter = undefined;
  brands = undefined;
  channels = undefined;
  partners = undefined;
  positions = undefined;
  regions = undefined;
  states = undefined;
  documents = undefined;
  team = undefined;
  params = undefined;
  searchString = '';

  offset: number = 0;
  limit: number = 10;
  endScroll: boolean = false;

  docId = null;

  public searchFilterCtrl: FormControl = new FormControl();
  selectSearchString = '';

  mobileFilter = false;
  menuOpenFilter = true;

  constructor(
    private dialogService: DialogService,
    private authService: AuthService
  ) {
  }

  getUser(userId) {
    let data = {
      'userId': userId,
      params: this.params,
    }
    this.dialogService.openDialog(TeamDialogComponent, data).subscribe(() => {
      this.authService.getUserList(this.docId, this.limit, this.offset, this.formFilter.value, this.searchString).subscribe((response: any) => {
        this.team = response;
      })
    })
  }

  paginationOn() {
    this.offset += this.limit;
    this.authService.getUserList(this.docId, this.limit, this.offset, this.formFilter.value, this.searchString).subscribe((response: any) => {
      Array.prototype.push.apply(this.team, response);
      if (response.length < this.limit) {
        this.endScroll = true;
      }
    })
    // if (this.filtered) {
    //
    // } else {
    //   this.authService.getUserList(2, this.limit, this.offset).subscribe((response: any) => {
    //     Array.prototype.push.apply(this.team, response);
    //     if (response.length < this.limit) {
    //       this.endScroll = true;
    //     }
    //   });
    // }
  }

  searchUser() {
    this.offset = 0;
    this.limit = 10;
    this.authService.getUserList(this.docId, this.limit, this.offset, this.formFilter.value, this.searchString).subscribe((response: any) => {
      this.team = response;
      console.log(response);
    })
  }

  clearSearchUser() {
    this.searchString = '';
    this.searchUser();
  }

  openFiltredMobile() {
    if (this.menuOpenFilter) {
      this.menuOpenFilter = false;
    } else {
      this.menuOpenFilter = true;
    }
  }

  ngOnInit() {
    if (window.innerWidth <= 1024) {
      this.mobileFilter = true;
      this.menuOpenFilter = false;
    } else {
      this.mobileFilter = false;
      this.menuOpenFilter = true;
    }
    this.authService.getParams().subscribe((response: any) => {
      this.params = response;
      this.brands = response.brands;
      this.channels = response.channels;
      this.partners = response.partners;
      this.positions = response.positions;
      this.regions = response.region;
      this.states = response.states;
      this.documents = response.documents;
      this.formFilter = new FormGroup({
        'brand_id': new FormControl(null),
        'channel_id': new FormControl(null),
        'partner_id': new FormControl(null),
        'position_id': new FormControl(null),
        'region_id': new FormControl(null),
        'state_id': new FormControl(null),
        'test_off': new FormControl(null),
      })
      this.authService.getUserList(this.docId, this.limit, this.offset, this.formFilter.value, this.searchString).subscribe((response: any) => {
        this.team = response;
      })
    })
  }

}
