import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'dateFormat',
} )
export class DateFormatPipe implements PipeTransform {

  transform( value: string, format?: string, local: string = 'ru', ...args ) {
    const locales = {
      ru: [
        'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября',
        'октября', 'ноября', 'декабря',
      ],
    };

    const date = new Date( Number( value ) );
    let result = '';

    switch ( format ) {
      case 'short':
        // dd.mm, hh:mm
        result = date.getDate() +
          '.' +
          ('0' + (date.getMonth() + 1)).slice( -2 ) +
          ', ' +
          ('0' + (date.getHours())).slice( -2 ) +
          ':' +
          ('0' + (date.getMinutes())).slice( -2 );
        break;
      case 'medium':
        // dd.mm.yy, hh:mm
        result = date.getDate() +
          '.' +
          ('0' + (date.getMonth() + 1)).slice( -2 ) +
          '.' +
          date.getFullYear() +
          ', ' +
          ('0' + (date.getHours())).slice( -2 ) +
          ':' + ('0' + (date.getMinutes())).slice( -2 );
        break;
      case 'long':
        // dd month yy, hh:mm
        result = date.getDate() +
          ' ' +
          locales[ local ][ date.getMonth() + 1 ] +
          ' ' +
          date.getFullYear() +
          ', ' +
          ('0' + (date.getHours())).slice( -2 ) +
          ':' +
          ('0' + (date.getMinutes())).slice( -2 );
        break;

      case 'shortDate':
        // dd.mm
        result = date.getDate() + '.' + ('0' + (date.getMonth() + 1)).slice( -2 );
        break;
      case 'mediumDate':
        // dd.mm.yy
        result = date.getDate() + '.' + ('0' + (date.getMonth() + 1)).slice( -2 ) + '.' + date.getFullYear();
        break;
      case 'longDate':
        // dd month yy
        result = date.getDate() + ' ' + locales[ local ][ date.getMonth() + 1 ] + ' ' + date.getFullYear();
        break;

      case 'shortTime':
        // hh:mm
        result = ('0' + (date.getHours())).slice( -2 ) + ':' + ('0' + (date.getMinutes())).slice( -2 );
        break;
      case 'mediumTime':
        // hh:mm:ss
        result = ('0' + (date.getHours())).slice( -2 ) +
          ':' +
          ('0' + (date.getMinutes())).slice( -2 ) +
          ':' +
          ('0' + (date.getSeconds())).slice( -2 );
        break;

      default:
        // hh:mm, dd month,
        result = ('0' + (date.getHours())).slice( -2 ) +
          ':' +
          ('0' + (date.getMinutes())).slice( -2 ) +
          ', ' +
          date.getDate() +
          ' ' +
          locales[ local ][ date.getMonth() + 1 ];
        break;
    }

    return result;
  }
}
