<div class="custom_dialog user_change">
  <form [formGroup]="form" (ngSubmit)="onConfirmDeadline()" enctype="multipart/form-data" *ngIf="type === 'deadline'">
    <div mat-dialog-title class="dialog-text" [innerHTML]="title | safeHtml"></div>

    <div mat-dialog-content class="dialog-content" [innerHTML]="text | safeHtml"></div>

    <div mat-dialog-content class="dialog-content">
      <input
        placeholder="Текущая дата: {{currentDate | timestampToDate: 'shortDate'}}"
        formControlName="deadline"
        [matDatepicker]="picker"
        (click)="picker.open()"
        (dateChange)="dateChange($event, form.get('deadline'))"
        readonly
        class="date_input"
        [min]="checkDate(currentDate)"
      >
      <mat-datepicker #picker></mat-datepicker>
    </div>

    <button type="submit" [disabled]="!this.form.get('deadline').value" class="dialog_button user_change_button">ОТПРАВИТЬ</button>
  </form>

  <form [formGroup]="form" (ngSubmit)="onConfirmAttempts()" enctype="multipart/form-data" *ngIf="type === 'attempts'">
    <div mat-dialog-title class="dialog-text" [innerHTML]="title | safeHtml"></div>

    <div mat-dialog-content class="dialog-content" [innerHTML]="text | safeHtml"></div>

    <div mat-dialog-content class="dialog-content">
      <input
        placeholder="Текущее кол-во попыток: {{numAttempts}}"
        type="number"
        min="1"
        formControlName="numAttempts"
      >
    </div>

    <button type="submit" [disabled]="!this.form.get('numAttempts').value" class="dialog_button user_change_button_test">ОТПРАВИТЬ</button>
  </form>
</div>
