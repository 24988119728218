import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'halfArray'
})

export class HalfArrayPipe implements PipeTransform {
  transform(arr: any, side: string): any {
    switch (side) {
      case 'left':
        return arr.slice(0, (arr.length / 2))
      case 'right':
        return arr.slice((arr.length / 2), arr.length)
      default:
        return arr
    }
  }
}
