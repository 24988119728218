import {Directive, HostListener} from "@angular/core";
import {ExtraService} from "services";

@Directive({
  selector: '[scrollMenuListener]'
})

export class ScrollMenuListener {

  constructor(
    private extraService: ExtraService
  ) {}

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    this.extraService.scrollMenuTop = event.target.scrollTop;
  }
}
