<app-header></app-header>

<div class="outer">
  <div class="workspace personal"
       #workspace
       scrollButtonListener
       [ngClass]="{'mobile_menu': extraService.showUserMobile}">
    <app-side-menu></app-side-menu>
    <div class="container_menu" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}" [@show_preview]="showPrev">
      <div class="group_menu">
        <div
          class="group_profile"
          routerLinkActive="active"
          [routerLink]="['/reports-home/infographics']"
        >PPD News</div>
        <div
          class="group_action"
          routerLinkActive="active"
          [routerLink]="['/reports-home/attestation']"
        >Аттестация</div>
        <div
          class="group_action"
          routerLinkActive="active"
          [routerLink]="['/reports-home/reports']"
        >Все отчеты</div>
      </div>
      <router-outlet></router-outlet>
    </div>
    <app-scroll-to-top [workspace]="workspace"></app-scroll-to-top>
  </div>

</div>
