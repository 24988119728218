import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { StatisticsService } from 'services/api';

import { ExtraService } from 'services';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-statistics',
  templateUrl: 'statistics.component.html',
  styleUrls: ['statistics.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class StatisticsComponent implements OnInit{

  showPrev = 'show';

  score = undefined;
  historyScore = undefined;
  score_desc: string;
  maxResult: any;

  testAvailable = undefined;
  testNonStarted = undefined;
  testComplited: any;

  constructor(
    private router: Router,
    private statisticsService: StatisticsService,
    public extraService: ExtraService,
  ) {
  }

  goDocument(section, id) {
    this.router.navigate([section + '/documents/' + id]);
  }

  goTest(checkOver, testId) {
    if (!checkOver) {
      this.router.navigate(['test/' + testId]);
    } else {
      return;
    }
  }

  goAttempts(showAnswers, section, attempts) {
    if (!showAnswers) {
      return
    }
    this.extraService.attempts = attempts;
    this.router.navigate(['attempts/' + section])
  }

  ngOnInit() {
    this.statisticsService.getUserScore().subscribe((response: any) => {
      const declination = (number, ending, cases = [2, 0, 1, 1, 1, 2]) => ending[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
      this.score = response.scores
      this.historyScore = response.history;
      this.score_desc = declination(this.score, ['балл', 'балла', 'баллов'])
    });
    this.statisticsService.getTestsAvailable().subscribe((response: any) => {
      this.testAvailable = response;
    });

    this.statisticsService.getTestsComplited().subscribe((response: any) => {
      this.testComplited = response;
    });
    this.statisticsService.getTestsNonStarted().subscribe((response: any) => {
      this.testNonStarted = response;
    });
  }
}
