import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeURL',
})
export class SafePipeUrl implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(style: any) {
    if (style !== '' && style !== undefined) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(style);
    } else {
      if (style == null) {
        style = '';
      }
      return style;
    }
  }
}
