import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { forkJoin } from 'rxjs';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ExtraService, DialogService } from 'services';
import { ManagementApiService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { AlertDialogComponent } from 'components/dialogs';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { takeUntil, tap } from 'rxjs/operators';
import { DestroyerService } from 'core/services';

@Component({
  selector: 'app-section-management',
  templateUrl: 'section-management.component.html',
  styleUrls: ['section-management.component.scss'],
  providers: [DestroyerService],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class SectionManagementComponent implements OnInit {

  @Input() noShowMenu = false;

  @ViewChild( 'fileInput' ) fileInput;
  file: any;
  loadFile = '';

  showPrev = 'show';
  section = undefined;
  folders = undefined;
  docs = undefined;
  archiveDocs = undefined;

  inactiveButton = false;

  trashCount = undefined;

  historyMail = false;
  mailSend = undefined;
  searchStringMail = '';

  searchStringDocument = '';

  requests = undefined;
  subtheme = undefined;
  department = undefined;
  theme = undefined;
  status = undefined;
  formFeedback = undefined;
  feedbackStatistics = undefined;

  constructor(
    public extraService: ExtraService,
    private dialogService: DialogService,
    private managementService: ManagementApiService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private destroy$: DestroyerService
  ) {
  }

  goBack() {
    this.router.navigate(['management'])
  }

  goAdd(addType) {
    this.router.navigate(['section-management/' + this.section + '/' + addType]);
  }

  goEdit(editType, id) {
    this.router.navigate(['section-management/' + this.section + '/' + editType + '/' + id])
  }

  goTrash() {
    this.router.navigate(['management/trash/' + this.section]);
  }

  doArchive(event, id, archive) {
    event.stopPropagation();

    if (archive) {
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('archive')).subscribe((res: any) => {
        if (res) {
          this.managementService.managementArchiveDocs(id).subscribe(()=> {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-arch-doc'));
            this.searchDocument();
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          });
        } else {
          return;
        }
      })
    } else {
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('unarchive')).subscribe((res: any) => {
        if (res) {
          this.managementService.managementUnarchiveDocs(id).subscribe(()=> {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-unarch-doc'));
            this.searchDocument();
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          });
        } else {
          return;
        }
      })
    }

  }

  changeDialogData(type, text?) {
    let data = {};
    switch (type) {
      case 'trash-doc':
        data = {
          title: 'Вы действительно хотите переместить документ в корзину?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'del-folder':
        data = {
          title: 'Вы действительно хотите удалить папку?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'del-test':
        data = {
          title: 'Вы действительно хотите удалить тест?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'del-banner':
        data = {
          title: 'Вы действительно хотите удалить баннер?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'del-notification':
        data = {
          title: 'Вы действительно хотите удалить письмо?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'archive':
        data = {
          title: 'Вы действительно хотите архивировать документ?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'unarchive':
        data = {
          title: 'Вы действительно хотите разархивировать документ?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'copy':
        data = {
          title: 'Вы действительно хотите копировать документ?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'suc-trash-doc':
        data = {
          title: 'Документ успешно перемещен',
          confirmButtonText: 'OK',
        }
        break
      case 'suc-del-folder':
        data = {
          title: 'Папка успешно удалена',
          confirmButtonText: 'OK',
        }
        break
      case 'suc-del-banner':
        data = {
          title: 'Баннер успешно удален',
          confirmButtonText: 'OK',
        }
        break
      case 'suc-del-test':
        data = {
          title: 'Тест успешно удален',
          confirmButtonText: 'OK',
        }
        break
      case 'suc-arch-doc':
        data = {
          title: 'Документ успешно архивирован',
          confirmButtonText: 'OK'
        }
        break
      case 'suc-unarch-doc':
        data = {
          title: 'Документ успешно разархивирован',
          confirmButtonText: 'OK'
        }
        break
      case 'suc-copy-doc':
        data = {
          title: 'Документ успешно копирован',
          confirmButtonText: 'OK'
        }
        break
      case 'suc-users-import':
        data = {
          title: 'Пользователи успешно импортированы',
          confirmButtonText: 'OK'
        }
        break
      case 'suc-del-notification':
        data = {
          title: 'Письмо успешно удалено',
          confirmButtonText: 'OK',
        }
        break
      case 'error':
        data = {
          title: 'Произошла ошибка',
          confirmButtonText: 'OK'
        }
        break
      case 'error-file-format':
        data = {
          title: 'Только xls или xlsx!',
          confirmButtonText: 'OK'
        }
        break
      case 'error-import-file':
        data = {
          title: 'Произошла ошибка',
          text: text,
          confirmButtonText: 'OK'
        }
    }
    return data;
  }

  doDelete(event, id, type) {
    event.stopPropagation();
    switch (type) {
      case 'article':
      case 'course':
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('trash-doc')).subscribe((res) => {
          if (res) {
            this.managementService.managementUpdateDoc(this.section, { is_trash: true }, id).subscribe(() => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-trash-doc'))
              this.searchDocument();
              this.managementService.managementGetTrash(this.section).subscribe((response: any) => {
                this.trashCount = response.length
              })
            }, () => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'))
            });
          }
        });
        break
      case 'folder':
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('del-folder')).subscribe((res) => {
          if (res) {
            this.managementService.managementDeleteFolder(id).subscribe(() => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-del-folder'))
              this.searchDocument();
            }, () => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'))
            });
          }
        });
        break
      case 'final_test':
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('del-test')).subscribe((res) => {
          if (res) {
            this.managementService.managementDeleteTest(id).subscribe(() => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-del-test'))
              this.searchDocument();
            }, () => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'))
            });
          }
        });
        break
      case 'attestation_test':
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('del-test')).subscribe((res) => {
          if (res) {
            this.managementService.managementDeleteAttestationTest(id).subscribe(() => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-del-test'))
              this.searchDocument();
            }, () => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'))
            });
          }
        });
        break
      case 'banner':
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('del-banner')).subscribe((res) => {
          if (res) {
            this.managementService.managementDeleteBanner(id).subscribe(() => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-del-banner'))
              this.searchDocument();
            }, () => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'))
            });
          }
        });
        break
      case 'notifications':
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('del-notification')).subscribe((res) => {
          if (res) {
            this.managementService.managementDeleteNotification(id).subscribe(() => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-del-notification'))
              this.searchDocument();
            }, () => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'))
            });
          }
        });
        break
    }
  }

  doCopy(event, id, type) {
    event.stopPropagation();
    this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('copy')).subscribe((res) => {
      if (res) {
        this.managementService.managementCopyDoc(type, id).subscribe(() => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-copy-doc'))
          this.searchDocument();
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'))
        });
      }
    });
  }

  processWebFile(event) {
    if (event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.file = event.target.files[0];
      return true;
    }
    return this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error-file-format'));
  }

  getFile() {
    this.fileInput.nativeElement.click();
  }

  sendFile() {
    if (!this.inactiveButton) {
      this.inactiveButton = true;
      const formData: FormData = new FormData();
      formData.append( 'file', this.file );

      this.managementService.managementUsersImport(formData).subscribe(() => {
        this.inactiveButton = false;
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-users-import'));
      }, (err) => {
        this.inactiveButton = false;
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error-import-file', err.error.error));
      })
    }
  }

  getTemplate() {
    if (!this.inactiveButton) {
      this.inactiveButton = true;
      this.managementService.managementGetXlsTemplate().subscribe((response: any) => {
        location.assign(response.url);
        this.inactiveButton = false;
      }, () => {
        this.inactiveButton = false;
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
      });
    }
    return;
  }

  usersExport() {
    if (!this.inactiveButton) {
      this.inactiveButton = true;
      this.managementService.managementUsersExport().subscribe((response: any) => {
        location.assign(response.url);
        this.inactiveButton = false;
      }, () => {
        this.inactiveButton = false;
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
      });
    }
    return;
  }

  // getListEntity(section) {
  //   switch (section) {
  //     case 'test':
  //       this.managementService.managementGetSectionsTests().subscribe((response: any) => {
  //         this.docs = response;
  //       })
  //       break
  //     case 'banner':
  //       this.managementService.managementGetSectionsBanners().subscribe((response: any) => {
  //         this.docs = response;
  //       })
  //       break
  //     case 'attestation-tests':
  //       this.managementService.managementGetSectionsAttestationTests().subscribe((response: any) => {
  //         this.docs = response;
  //       })
  //       break
  //     case 'notifications':
  //       this.managementService.managementGetNotificationsList().subscribe((response: any) => {
  //         this.docs = response;
  //       })
  //       break
  //     default:
  //       forkJoin([
  //         this.managementService.managementGetSectionsFolders(section),
  //         this.managementService.managementGetSectionsDocs(section),
  //         this.managementService.managementGetSectionsDocs(section, true),
  //       ]).subscribe((response: any) => {
  //         this.folders = response[0];
  //         this.docs = response[1];
  //         this.archiveDocs = response[2];
  //       });
  //       break
  //   }
  // }

  openHistory() {
    if (!this.historyMail) {
      this.historyMail = true
      this.managementService.managementGetNotificationsListSend().subscribe((response: any) => {
        this.mailSend = response;
      })
    } else {
      this.historyMail = false
    }
  }


  searchMail() {
    this.managementService.managementGetNotificationsListSend(this.searchStringMail).subscribe((response: any) => {
      this.mailSend = response;
    })
  }
  clearSearchMail() {
    this.searchStringMail = '';
    this.searchMail();
  }

  searchFeedback() {
    this.managementService.managementFeedbackList(this.searchStringDocument, this.formFeedback.value.theme, this.formFeedback.value.department, this.formFeedback.value.subtheme, this.formFeedback.value.status).subscribe((response: any) => {
      this.requests = response;
    })
  }

  searchDocument() {
    switch (this.section) {
      case 'test':
        this.managementService.managementGetSectionsTests(this.searchStringDocument).subscribe((response: any) => {
          this.docs = response;
        })
        break
      case 'banner':
        this.managementService.managementGetSectionsBanners(this.searchStringDocument).subscribe((response: any) => {
          this.docs = response;
        })
        break
      case 'attestation-tests':
        this.managementService.managementGetSectionsAttestationTests(this.searchStringDocument).subscribe((response: any) => {
          this.docs = response;
        })
        break
      case 'notifications':
        this.managementService.managementGetNotificationsList(this.searchStringDocument).subscribe((response: any) => {
          this.docs = response;
        })
        break
      case 'feedback':
        this.managementService.managementFeedbackList(this.searchStringDocument).subscribe((response: any) => {
          this.requests = response;
        })
        break
      default:
        this.managementService.managementDocumentsSearch(this.searchStringDocument, this.section)
        .subscribe((response: any) => {
          this.docs = response.documents;
          this.folders = response.folders;
          this.archiveDocs = response.archive;
        })
        break
    }
  }
  clearSearchDocument() {
    this.searchStringDocument = '';
    this.searchDocument();
  }

  filterChange(data: any): void {
    this.managementService.mngDocsSearch(data)
    .pipe(
      tap((response: any) => {
        this.docs = response.documents;
        this.folders = response.folders;
        this.archiveDocs = response.archive;
      }),
      takeUntil(this.destroy$)
    )
    .subscribe();
  }


  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.section = params.section;
      this.searchDocument();
      this.managementService.managementGetTrash(this.section).subscribe((response: any) => {
        this.trashCount = response.length
      })
    });
    this.managementService.managementFeedbackStatistics().subscribe( (response: any) => {
      this.feedbackStatistics = response
    })
    this.managementService.managementFeedbackParams().subscribe( (response: any) => {
      this.subtheme = response.subtheme;
      this.department = response.department;
      this.theme = response.theme;
      this.status = response.status;
    })
    this.formFeedback = new FormGroup( {
      'subtheme': new FormControl(null),
      'department': new FormControl(null),
      'theme': new FormControl(null),
      'status': new FormControl(null),
    })
  }
}
