<app-header [noShowMenu]="true"></app-header>
<div class="outer">
  <div class="workspace doc" #workspace scrollButtonListener *ngIf="docBody" [@show_preview]="showPrev" id="top" [ngStyle]="{'backgroundImage': 'url(' + (section === 'lftf' ? '/assets/images/lftf_bg.jpg' : '') + ')', 'backgroundRepeat': 'no-repeat', 'backgroundPosition': 'center center', 'backgroundSize': 'cover'}">
    <div class="back_button" (click)="goBack()">
      <div class="back_icon"></div>
      <div class="back_title">{{ docBody.title }}</div>
    </div>
    <app-content-blocks [arrayContent]="docBody.elements"></app-content-blocks>

    <div class="test_button fin-test" *ngIf="docBody.test !== null && testAvailable" (click)="goTest(docBody.test)">ФИНАЛЬНЫЙ ТЕСТ. ПРОЙТИ ДО {{docBody.end_date | dateFormat: 'medium'}}</div>
    <div class="test_button" *ngIf="docBody.attestation !== null && attestationAvailable" (click)="goAttestationTest(docBody.attestation.id)">
      <div class="text">
        <div>НАЧАТЬ ТЕСТИРОВАНИЕ</div>
        <div>{{declination(docBody.attestation.available_attempts, [' (осталась ', ' (осталось ', '(осталось ']) + docBody.attestation.available_attempts + declination(docBody.attestation.available_attempts, [' попытка)', ' попытки)', ' попыток)'])}}</div>
      </div>
    </div>

    <div class="next-document-container" *ngIf="section === 'business' && docBody.next_id">
      <div class="test_button next-document" (click)="goDocument(docBody)">
        <p>Перейти к теме {{docBody.next_id.title}} </p>
      </div>
    </div>

    <div *ngIf="docBody.chat">
      <div class="container_support">
        <h1>КОММЕНТАРИИ К КУРСУ</h1>
        <a #support href="mailto:support@ppdportal.loreal.com.ru" style="display: none"></a>
        <div class="support"  (click)="getSupport()"></div>
      </div>

      <app-chat [chatId]="chatId"></app-chat>
    </div>
    <app-scroll-to-top [workspace]="workspace"></app-scroll-to-top>
  </div>

</div>


