<app-header></app-header>

<div class="outer" *ngIf="extraService.profileInfo" id="top">
  <div class="workspace rating"
       #workspace
       scrollButtonListener
       [ngClass]="{'mobile_menu': extraService.showUserMobile}">
    <app-side-menu></app-side-menu>
    <div class="container_menu" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}" [@show_preview]="showPrev">
      <div class="group_menu">
        <div
          class="group_profile"
          routerLinkActive="active"
          [routerLink]="['/rating/current']"
        >Рейтинг</div>
        <div
          class="group_action"
          routerLinkActive="active"
          [routerLink]="['/rating/hall-of-fame']"
        >Зал славы</div>
      </div>
      <router-outlet></router-outlet>

    </div>

    <app-scroll-to-top [workspace]="workspace"></app-scroll-to-top>

  </div>
</div>
