import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BaseService } from 'services/api'
import { AlertDialogComponent } from 'components/dialogs'
import { DialogService } from 'services'

@Component({
  selector: 'app-documents-viewed-dialog',
  templateUrl: './documents-viewed-dialog.component.html',
  styleUrls: ['./documents-viewed-dialog.component.scss'],
})

export class DocumentsViewedDialogComponent {

  rulesCheck = false;

  title: string;
  text: string;
  check_agree: string;
  instructions: string;
  button: string;
  viewedId: number;
  docId: number = null;

  constructor(
    public dialogRef: MatDialogRef<DocumentsViewedDialogComponent>,
    private baseService: BaseService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private dialogData: {
      'title': string,
      id: number,
      'text': string,
      'check_agree': string,
      'instructions': string,
      'button': string,
      docId: number
    },
  ) {
    ({
      title: this.title,
      id: this.viewedId,
      text: this.text,
      check_agree: this.check_agree,
      instructions: this.instructions,
      button: this.button,
      docId: this.docId
    } = this.dialogData);
  }

  changeAgreement(event) {
    if (event.checked) {
      this.rulesCheck = true;
      return;
    }
    this.rulesCheck = false;
    return;
  }

  sendViewed() {
    this.baseService.systemMessageSend(this.viewedId, this.docId).subscribe( () => {
      this.dialogRef.close(true);
    }, () => {
      const data = {
        title: 'Произошла ошибка',
        confirmButtonText: 'OK'
      }
      this.dialogService.openDialog(AlertDialogComponent, data);
    })
  }

}
