import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'findArray'
})

export class FindArrayPipe implements PipeTransform {
  transform(arr: any, id: any): any {
    return arr.find(x => x.id === id).array
  }
}
