<div class="form_container">
  <div class="parent_message" *ngIf="answer">
    <span>{{answer.author.name + ' ' + answer.author.surname}}</span>
    <span class="overflow" *ngIf="answer.text !== ''">{{answer.text}}</span>
    <div class="files_item" *ngIf="answer.text === ''">
      <img *ngIf="answer.files.image" [src]="answer.files.image[0].path" />
      <video *ngIf="answer.files.video" [src]="answer.files.video[0].path | safeURL"></video>
      <div class="application_file" *ngIf="answer.files.application">
        <div class="document_icon">
          <div class="icon"></div>
        </div>
        <div class="document_name">{{answer.files.application[0].name + '.' + answer.files.application[0].ext}}</div>
      </div>
    </div>
    <div class="delete_button answer" (click)="removeAnswer()"></div>
  </div>
  <div class="file_container">
    <div class="file" *ngFor="let loadFile of loadFiles; let i = index">
      <div class="preview" *ngIf="loadFile.type === 'video'">
        <video>
          <source [src]="loadFile.url | safeURL">
        </video>
        <div class="delete_button" (click)="deleteFile(i)"></div>
      </div>
      <div class="preview" *ngIf="loadFile.type === 'image'" [ngStyle]="{'backgroundImage': 'url(' + ((loadFile.url)) + ')'}">
        <div class="delete_button" (click)="deleteFile(i)"></div>
      </div>
      <div class="preview" *ngIf="loadFile.type === 'document'">
        <div class="document_icon">
          <div class="icon"></div>
        </div>
        <div class="document_name">{{loadFile.name}}</div>
        <div class="delete_button" (click)="deleteFile(i)"></div>
      </div>
    </div>
  </div>
  <ngx-file-drop dropZoneLabel="Drop files here"
                 dropZoneClassName="drop_zone"
                 contentClassName="drop_zone_content"
                 (onFileDrop)="dropped($event)"
                 (onFileOver)="fileOver($event)"
                 (onFileLeave)="fileLeave($event)"
  >
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <form class="form_send" [formGroup]="formSend" (ngSubmit)="submit()" *ngIf="formVisible">
          <div class="pick_button" (click)="openFileSelector()"></div>
          <textarea autosize maxlength="500" placeholder="Написать сообщение..." rows="1" formControlName="text" (mouseenter)="closeEmoji()" (click)="closeEmoji()" (keydown)="onKeydown($event)"></textarea>
          <div class="emoji_button" (mouseenter)="openEmoji()"></div>
          <div class="emoji_button_mobile" (click)="openEmoji()"></div>
          <emoji-mart
            class="emoji_mart"
            *ngIf="openEmojiPanel"
            (emojiSelect)="addEmoji($event)"
            emoji=""
            title=""
            emojiTooltip="false"
            [showPreview]=false
            [set]="'google'"
            (mouseleave)="closeEmoji()"
          ></emoji-mart>
          <div *ngIf="!inactiveSend" class="send_button" (click)="submit()" (mouseenter)="closeEmoji()">
            <div class="icon" [ngClass]="{preloader: inactiveSend}"></div>
          </div>
          <div *ngIf="inactiveSend" class="send_button">
            <div class="preloader"></div>
          </div>
        </form>
        <div class="plus_icon" *ngIf="!formVisible"></div>
      </ng-template>
  </ngx-file-drop>
</div>
