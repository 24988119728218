<div class="header_osn">
  <div class="logo_container">
    <div class="logo"></div>
    <div class="logo"></div>
    <div class="logo"></div>
    <div class="logo"></div>
<!--    <div class="logo"></div>-->
  </div>
</div>

<div class="outer auth">
  <div class="circle">
    <form class="form_container" [@show_form]="showForm" [formGroup]="auth" *ngIf="page === 'auth'" (ngSubmit)="onSubmit('auth')">
      <div class="auth_desc">
        Вход на портал PPD
      </div>
      <div class="login_input">
        <div class="login">Логин (email)</div>
        <input formControlName="email" type="email" (change)="checkEmptyEmailField()" #inputLogin>
        <div *ngIf="auth.controls['email'].invalid && auth.controls['email'].touched" class="error_text">
              <span *ngIf="auth.controls['email'].errors.required">
                Поле «Логин» обязательно для заполнения.
              </span>
              <span *ngIf="auth.controls['email'].errors.email">
                Введен некорректный email.
              </span>
        </div>
      </div>
      <div class="password_input" *ngIf="showPasswordField">
        <div class="password">Пароль</div>
        <input
          type="{{passwFieldType}}"
          formControlName="password"
        >
        <i
          class="view-switcher"
          (click)="showPassw()"
        >
          <div class="close_eye">
            <img src="/assets/images/icons/close_eye.png" *ngIf="passwFieldType === 'password'">
            <img src="/assets/images/icons/eye.png" *ngIf="passwFieldType === 'text'">
          </div>
        </i>
      </div>
      <div *ngIf="auth.controls['password'].invalid && auth.controls['password'].touched" class="error_text">
        <span *ngIf="auth.controls['password'].errors.required">
          Поле «Пароль» обязательно для заполнения.
        </span>
      </div>
<!--      <div class="consent_group">-->
<!--        <mat-checkbox formControlName="agreement"></mat-checkbox>-->
<!--        <div class="consent">-->
<!--          Согласие на-->
<!--          <span (click)="onRules()">обработку данных</span>-->
<!--        </div>-->
<!--      </div>-->




      <div class="button_group">
        <button class="come_in_button" [disabled]="auth.invalid" type="submit"  *ngIf="showPasswordField">Войти по логину</button>
        <button class="mail_button" type="submit" *ngIf="showSsoButton">Войти через почту @loreal.com</button>
        <button type="submit" class="check_email_button" *ngIf="showCheckEmailButton" (click)="checkEmailAuth()">Далее</button>
        <div class="restore_password_button" *ngIf="page === 'auth'" (click)="changePage('update')">Восстановить пароль</div>
        <div class="technical_button">
          <a href="mailto:support@ppdportal.loreal.com.ru">Техническая поддержка: support@ppdportal.loreal.com.ru</a>
        </div>
        <div class="support_container">
          <a href="mailto:support@ppdportal.loreal.com.ru"><div class="support"></div></a>
        </div>
      </div>
    </form>

    <form class="input_update_password" [@show_form]="showForm" [formGroup]="update" *ngIf="page === 'update'" (ngSubmit)="onSubmit('update')">
      <div class="update_container">
        <div class="text_update">Восстановление пароля</div>
        <input
          placeholder="E-mail"
          formControlName="email"
          (change)="checkEmptyEmailField()"
        >
        <div *ngIf="update.controls['email'].invalid && update.controls['email'].touched"
             class="error_text">
              <span *ngIf="update.controls['email'].errors.required">
                Поле «E-mail» обязательно для заполнения.
              </span>
              <span *ngIf="auth.controls['email'].errors.email">
                Введен некорректный email.
              </span>
        </div>
      </div>
      <div class="check_email_button update" *ngIf="showCheckEmailButton" (click)="checkEmailAuth()">Далее</div>
      <button [disabled]="update.invalid" class="button_update" *ngIf="showUpdateButton">
        <span>Отправить</span>
      </button>
      <div (click)="changePage('auth')" [disabled]="auth.invalid" class="button_update_auth">
        <span>Назад</span>
      </div>
    </form>

  </div>

</div>
