import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { DialogService } from 'services';
import { AuthService } from 'services/api';

import { AlertDialogComponent } from '..';

@Component({
  selector: 'app-user-change-dialog',
  templateUrl: './user-change-dialog.component.html',
  styleUrls: ['./user-change-dialog.component.scss'],
})
export class UserChangeDialogComponent implements OnInit {
  title: string;
  confirmButtonText = 'OK';

  form: FormGroup;

  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<UserChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {'title': string, confirmButtonText: string },
  ) {
    ({
      title: this.title,
      confirmButtonText: this.confirmButtonText,
    } = this.dialogData);
  }

  changeDialogData(type) {
    let data = {
      'confirmButtonText': 'OK'
    };
    switch (type) {
      case 'wrong password':
        data['title'] = 'Старый пароль не подходит. Попробуйте ещё раз.'
        break
      case 'new_password has wrong format':
        data['title'] = 'Произошла ошибка. Попробуйте ещё раз.';
        data['text'] = '<ul>' +
          '<li>Пароль должен состоять не менее чем из 8 символов.</li>' +
          '<li>Пароль должен содержать цифры и буквы из верхнего и нижнего регистров.</li>' +
          '<li>Пароль должен содержать специальные символы (!, @, #, $ и т.д.)</li>' +
          '</ul>'
        break
      case 'success':
        data['title'] = 'Пароль успешно изменен.'
        break
      default:
        data['title'] = 'Произошла ошибка.'
        break
    }
    return data;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
    this.authService.resetUserPassword(this.form.get('old_password').value, this.form.get('new_password').value).subscribe(() => {
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('success'));
    }, (err) => {
      console.log(err.error.error);
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(err.error.error));
    });
  }

  ngOnInit() {
    this.form = new FormGroup({
      'old_password': new FormControl('', [Validators.required]),
      'new_password': new FormControl('', [Validators.required, Validators.minLength(6)])
    });
  }
}
