import {Pipe, PipeTransform} from '@angular/core';

@Pipe( {
  name: 'findVideoId',
} )

export class FindVideoIdPipe implements PipeTransform{
  transform(string: any): any {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = string.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }
}
