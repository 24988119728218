import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { environment } from "../../../../environments/environment";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService, StatisticsService } from 'services/api';
import { DialogService, StorageService } from 'services';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { AlertDialogComponent } from 'components/dialogs';
@Component({
  selector: 'app-auth',
  templateUrl: 'auth.component.html',
  styleUrls: ['auth.component.scss'],
  animations: [
    trigger('show_form', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(600, style({
          opacity: 1,
        })),
      ]),
      transition('none => show', [
        style({
          opacity: 0,
        }),
        animate(600, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class AuthComponent implements OnInit, AfterViewInit {

  passwFieldType: 'password' | 'text' = 'password';

  page = 'auth'; // Положение на странице авторизации

  showForm = 'show';

  showCheckEmailButton = true;
  showPasswordField = false;
  showSsoButton = false;
  showUpdateButton = false;

  auth: FormGroup | any;
  update: FormGroup | any;

  logs = {};

  @ViewChild('inputLogin') inputLogin: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private statisticsService: StatisticsService,
    private dialogService: DialogService,
    private storage: StorageService
  ) {}

  showPassw() {
    this.passwFieldType = (this.passwFieldType === 'password') ? 'text' : 'password';
  }

  checkEmptyEmailField(ssoEmail?) {
    if (ssoEmail) {
      this.showCheckEmailButton = false;
      this.showPasswordField = false;
      this.showSsoButton = true;
      this.showUpdateButton = false;
      return;
    }
    if (this.auth.get('email').value === '' || this.update.get('email').value === '') {
      this.showCheckEmailButton = true;
      this.showPasswordField = false;
      this.showSsoButton = false;
      this.showUpdateButton = false;
      return;
    }
  }

  checkEmailAuth() {
    let endEmail;
    if (this.page === 'auth') {
      endEmail = this.auth.get('email').value.split('@')[1];
    } else {
      endEmail = this.update.get('email').value.split('@')[1];
    }
    if (endEmail) {
      if (endEmail === 'loreal.com' || endEmail === 'loreal.ru') {
        this.showCheckEmailButton = false;
        this.showPasswordField = false;
        this.showSsoButton = true;
        this.showUpdateButton = false;
        return;
      }
      this.showCheckEmailButton = false;
      this.showPasswordField = true;
      this.showSsoButton = false;
      this.showUpdateButton = true;
      return;
    }
    this.showCheckEmailButton = true;
    this.showPasswordField = false;
    this.showSsoButton = false;
    this.showUpdateButton = false;
    this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('email-error'));
    return;
  }

  changeDialogData(type) {
    let data = {
      'confirmButtonText': 'ОК'
    };
    switch (type) {
      case 'email-error':
        data['title'] = 'Введен некорректный email.'
        break
      case 'authentication failed':
        data['title'] = 'Произошла ошибка. Попробуйте еще раз.'
        break
      case 'count exceed':
        data['title'] = 'Превышен лимит неудачных попыток. Попробуйте ещё раз через 3 минуты.'
        break
      case 'total exceed':
        data['title'] = 'Превышен лимит неудачных попыток. Пожалуйста обратитесь к администратору или руководителю.'
        break
      case 'suc-change-passw':
        data['title'] = 'Новый пароль отправлен на почту.'
        break
      default:
        data['title'] = 'Произошла ошибка, обратитесь в тех.поддержку.'
        break
    }
    return data;
  }

  onSubmit(type): void {
    if (type === 'auth') {
      if (this.showPasswordField) {
        this.authService.authSignIn(this.auth.get('email').value.toLowerCase(), this.auth.get('password').value).subscribe((response: any) => {
          this.authService.saveNewTokens(response);
          if (this.storage.getValue('metrics') && this.storage.getValue('metrics')['time_intervals'].length > 0) {
            this.logs = this.storage.getValue('metrics')['time_intervals'];
            this.statisticsService.addStatisticsLogs(this.logs).subscribe(() => {
              this.storage.setValue('metrics', {'time_intervals': []});
            });
          }
          this.storage.setValue('email', this.auth.get('email').value.toLowerCase())
          this.router.navigate(['main']);
        }, ((err) => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(err.error.error));
        }));
      }
      if (this.showSsoButton) {
        this.ssoLogin()
      }
    } else if (type === 'update') {
      if (this.showUpdateButton) {
        this.authService.authPasswordUpdate(
          this.update.get('email').value.toLowerCase(),
        ).subscribe(
          () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-change-passw'));
          }, () => {
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          });
      }
    }

  }

  onRules() {
     window.open(this.authService.authGetRules());
  }

  ssoLogin() {
    this.storage.setValue('email', this.auth.get('email').value.toLowerCase())
    location.assign(environment.ssoUrl);
  }

  changePage(type) {
    this.showCheckEmailButton = true;
    this.showPasswordField = false;
    this.showSsoButton = false;
    this.showUpdateButton = false;
    this.auth.reset();
    this.update.reset();
    this.page = type;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.inputLogin.nativeElement.focus()
    })
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length < 1) {
        return;
      } else {
        this.authService.saveNewTokens(params);
        this.router.navigate(['main']);
      }
    })
    this.auth = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
    if (this.storage.getValue('email') && this.storage.getValue('email').length > 0) {
      this.auth.get('email').setValue(this.storage.getValue('email'))
      if (this.auth.get('email').value.split('@')[1] === 'loreal.com' || this.auth.get('email').value.split('@')[1] === 'loreal.ru') {
        this.checkEmptyEmailField(true)
      }
    }
    this.update = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }
}

