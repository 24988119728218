<app-header></app-header>
<div class="outer">
  <div class="workspace"
       #workspace
       scrollButtonListener
       [ngClass]="{'mobile_menu': extraService.showUserMobile}"
  >
    <app-side-menu></app-side-menu>
    <section class="content" [@show_preview]="showPrev" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}">
      <h1>Управление контентом</h1>
      <div class="management_sections_buttons_container" *ngIf="contentSections">
        <div class="management_sections_button" *ngFor="let section of contentSections" (click)="goContentManagement(section.reference)">
          <div class="management_sections_button_icon"
               [ngClass]="{
              'main': section.reference === 'main',
              'archive': section.reference === 'archive',
              'important': section.reference === 'important',
              'attestation': section.reference === 'attestation',
              'integration': section.reference === 'integration',
              'library': section.reference === 'library',
              'test': section.reference === 'test' || section.reference === 'attestation-tests',
              'banners': section.reference === 'banner',
              'business': section.reference === 'business',
              'feedback': section.reference === 'feedback',
              'lftf': section.reference === 'lftf'
               }"
          ></div>
          <div class="management_sections_button_title">{{section.title}}</div>
        </div>
      </div>
      <h1>Управление пользователями</h1>
      <div class="management_sections_buttons_container" *ngIf="contentSections">
        <div class="management_sections_button" *ngFor="let section of authSections" (click)="goAuthManagement(section.reference)">
          <div class="management_sections_button_icon"
               [ngClass]="{
               'auth': section.reference === 'auth'
               }"
          ></div>
          <div class="management_sections_button_title">{{section.title}}</div>
        </div>
      </div>
      <h1>Управление рассылками</h1>
      <div class="management_sections_buttons_container" *ngIf="notifications">
        <div class="management_sections_button" *ngFor="let section of notifications" (click)="goMailManagement(section.reference)">
          <div class="management_sections_button_icon"
               [ngClass]="{
               'mail_letter': section.reference === 'notifications',
               'feedback': section.reference === 'feedback'
               }"
          ></div>
          <div class="management_sections_button_title">{{section.title}}</div>
        </div>
      </div>
    </section>

    <app-scroll-to-top [workspace]="workspace"></app-scroll-to-top>

  </div>

</div>
