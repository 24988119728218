import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'safeHtml',
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(style: any) {
    if (style !== '' && style !== undefined) {
      return this.sanitizer.bypassSecurityTrustHtml(style);
    } else {
      if (style == null) {
        style = '';
      }
      return style;
    }
  }
}