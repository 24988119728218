import { Component, Inject } from '@angular/core';
import {DialogService, ExtraService} from "services";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "services/api";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AlertDialogComponent, TeamDialogComponent} from "components/dialogs";

@Component({
  selector: 'app-update-user-dialog',
  templateUrl: './update-user-dialog.component.html',
  styleUrls: ['./update-user-dialog.component.scss'],
})

export class UpdateUserDialogComponent {

  userId: any;
  updateInfo: any;
  updateInfoBrand: any;

  formFilter = undefined;
  brands = undefined;
  channels = undefined;
  partners = undefined;
  positions = undefined;
  regions = undefined;
  states = undefined;
  team = undefined;

  public searchFilterCtrl: FormControl = new FormControl();
  selectSearchUpdateString = '';

  profileInfo: any;
  params: any;

  formUpdate: FormGroup;

  constructor(
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<UpdateUserDialogComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) private dialogData: { 'userId': any, profileInfo: any, params: any },
  ) {
    ({
      userId: this.userId,
      profileInfo: this.profileInfo,
      params: this.params,
    } = this.dialogData);
  }

  getId(param) {
    if (param) {
      if (Array.isArray(param)) {
        let arrId = []
        param.map((item) => {
          arrId.push(item['id'])
        })
        return arrId
      }
      return param.id
    }
    return null
  }

  sendUpdateUser() {
    const data = {
      'title': 'Пользователь успешно обновлён',
      'confirmButtonText': 'ОК'
    }
    this.authService.updateUser(this.formUpdate.value).subscribe((response: any) => {
      this.dialogService.openDialog(AlertDialogComponent, data).subscribe(() => {
        this.dialogService.closeDialog()
        this.dialogService.openDialog(TeamDialogComponent, {userId: this.profileInfo.id})
      })
    }, () => {
      data['title'] = 'Произошла ошибка';
      this.dialogService.openDialog(AlertDialogComponent, data);
    });

  }

  ngOnInit() {
    this.formUpdate = new FormGroup( {
      'name': new FormControl(this.profileInfo.name, [Validators.required]),
      'surname': new FormControl(this.profileInfo.surname, [Validators.required]),
      'patronymic': new FormControl(this.profileInfo.patronymic),
      'email': new FormControl(this.profileInfo.email, [Validators.required, Validators.email]),
      'phone': new FormControl(this.profileInfo.phone),
      'channel': new FormControl(this.getId(this.profileInfo.channel)),
      'city': new FormControl(this.getId(this.profileInfo.city)),
      'department': new FormControl(this.getId(this.profileInfo.department)),
      'is_active': new FormControl(this.profileInfo.is_active),
      'partner': new FormControl(this.getId(this.profileInfo.partner)),
      'position': new FormControl(this.getId(this.profileInfo.position)),
      'regions': new FormControl(this.getId(this.profileInfo.regions)),
      'state': new FormControl(this.getId(this.profileInfo.state)),
      'test_off': new FormControl(this.profileInfo.test_off),
      'id': new FormControl(this.profileInfo.id),
      'brands': new FormControl(this.getId(this.profileInfo.brands)),
    });
  }
}
