import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanActivate } from '@angular/router';
import { AuthService } from 'services/api';
import { Router, NavigationCancel } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
  })

export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router,
        private matDialogService: MatDialog,
      ) {}
    

      canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return new Observable( (subscriber) => {
          this.authService.authTokenValidate().subscribe(
            () => {
              console.log('Токен валиден.');
              subscriber.next(true);
            },
            () => {
              this.router.navigate(['/auth']);
              // Закрываем все открытые окна
              this.matDialogService.closeAll();
              subscriber.next(false);
            },
          );
        });
      }
}
