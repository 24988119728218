<div class="select_container">
  <form [formGroup]="formFilter" class="form_filter" *ngIf="formFilter">

    <mat-select class="custom_select for_management" placeholder="Выберите дату и год" formControlName="start">
      <mat-option [value]="null">--</mat-option>
      <mat-option
        *ngFor="let interval of intervals"
        [value]="interval.id">
        {{ interval.id.year }} {{ interval.id.month | getMonthForIndex }}
      </mat-option>
    </mat-select>

    <mat-select class="custom_select for_management" placeholder="Выберите дату и год" formControlName="finish">
      <mat-option [value]="null">--</mat-option>
      <mat-option
        *ngFor="let interval of intervals"
        [value]="interval.id">
        {{ interval.id.year }} {{ interval.id.month | getMonthForIndex }}
      </mat-option>
    </mat-select>

  </form>
  <div class="button-container">
    <div class="button" (click)="searchLeaders(false)">Применить</div>
  </div>
  <div class="button-container">
    <div class="button" (click)="searchLeaders(true)">Очистить</div>
  </div>
</div>
<div
  class="container_history"
  infiniteScroll
  [infiniteScrollDisabled]="endScroll"
  (scrolled)="paginationOn()"
>
  <ng-container *ngFor="let history of this.history">
    <div class="button_history">
      <img class="icon" src="assets/images/icons/rating-history.png">
      <div>Лидеры портала <span style="text-transform: uppercase">{{history.start.month | getMonthForIndex}} {{history.start.year}}</span> <span *ngIf="history.finish" style="text-transform: uppercase"> - {{history.finish.month | getMonthForIndex}} {{history.finish.year}}</span></div>
    </div>
    <div class="card_container">
      <app-card-rating *ngFor="let leaders of history.leaders" [history]="leaders"></app-card-rating>
    </div>
  </ng-container>
</div>


