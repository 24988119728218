import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import {ExtraService} from 'services';
import { ManagementApiService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-management',
  templateUrl: 'management.component.html',
  styleUrls: ['management.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class ManagementComponent implements OnInit {

  @ViewChild('workspace') workspace;

  showPrev = 'show';

  contentSections = undefined;
  authSections = undefined;
  notifications = undefined;

  constructor(
    public extraService: ExtraService,
    private managementService: ManagementApiService,
    private router: Router,
  ) {
  }

  goContentManagement(section) {
    this.router.navigate(['section-management/' + section]);
  }

  goAuthManagement(section) {
    this.router.navigate(['section-management/' + section]);
  }

  goMailManagement(section) {
    this.router.navigate(['section-management/' + section]);
  }

  ngOnInit() {
    this.managementService.managementGetSections().subscribe((response: any) => {
      this.contentSections = response.content;
      this.authSections = response.auth;
      this.notifications = response.notifications;
    });
  }
}
