<app-header [noShowMenu]="true"></app-header>

<div class="outer">
  <div class="workspace management"
       [ngClass]="{'mobile_menu': extraService.showUserMobile}"
  >
    <section class="content" [@show_preview]="showPrev" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}">

      <div class="back_button back_button_management" (click)="goBack()">
        <div class="back_icon"></div>
        <div class="back_title">Назад</div>
      </div>

      <app-form-management
        [type]="addType"
        [form]="form"
        [controls]="controls"
        [section]="section"
      ></app-form-management>

    </section>
  </div>
</div>
