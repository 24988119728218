<div class="custom_dialog management_dialog_form">
  <app-form-management
    [form]="formElements"
    [id]="docId"
    [controls]="controlsElement"
    [type]="type"
    [section]="section"
  >
  </app-form-management>

  <div class="sub_form">

    <div class="container_under_text_red" *ngIf="checkType(type).viewSubFilterForm && !showFilterForm && section === 'business'">
      <div class="under_text_red">Все файлы, кроме видео, которые Вы загружаете, будут доступны для скачивания!
        Убедитесь, что в них нет конфиденциальной информации</div>
    </div>

    <div class="container_under_text" *ngIf="checkType(type).viewSubFilterForm && !showFilterForm && section === 'business'">
      <div class="under_text">1. Установите фильтры элемента (по умолчанию вся коммерция)</div>
    </div>

    <div class="add_files_container" *ngIf="checkType(type).viewSubFilterForm && !showFilterForm">
      <h1>{{checkType(type).titleSubFilterFormContainer}}</h1>
      <div class="button file_button" (click)="createFilterElement()">{{checkType(type).titleSubFilterFormAddButton}}</div>
    </div>

    <div *ngIf="checkType(type).viewSubFilterForm" class="filters">
      <div class="element_container">
        <div class="element disabled_edit" *ngFor = "let filter of arrFilters; let i = index">
          <div class="info_container">
            <div class="element_index">{{i + 1}}</div>
            <div class="element_type"><span></span>{{filter.title}}</div>
          </div>
          <div class="element_button_container">
            <div class="delete_button" (click)="deleteFilterElement(filter.id, i)"></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="checkType(type).viewSubFilterForm && showFilterForm">
      <div class="files_container">
        <div class="delete_button" (click)="closeFilterForm()"></div>
        <app-form-management
          [form]="formFilter"
          [controls]="controlsFilter"
          [section]="section"
          [type]="type"
        ></app-form-management>
      </div>
    </div>

    <div class="container_under_text" *ngIf="checkType(type).viewSubForm && section === 'business'">
      <div class="under_text">2. Добавьте файлы</div>
    </div>

    <div class="add_files_container" *ngIf="checkType(type).viewSubForm">
      <h1>{{checkType(type).titleSubFormContainer}}</h1>
      <div class="button file_button" (click)="createFileElement()">{{checkType(type).titleSubFormAddButton}}</div>
    </div>

    <div *ngFor="let form of arrFiles; let i = index" class="files_container">
      <div class="delete_button" (click)="deleteFileElement(form, i)"></div>
      <app-form-management
        [form]="form.form"
        [controls]="controlsFile"
        [section]="section"
        [type]="type"
      ></app-form-management>
    </div>

    <button class="button submit_button" (click)="collectForms()" [disabled]="inactiveSubmit" [ngClass]="{no_show: inactiveSubmit}">{{checkType(type).titleFormSubmitButton}}</button>

    <div class="preloader" *ngIf="inactiveSubmit">
      <div class="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>

</div>


