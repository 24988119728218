export * from './auth/auth.component';
export * from './auth-management/auth-management.component';
export * from './main/main.component';
export * from './document/document.component';
export * from './test/test.component';
export * from './personal/personal.component';
export * from './reports/reports-home.component';
export * from './attempts/attempts.component';
export * from './important/important.component';
export * from './archive/archive.component';
export * from './library/library.component';
export * from './lftf/lftf.component';
export * from './management/management.component';
export * from './attestation/attestation.component';
export * from './attestation-test/attestation-test.component';
export * from './business-code/business-code.component';
export * from './feedback/feedback.component'
export * from './rating/rating.component'
