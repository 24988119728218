import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from 'services';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { FilterGroupItem, FilterItem } from 'app/typings/api';

@Injectable({
  providedIn: 'root',
})

export class ManagementApiService {

  private readonly root = environment.url + 'api/';

  constructor(
    private httpClient: HttpClient,
    private storage: StorageService,
  ) {}

  getToken(): string {
    const token = this.storage.getValue('token-management');
    return 'Token ' + token;
  }

  private readonly managementGetSectionsUrl = this.root + 'mng/sections/';

  private readonly managementGetSectionsArticleUrl = this.root + 'mng/docs/article/list';
  private readonly managementGetSectionsCourseUrl = this.root + 'mng/docs/course/list';
  private readonly managementGetSectionsAttestationUrl = this.root + 'mng/docs/attestation/list';
  private readonly managementGetSectionsFolderUrl = this.root + 'mng/docs/folder/list';

  private readonly managementGetSectionsBannersUrl = this.root + 'mng/main/banner/list';
  private readonly managementGetBannerImagesUrl = this.root + 'mng/main/image/list/';
  private readonly managementGetBannerImageUrl = this.root + 'mng/main/image/';
  private readonly managementGetBannerFiltersUrl = this.root + 'mng/main/filter/list/';
  private readonly managementGetBannerFilterUrl = this.root + 'mng/main/filter/';

  private readonly managementGetFiltersUrl = this.root + 'mng/docs/filter/list/';
  private readonly managementGetElementsUrl = this.root + 'mng/docs/element/list/';
  private readonly managementGetFilesUrl = this.root + 'mng/docs/file/list/';

  private readonly managementGetSectionsTestsUrl = this.root + 'mng/tests/test/list';
  private readonly managementGetSectionsAttestationTestsUrl = this.root + 'mng/attestation-tests/test/list';
  private readonly managementGetQuestionsUrl = this.root + 'mng/tests/question/list/'
  private readonly managementGetQuestionsAttestationUrl = this.root + 'mng/attestation-tests/question/list/'
  private readonly managementGetOptionsUrl = this.root + 'mng/tests/option/list/'
  private readonly managementGetOptionsAttestationUrl = this.root + 'mng/attestation-tests/option/list/'
  private readonly managementGetFiltersQuestionAttestationUrl = this.root + 'mng/attestation-tests/filter/list/'

  private readonly managementGetTypesArticleUrl = this.root + 'mng/docs/article/types';
  private readonly managementGetTypesCourseUrl = this.root + 'mng/docs/course/types';
  private readonly managementGetTypesAttestationUrl = this.root + 'mng/docs/attestation/types';
  private readonly managementGetTypesFolderUrl = this.root + 'mng/docs/folder/types';

  private readonly managementGetTypesBannerUrl = this.root + 'mng/main/banner/types';
  private readonly managementGetTypesImageUrl = this.root + 'mng/main/image/types';
  private readonly managementGetTypesBannerFiltersUrl = this.root + 'mng/main/filter/types';

  private readonly managementGetTypesElementsUrl = this.root + 'mng/docs/element/types';
  private readonly managementGetTypesFileUrl = this.root + 'mng/docs/file/types';
  private readonly managementGetTypesFiltersUrl = this.root + 'mng/docs/filter/types';
  private readonly managementGetFilterUrl = this.root + 'mng/docs/filter/';

  private readonly managementGetTypesTestUrl = this.root + 'mng/tests/test/types';
  private readonly managementGetTypesAttestationTestUrl = this.root + 'mng/attestation-tests/test/types';
  private readonly managementGetTypesQuestionsUrl = this.root + 'mng/tests/question/types';
  private readonly managementGetTypesQuestionsAttestationUrl = this.root + 'mng/attestation-tests/question/types';
  private readonly managementGetTypesOptionUrl = this.root + 'mng/tests/option/types/'
  private readonly managementGetTypesOptionAttestationUrl = this.root + 'mng/attestation-tests/option/types/';
  private readonly managementGetTypesFilterQuestionAttestationUrl = this.root + 'mng/attestation-tests/filter/types';
  private readonly managementGetParamsQuestionAttestationUrl = this.root + 'mng/attestation-tests/params/';

  private readonly managementGetArticleUrl = this.root + 'mng/docs/article/';
  private readonly managementGetCourseUrl = this.root + 'mng/docs/course/';
  private readonly managementGetAttestationUrl = this.root + 'mng/docs/attestation/';
  private readonly managementGetTestUrl = this.root + 'mng/tests/test/';
  private readonly managementGetAttestationTestUrl = this.root + 'mng/attestation-tests/test/'
  private readonly managementGetFolderUrl = this.root + 'mng/docs/folder/';
  private readonly managementGetBannerUrl = this.root + 'mng/main/banner/';

  private readonly managementGetAttestationQuestionTemplateUrl = this.root + 'mng/attestation-tests/question/template/';
  private readonly managementAttestationQuestionImportUrl = this.root + 'mng/attestation-tests/question/import/';

  private readonly managementGetXlsTemplateUrl = this.root + 'mng/auth/template/';
  private readonly managementUsersImportUrl = this.root + 'mng/auth/import/';
  private readonly managementUsersExportUrl = this.root + 'mng/auth/export/';

  private readonly managementGetTrashUrl = this.root + 'mng/docs/document/trash'
  private readonly managementDocumentSearchUrl = this.root + 'mng/docs/search'

  private readonly managementGetNotificationsListUrl = this.root + 'mng/notifications/draft/list?is_send=False';
  private readonly managementGetNotificationsListSendUrl = this.root + 'mng/notifications/draft/list?is_send=True';
  private readonly managementGetNotificationsMailTypesUrl = this.root + 'mng/notifications/draft/types';
  private readonly managementGetNotificationsUrl = this.root + 'mng/notifications/draft/';
  private readonly managementGetNotificationsFileTypesUrl = this.root + 'mng/notifications/file/types';
  private readonly managementGetNotificationsFileListUrl = this.root + 'mng/notifications/file/list/';
  private readonly managementGetNotificationsFileCreateUrl = this.root + 'mng/notifications/file/create/';
  private readonly managementGetNotificationTypesFiltersUrl = this.root + 'mng/notifications/filter/types';
  private readonly managementGetNotificationFiltersListUrl = this.root + 'mng/notifications/filter/list/';
  private readonly managementGetNotificationFilterCreateUrl = this.root + 'mng/notifications/filter/create/';


  private readonly managementFeedbackListUrl = this.root + 'mng/feedback/request/list';
  private readonly managementFeedbackGetUrl = this.root + 'mng/feedback/request/';
  private readonly managementFeedbackFileGetUrl = this.root + 'mng/feedback/request-file/list/';
  private readonly managementFeedbackParamsUrl = this.root + 'mng/feedback/params';
  private readonly managementFeedbackMessageGetUrl = this.root + 'mng/feedback/message/list/';
  private readonly managementFeedbackMessageFileGetUrl = this.root + 'mng/feedback/message-file/list/';
  private readonly managementFeedbackMessageCreateUrl = this.root + 'mng/feedback/message/create/';
  private readonly managementFeedbackMessageFileCreateUrl = this.root + 'mng/feedback/message-file/create/';
  private readonly managementFeedbackStatisticsUrl = this.root + 'mng/feedback/statistics';



  managementGetSections() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });

    return this.httpClient.post(
      this.managementGetSectionsUrl,
      null,
      { headers },
    );
  }

  managementGetSectionsDocs(section, archive = false) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    let body = { section__reference: section }
    if (archive) {
      body['archive'] = archive;
    }
    if (section === 'main' || section === 'integration' || section === 'library' || section === 'lftf' || section === 'important' || section === 'business') {
      return this.httpClient.post(
        this.managementGetSectionsCourseUrl,
        body,
        { headers },
      );
    }
    if (section === 'attestation') {
      return this.httpClient.post(
        this.managementGetSectionsAttestationUrl,
        body,
        { headers }
      )
    }
    return this.httpClient.post(
      this.managementGetSectionsArticleUrl,
      body,
      { headers },
    );
  }

  managementGetSectionsBanners(search = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetSectionsBannersUrl,
      {query: search},
      { headers },
    );
  }

  managementGetSectionsTests(search = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetSectionsTestsUrl,
      {query: search},
      { headers },
    );
  }

  managementGetSectionsAttestationTests(search = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetSectionsAttestationTestsUrl,
      {query: search},
      { headers },
    );
  }

  managementGetSectionsFolders(section) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetSectionsFolderUrl,
      { section__reference: section },
      { headers },
    );
  }

  managementGetTypesDoc(section) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    if (section === 'main' || section === 'integration' || section === 'library' || section === 'lftf' || section === 'important' || section === 'business') {
      return this.httpClient.post(
        this.managementGetTypesCourseUrl,
        { section__reference: section },
        { headers }
      )
    }
    if (section === 'attestation') {
      return this.httpClient.post(
        this.managementGetTypesAttestationUrl,
        { section__reference: section },
        { headers }
      )
    }
    return this.httpClient.post(
      this.managementGetTypesArticleUrl,
      { section__reference: section },
      { headers },
    );
  }

  managementGetTypesFolder(section) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesFolderUrl,
      { section__reference: section },
      { headers },
    );
  }

  managementGetTypesBanner(section) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesBannerUrl,
      { section__reference: section },
      { headers },
    );
  }

  managementGetTypesImage(section) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesImageUrl,
      { section__reference: section },
      { headers },
    );
  }

  managementGetTypesBannerFilters(section) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesBannerFiltersUrl,
      { section__reference: section },
      { headers },
    );
  }

  managementGetTypesFilters(section) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesFiltersUrl,
      { section__reference: section },
      { headers },
    );
  }

  managementGetFilter(filterId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetFilterUrl + filterId,
      null,
      { headers },
    );
  }

  managementGetTypesElements(section) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesElementsUrl,
      { section__reference: section },
      { headers }
    );
  }

  managementGetTypesFile(section) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesFileUrl,
      { section__reference: section },
      { headers }
    );
  }

  managementGetTypesTest() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesTestUrl,
      null,
      { headers },
    );
  }

  managementGetTypesAttestationTest() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesAttestationTestUrl,
      null,
      { headers },
    );
  }

  managementGetTypesQuestions() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesQuestionsUrl,
      null,
      { headers }
    );
  }

  managementGetTypesQuestionsAttestation() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesQuestionsAttestationUrl,
      null,
      { headers }
    );
  }

  managementGetTypesOption(questionType) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesOptionUrl + questionType,
      null,
      { headers }
    );
  }

  managementGetTypesOptionAttestation(questionType) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesOptionAttestationUrl + questionType,
      null,
      { headers }
    );
  }

  managementGetTypesFilterQuestionAttestation() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTypesFilterQuestionAttestationUrl,
      null,
      { headers }
    );
  }

  managementGetParamsQuestionAttestation() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetParamsQuestionAttestationUrl,
      null,
      { headers }
    );
  }

  managementGetDoc(section, docId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    if (section === 'main' || section === 'integration' || section === 'library' || section === 'lftf' || section === 'important' || section === 'business') {
      return this.httpClient.post(
        this.managementGetCourseUrl + docId,
        null,
        { headers }
      )
    }
    if (section === 'attestation') {
      return this.httpClient.post(
        this.managementGetAttestationUrl + docId,
        null,
        { headers }
      )
    }
    return this.httpClient.post(
      this.managementGetArticleUrl + docId,
      null,
      { headers },
    );
  }

  managementGetFilters(docId: number): Observable<FilterItem[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post<FilterItem[]>(
      this.managementGetFiltersUrl + docId,
      null,
      { headers }
    );
  }

  managementGetElements(docId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetElementsUrl + docId,
      null,
      { headers }
    );
  }

  managementGetFiles(elementId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetFilesUrl + elementId,
      null,
      { headers }
    );
  }

  managementGetTest(testId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTestUrl + testId,
      null,
      { headers },
    );
  }

  managementGetAttestationTest(testId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetAttestationTestUrl + testId,
      null,
      { headers },
    );
  }

  managementGetQuestions(testId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetQuestionsUrl + testId,
      null,
      { headers }
    );
  }

  managementGetQuestionsAttestation(testId, filters?) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    if (filters) {
      return this.httpClient.post(
        this.managementGetQuestionsAttestationUrl + testId,
        {filters: filters},
        { headers }
      );
    }
    return this.httpClient.post(
      this.managementGetQuestionsAttestationUrl + testId,
      null,
      { headers }
    );
  }

  managementGetOption(questionId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetOptionsUrl + questionId,
      null,
      { headers }
    );
  }

  managementGetOptionAttestation(questionId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetOptionsAttestationUrl + questionId,
      null,
      { headers }
    );
  }

  managementGetFiltersQuestionAttestation(questionId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetFiltersQuestionAttestationUrl + questionId,
      null,
      { headers }
    );
  }

  managementGetFolder(folderId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetFolderUrl + folderId,
      null,
      { headers },
    );
  }

  managementGetBanner(bannerId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetBannerUrl + bannerId,
      null,
      { headers },
    );
  }

  managementGetBannerImages(bannerId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetBannerImagesUrl + bannerId,
      null,
      { headers },
    );
  }

  managementGetBannerImage(imageId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetBannerImageUrl + imageId,
      null,
      { headers },
    );
  }

  managementGetBannerFilters(docId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetBannerFiltersUrl + docId,
      null,
      { headers }
    );
  }

  managementGetBannerFilter(filterId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetBannerFilterUrl + filterId,
      null,
      { headers }
    );
  }

  managementCreateDoc(section, data) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    if (section === 'main' || section === 'integration' || section === 'library' || section === 'lftf' || section === 'important' || section === 'business') {
      return this.httpClient.post(
        this.root + 'mng/docs/course/create',
        data,
        { headers }
      );
    }
    if (section === 'attestation') {
      return this.httpClient.post(
        this.root + 'mng/docs/attestation/create',
        data,
        { headers }
      )
    }
    return this.httpClient.post(
      this.root + 'mng/docs/article/create',
      data,
      { headers }
    );
  }

  managementCreateFilter(data, docId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/filter/create/' + docId,
      data,
      { headers }
    );
  }

  managementCreateElement(data, docId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/element/create/' + docId,
      data,
      { headers }
    );
  }

  managementCreateFileElement(data, elementId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/file/create/' + elementId,
      data,
      { headers }
    );
  }

  managementCreateTest(data) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/tests/test/create',
      data,
      { headers }
    )
  }

  managementCreateAttestationTest(data) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/attestation-tests/test/create',
      data,
      { headers }
    )
  }

  managementCreateQuestion(data, testId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/tests/question/create/' + testId,
      data,
      { headers }
      )
  }

  managementCreateQuestionAttestation(data, testId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/attestation-tests/question/create/' + testId,
      data,
      { headers }
    )
  }

  managementCreateOption(data, questionId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/tests/option/create/' + questionId,
      data,
      { headers }
      )
  }

  managementCreateOptionAttestation(data, questionId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/attestation-tests/option/create/' + questionId,
      data,
      { headers }
    )
  }

  managementCreateFilterQuestionAttestation(data, questionId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/attestation-tests/filter/create/' + questionId,
      data,
      { headers }
    )
  }

  managementCreateFolder(data) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/folder/create',
      data,
      { headers }
    );
  }

  managementCreateBanner(data) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/main/banner/create',
      data,
      { headers }
    );
  }

  managementCreateBannerImage(data, bannerId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/main/image/create/' + bannerId,
      data,
      { headers }
    );
  }

  managementCreateBannerFilter(data, bannerId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/main/filter/create/' + bannerId,
      data,
      { headers }
    );
  }

  managementUpdateDoc(section, data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    if (section === 'main' || section === 'integration' || section === 'library' || section === 'lftf' || section === 'important' || section === 'business') {
      return this.httpClient.post(
        this.root + 'mng/docs/course/' + id + '/update',
        data,
        { headers }
      );
    }
    if (section === 'attestation') {
      return this.httpClient.post(
        this.root + 'mng/docs/attestation/' + id + '/update',
        data,
        { headers }
      )
    }
    return this.httpClient.post(
      this.root + 'mng/docs/article/' + id + '/update',
      data,
      { headers }
    );
  }

  managementUpdateFilter(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/docs/filter/' + id + '/update',
      data,
      { headers }
    )
  }

  managementUpdateElement(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/docs/element/' + id + '/update',
      data,
      { headers }
    )
  }

  managementUpdateFileElement(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/docs/file/' + id + '/update',
      data,
      { headers }
    )
  }

  managementUpdateTest(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/tests/test/' + id + '/update',
      data,
      { headers }
    );
  }

  managementUpdateAttestationTest(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/attestation-tests/test/' + id + '/update',
      data,
      { headers }
    );
  }

  managementUpdateQuestion(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/tests/question/' + id + '/update',
      data,
      { headers }
    );
  }

  managementUpdateQuestionAttestation(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/attestation-tests/question/' + id + '/update',
      data,
      { headers }
    );
  }

  managementUpdateOption(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/tests/option/' + id + '/update',
      data,
      { headers }
    );
  }

  managementUpdateOptionAttestation(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/attestation-tests/option/' + id + '/update',
      data,
      { headers }
    );
  }

  managementUpdateFilterQuestionAttestation(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/attestation-tests/filter/' + id + '/update',
      data,
      { headers }
    );
  }

  managementUpdateFolder(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/docs/folder/' + id + '/update',
      data,
      { headers }
    );
  }

  managementUpdateBanner(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/main/banner/' + id + '/update',
      data,
      { headers }
    );
  }

  managementUpdateBannerImage(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/main/image/' + id + '/update',
      data,
      { headers }
    )
  }

  managementUpdateBannerFilter(data, id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/main/filter/' + id + '/update',
      data,
      { headers }
    )
  }

  managementDeleteFolder(folderId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/folder/' + folderId + '/delete',
      null,
      { headers },
    );
  }

  managementDeleteBanner(bannerId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/main/banner/' + bannerId + '/delete',
      null,
      { headers },
    );
  }

  managementDeleteDocs(type, docId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/' + type + '/' + docId + '/delete',
      null,
      { headers },
    );
  }

  managementDeleteFilter(filterId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/filter/' + filterId + '/delete',
      null,
      { headers }
    )
  }

  managementDeleteFilters(data: FilterGroupItem): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });

    return this.httpClient.post(
      this.root + 'mng/delete_filters/',
      data,
      { headers }
    )
  }

  managementDeleteBannerImage(imageId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/main/image/' + imageId + '/delete',
      null,
      { headers }
    )
  }

  managementDeleteBannerFilter(filterId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/main/filter/' + filterId + '/delete',
      null,
      { headers }
    )
  }

  managementDeleteElement(elementId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/element/' + elementId + '/delete',
      null,
      { headers }
    )
  }

  managementDeleteFileElement(fileId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/file/' + fileId + '/delete',
      null,
      { headers }
    )
  }

  managementDeleteTest(testId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/tests/test/' + testId + '/delete',
      null,
      { headers }
    );
  }

  managementDeleteAttestationTest(testId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/attestation-tests/test/' + testId + '/delete',
      null,
      { headers }
    );
  }

  managementDeleteQuestion(questionId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/tests/question/' + questionId + '/delete',
      null,
      { headers }
    )
  }

  managementDeleteQuestionAttestation(questionId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/attestation-tests/question/' + questionId + '/delete',
      null,
      { headers }
    )
  }

  managementDeleteOption(optionId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/tests/option/' + optionId + '/delete',
      null,
      { headers }
    )
  }

  managementDeleteOptionAttestation(optionId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/attestation-tests/option/' + optionId + '/delete',
      null,
      { headers }
    )
  }

  managementDeleteFilterQuestionAttestation(filterId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/attestation-tests/filter/' + filterId + '/delete',
      null,
      { headers }
    )
  }

  managementArchiveDocs(docId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/document/' + docId + '/archive',
      null,
      { headers }
    )
  }

  managementUnarchiveDocs(docId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/document/' + docId + '/unarchive',
      null,
      { headers }
    )
  }

  managementCopyDoc(type, docId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/' + type + '/' + docId + '/copy',
      null,
      { headers },
    );
  }

  managementGetXlsTemplate() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetXlsTemplateUrl,
      null,
      { headers },
    );
  }

  managementUsersImport (data) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementUsersImportUrl,
      data,
      { headers },
    );
  }

  managementUsersExport () {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementUsersExportUrl,
      null,
      { headers }
    );
  }

  managementGetAttestationQuestionTemplate() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetAttestationQuestionTemplateUrl,
      null,
      { headers },
    );
  }

  managementAttestationQuestionImport(testId, data) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementAttestationQuestionImportUrl + testId,
      data,
      { headers },
    );
  }

  managementGetTrash(reference: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetTrashUrl,
      { section__reference: reference },
      { headers }
    )
  }

  managementGetNotificationsList(search = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetNotificationsListUrl,
      { query: search },
      { headers },
    );
  }

  managementGetNotificationsListSend(search = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetNotificationsListSendUrl,
      { query: search },
      { headers },
    );
  }

  managementGetNotificationsTypes() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetNotificationsMailTypesUrl,
      null,
      { headers },
    );
  }

  managementNotificationCreate(data) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/notifications/draft/create',
      data,
      { headers },
    );
  }

  managementGetNotification(draftId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetNotificationsUrl + draftId,
      null,
      { headers },
    );
  }

  managementGetNotificationsFileTypes() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetNotificationsFileTypesUrl,
      null,
      { headers },
    );
  }

  managementGetNotificationsFileList(draftId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetNotificationsFileListUrl + draftId,
      null,
      { headers },
    );
  }

  managementGetNotificationsFileCreate(data, draftId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetNotificationsFileCreateUrl + draftId,
      data,
      { headers },
    );
  }

  managementDeleteNotificationFile(draftId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/notifications/file/' + draftId + '/delete',
      null,
      { headers },
    );
  }

  managementSendNotification(id) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken()
    });
    return this.httpClient.post(
      this.root + 'mng/notifications/draft/send/' + id,
      null,
      { headers }
    );
  }

  managementUpdateNotificationDraft(data, draftId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/notifications/draft/' + draftId + '/update',
      data,
      { headers },
    );
  }
  managementDeleteNotification(draftId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/notifications/draft/' + draftId + '/delete',
      null,
      { headers },
    );
  }

  managementGetNotificationTypesFilters() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetNotificationTypesFiltersUrl,
      null,
      { headers },
    );
  }

  managementGetNotificationListFilters(filterId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetNotificationFiltersListUrl + filterId,
      null,
      { headers },
    );
  }

  managementNotificationCreateFilter(data, filterId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementGetNotificationFilterCreateUrl + filterId,
      data,
      { headers },
    );
  }

  managementDeleteNotificationFilter(filterId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/notifications/filter/' + filterId + '/delete',
      null,
      { headers },
    );
  }




  managementCreateElementFilter(data, elementId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/element-filter/create/' + elementId,
      data,
      { headers },
    );
  }
  managementDeleteElementFilter(elementId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/element-filter/' + elementId + '/delete',
      null,
      { headers },
    );
  }
  managementUpdateElementFilter(data, elementId) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/element-filter/' + elementId + '/update',
      data,
      { headers },
    );
  }
  managementListElementFilter(elementId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/element-filter/list/' + elementId,
      null,
      { headers },
    );
  }
  managementGetTypesElementFilter() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/element-filter/types',
      null,
      { headers },
    );
  }

  mngDocsSearch(data): any {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/search',
      data,
      { headers },
    );
  }



  managementGetElementFilter(elementId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + 'mng/docs/element-filter/' + elementId,
      null,
      { headers },
    );
  }

  managementDocumentsSearch(search = '', section) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementDocumentSearchUrl,
      {query: search, section__reference: section},
      { headers },
    );
  }

  managementFeedbackList(search = '', theme?: number, department?: string, subtheme?: string, status?: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementFeedbackListUrl,
      { query: search, theme: theme, department: department, subtheme: subtheme, status: status },
      { headers },
    );
  }

  managementFeedbackGet(feedbackId: number) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementFeedbackGetUrl + feedbackId,
      null,
      { headers },
    );
  }

  managementFeedbackParams() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementFeedbackParamsUrl,
      null,
      { headers }
    );
  }

  managementFeedbackMessageGet(feedbackId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementFeedbackMessageGetUrl + feedbackId,
      null,
      { headers },
    );
  }

  managementFeedbackMessageFileGet(feedbackId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementFeedbackMessageFileGetUrl + feedbackId,
      null,
      { headers },
    );
  }

  managementFeedbackFileGet(feedbackId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementFeedbackFileGetUrl + feedbackId,
      null,
      { headers },
    );
  }

  managementFeedbackStatistics() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementFeedbackStatisticsUrl,
      null,
      { headers },
    );
  }

  managementFeedbackMessageCreate(feedbackId: number, text: string) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementFeedbackMessageCreateUrl + feedbackId,
      { text: text },
      { headers },
    );
  }

  managementFeedbackMessageFileCreate(messageId: number, files: File[]) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    const formData = new FormData()
    files.forEach((file) => formData.append('files', file))
    return this.httpClient.post(
      this.managementFeedbackMessageFileCreateUrl + messageId,
      formData,
      { headers },
    );
  }

}
