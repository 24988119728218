import { Directive, Input } from '@angular/core'

import { InputDialogTriggerDirective } from 'core/directives'
import { FileDialogComponent } from './file-dialog.component'

@Directive({
  selector: '[appFileDialogTriggerFor]'
})
export class FileDialogTriggerDirective extends InputDialogTriggerDirective {
  @Input('appFileDialogTriggerFor') override inputDialog!: FileDialogComponent
  @Input('appFileDialogTriggerDisabled') override disabled = false
}
