<app-header [noShowMenu]="true" [noGoMain]="true"></app-header>
<div class="outer">
    <div class="workspace doc test" *ngIf="testBody" [@show_preview]="showPrev">
        <div class="back_button" (click)="goBack()">
            <div class="back_icon"></div>
            <div class="back_title">{{ testBody.title }}</div>
        </div>

      <section class="content">

        <div *ngIf="!showRightAnswers">

          <form class="form" [formGroup]="form" (ngSubmit)="submit()" enctype="multipart/form-data">

            <div class="timer" *ngIf="initiateTimer">{{timeleft | msToTime}}</div>

            <div class="question_body" *ngFor="let question_body of testBody.questions; let i = index">

              <div class="question_info" [ngClass]="{error: errors[question_body.id]}">
                Вопрос №{{i + 1}}
              </div>

              <div class="question_info" translate="no" [innerHTML]="question_body.text | safeHtml">
              </div>

              <div class="greeting" [ngClass]="{error: errors[question_body.id]}">

                {{question_body.type === 'matching' ? 'Сопоставьте значения левой и правой части'
                  : question_body.type === 'input' ? 'Введите число'
                  : 'Варианты ответа:'}}
                <span *ngIf="question_body.type === 'checkbox'"> (выбери несколько вариантов ответа)</span>
                <span *ngIf="question_body.type === 'radio'"> (выбери один правильный ответ)</span>
                <span *ngIf="question_body.type === 'sort'"> (сортировка по приоритету)</span>
                <span *ngIf="question_body.type === 'rating'"> (поставьте оценку)</span>
                <span *ngIf="question_body.type === 'text'"> (открытый вопрос)</span>
              </div>

              <div class="question_options">

                <!--Rating-->
                <ng-container *ngIf="question_body.type === 'rating'">
                  <mat-slider class="custom-slider"
                              min="{{question_body.options[0].text}}"
                              max="{{question_body.options[question_body.options.length - 1].text}}"
                              step="1"
                              thumbLabel="true"
                              tickInterval="1"
                              formControlName="{{question_body.id}}"
                  ></mat-slider>
                </ng-container>

                <!--Radio-->
                <ng-container *ngIf="question_body.type === 'radio'">
                  <mat-radio-group class="options-group" formControlName="{{question_body.id}}">
                    <mat-radio-button
                      value="{{option.id}}"
                      translate="no"
                      class="options-button"
                      *ngFor="let option of question_body.options; let i = index"
                    >
                      <span [innerHTML]="option.text | safeHtml"></span>
                    </mat-radio-button>
                  </mat-radio-group>
                </ng-container>

                <!--Checkbox-->
                <ng-container *ngIf="question_body.type === 'checkbox'">
                  <div class="options-group" formArrayName="{{question_body.id}}">
                    <mat-checkbox
                      formControlName="{{i}}"
                      value="{{option.id}}"
                      translate="no"
                      class="options-button"
                      *ngFor="let option of question_body.options; let i = index"
                    >
                      <span [innerHTML]="option.text | safeHtml"></span>
                    </mat-checkbox>
                  </div>
                </ng-container>

                <!--Sort-->
                <ng-container *ngIf="question_body.type === 'sort'">
                  <div class="sort_container">
                    <div class="number_container">
                      <div class="number" *ngFor="let number of question_body.options; let i = index">{{i + 1}}</div>
                    </div>
                    <div cdkDropList class="options-group" style="width: 100%" (cdkDropListDropped)="drop($event, question_body.id, question_body.options)">
                      <div
                        translate="no"
                        cdkDrag
                        class="options-button options-DropList"
                        *ngFor="let option of question_body.options; let i = index"
                      >
                        <span class="text" [innerHTML]="option.text | safeHtml"></span>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <!--Matching-->
                <ng-container *ngIf="question_body.type === 'matching'">
                  <div class="matching_container">
                    <div class="options-group for_matching">
                      <div
                        translate="no"
                        class="options-button options-DropList half_left default_cursor"
                        *ngFor="let option of question_body.options | halfArray: 'left'; let i = index"
                      >
                        <span class="text" [innerHTML]="option.text | safeHtml"></span>
                      </div>
                    </div>
                    <div class="equals">
                      <div class="equal" *ngFor="let equal of question_body.options | halfArray: 'left';"></div>
                    </div>
                    <div cdkDropList class="options-group for_matching" *ngIf="matchingArrays.length"
                         (cdkDropListDropped)="matchingDrop(
                            $event,
                            question_body.id
                    )">
                      <div
                        cdkDrag
                        class="options-button options-DropList"
                        *ngFor="let option of matchingArrays | findArray: question_body.id; let i = index"
                      >
                        <span class="text" [innerHTML]="option.text | safeHtml"></span>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <!--text-->
                <div class="options-textarea" *ngIf="question_body.type === 'open'">
                      <textarea
                        type="text"
                        placeholder="Введите текст ответа"
                        formControlName="{{question_body.id}}"
                      ></textarea>
                </div>

                <ng-container *ngIf="question_body.type === 'input'">
                  <input
                    type="number"
                    pattern="\d*"
                    formControlName="{{question_body.id}}"
                  >
                </ng-container>

              </div>
            </div>

            <div class="test_submit_body">
              <button class="test_submit" type="submit">Отправить</button>
            </div>

          </form>

        </div>

        <div *ngIf="showRightAnswers">

          <div class="question_body" *ngFor="let question_body of rightAnswers; let i = index">

            <div class="question_info" [ngClass]="{correct: question_body.is_right, incorrect: !question_body.is_right}">
              Вопрос №{{i + 1}}
            </div>

            <div class="question_info" translate="no" [innerHTML]="question_body.text | safeHtml"></div>

            <div class="greeting">
              {{question_body.type === 'matching' ? 'Сопоставьте значения левой и правой части'
              : question_body.type === 'input' ? 'Введите число'
                : 'Варианты ответа:'}}
              <span *ngIf="question_body.type === 'checkbox'"> (выбери несколько вариантов ответа)</span>
              <span *ngIf="question_body.type === 'radio'"> (выбери один правильный ответ)</span>
              <span *ngIf="question_body.type === 'sort'"> (сортировка по приоритету)</span>
              <span *ngIf="question_body.type === 'rating'"> (поставьте оценку)</span>
            </div>

            <div class="question_options">

              <!--Rating-->
              <ng-container *ngIf="question_body.type === 'rating'">
                <mat-slider class="custom-slider"
                            min="{{question_body.options[0].text}}"
                            max="{{question_body.options[question_body.options.length - 1].text}}"
                            step="1"
                            thumbLabel="true"
                            tickInterval="1"
                            [disabled]="showRightAnswers"
                ></mat-slider>
              </ng-container>

              <!--Radio-->
              <ng-container *ngIf="question_body.type === 'radio'">
                <mat-radio-group class="options-group" [disabled]="showRightAnswers">
                  <mat-radio-button
                    value="{{option.id}}"
                    translate="no"
                    class="options-button"
                    [checked]="option.is_selected"
                    [ngClass]="{correct: more_detail && option.is_selected && option.is_right, incorrect: more_detail && option.is_selected && !option.is_right}"
                    *ngFor="let option of question_body.options; let i = index"
                  >
                    <span [innerHTML]="option.text | safeHtml"></span>
                  </mat-radio-button>
                </mat-radio-group>
              </ng-container>

              <!--Checkbox-->
              <ng-container *ngIf="question_body.type === 'checkbox'">
                <div class="options-group">
                  <mat-checkbox
                    [disabled]="showRightAnswers"
                    value="{{option.id}}"
                    translate="no"
                    class="options-button"
                    [checked]="option.is_selected"
                    [ngClass]="{correct: more_detail && option.is_selected && option.is_right, incorrect: more_detail && option.is_selected && !option.is_right}"
                    *ngFor="let option of question_body.options; let i = index"
                  >
                    <span [innerHTML]="option.text | safeHtml"></span>
                  </mat-checkbox>
                </div>
              </ng-container>

              <!--Sort-->
              <ng-container *ngIf="question_body.type === 'sort'">
                <div class="sort_container">
                  <div class="number_container">
                    <div class="number" *ngFor="let number of question_body.options; let i = index">{{i + 1}}</div>
                  </div>
                  <div cdkDropList class="options-group">
                    <div
                      translate="no"
                      cdkDrag
                      [cdkDragDisabled]="true"
                      class="options-button options-DropList"
                      [ngClass]="{correct: more_detail && option.is_selected && option.is_right, incorrect: more_detail && option.is_selected && !option.is_right}"
                      *ngFor="let option of question_body.options"
                    >
                      <span class="text disabled" [innerHTML]="option.text | safeHtml"></span>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!--Matching-->
              <ng-container *ngIf="question_body.type === 'matching'">
                <div class="matching_container">
                  <div class="options-group for_matching">
                    <div
                      translate="no"
                      class="options-button options-DropList half_left default_cursor"
                      *ngFor="let option of question_body.options | halfArray: 'left'; let i = index"
                    >
                      <span class="text" [innerHTML]="option.text | safeHtml"></span>
                    </div>
                  </div>
                  <div class="equals">
                    <div class="equal" *ngFor="let equal of question_body.options | halfArray: 'left';"></div>
                  </div>
                  <div class="options-group for_matching">
                    <div
                      translate="no"
                      class="options-button options-DropList default_cursor"
                      [ngClass]="{correct: more_detail && option.is_selected && option.is_right, incorrect: more_detail && option.is_selected && !option.is_right}"
                      *ngFor="let option of question_body.options | halfArray: 'right'; let i = index"
                    >
                      <span class="text" [innerHTML]="option.text | safeHtml"></span>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="options-textarea" *ngIf="question_body.type === 'open'">
                <textarea
                  type="text"
                  placeholder="Введите текст ответа"
                  [value]="question_body.open"
                  [ngStyle]="{cursor: 'default'}"
                  [readOnly]="showRightAnswers"
                ></textarea>
              </div>

              <ng-container *ngIf="question_body.type === 'input'">
                <div class="input" [ngClass]="{correct: more_detail && question_body.is_right, incorrect: more_detail && !question_body.is_right}">{{
                  more_detail
                    ? 'Верный ответ:' + question_body.options[0].text + ' Ваш ответ:' + question_body.input
                    : 'Ваш ответ:' + question_body.input}}</div>
              </ng-container>

            </div>


          </div>

        </div>

      </section>
    </div>
</div>
