import {Component, OnInit, OnDestroy, ViewChild, AfterViewChecked} from '@angular/core';
import { Router } from '@angular/router';

import { ExtraService } from 'services';
import { DialogService } from 'services';
import { StorageService } from 'services';
import { AuthService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { AlertDialogComponent } from 'components/dialogs';
@Component({
  selector: 'app-side-menu',
  templateUrl: 'side-menu.component.html',
  styleUrls: ['side-menu.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class SideMenuComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild( 'support' ) support;
  @ViewChild( 'menu' ) menu;

  showPrev = 'show';

  windowSize: number = 0;

  sections: any;
  nowUrl: any;
  refName: any;

  constructor(
    public extraService: ExtraService,
    private authService: AuthService,
    private dialogService: DialogService,
    private storageService: StorageService,
    private router: Router
  ) {
  }

  getSupport() {
    this.support.nativeElement.click();
  }

  logout() {
    const data = {
      'title': 'Вы уверены, что хотите выйти?',
      'confirmButtonText': 'ДА',
      'notButtonText': 'НЕТ'
    }
    this.dialogService.openDialog(AlertDialogComponent, data).subscribe((res: any) => {
      if (res) {
        this.authService.authLogOut();
        this.storageService.setValue('token', '');
        this.storageService.setValue('token-management', '');
        this.router.navigate(['auth']);
      } else {
        return;
      }
    });
  }

  goPage(info) {
    if (info.is_closed) {
      return;
    }
    switch (info.reference) {
      case this.nowUrl:
        return;
      case 'reports':
        this.router.navigate([info.reference + '-home/' + 'reports'])
        break
      case 'rating':
        this.router.navigate([info.reference + '/current'])
        break
      case 'management':
      case 'attestation':
      case 'feedback':
      case 'main':
        this.router.navigate([info.reference])
        break
      default:
        this.router.navigate([info.reference + '/' + null])
    }
  }

  goPersonal(link) {
    if (link === this.nowUrl) {
      return;
    } else {
      this.router.navigate([link]);
    }
  }

  ngAfterViewChecked() {
    if (this.extraService.openMenu) {
      if (this.menu) {
        this.menu.nativeElement.scrollTop = this.extraService.scrollMenuTop
      }
    }
  }

  ngOnInit() {
    this.windowSize = window.innerWidth;
    if (this.windowSize > 1024) {
      this.extraService.openMenu = true;
    } else {
      this.extraService.openMenu = false;
    }
    this.authService.getAuthPermissions().subscribe((response: any) => {
      this.sections = response.sections;
    });
    this.nowUrl = document.location.pathname.slice(1);
    this.refName = this.nowUrl.split('/')[0]
  }

  ngOnDestroy() {
    this.extraService.openMenu = false;
    this.extraService.showUserMobile = false;
  }
}
