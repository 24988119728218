import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { forkJoin, from } from 'rxjs';
import {FormGroup, Validators, FormControl, FormArray, FormBuilder} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {DialogService, ExtraService, ManagementService} from 'services';
import { ManagementApiService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AlertDialogComponent, ManagementElementsDialogComponent } from 'components/dialogs';
import { MatDialog } from '@angular/material/dialog';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import { catchError, filter, map, switchMap, takeUntil, takeWhile, tap, toArray } from 'rxjs/operators';
import { DestroyerService } from 'core/services';
import { FilterGroupItem, FilterItem } from 'app/typings/api';
import { LoadingDialogComponent } from 'components/dialogs/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-edit-management',
  templateUrl: 'edit-management.component.html',
  styleUrls: ['edit-management.component.scss'],
  providers: [DestroyerService],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class EditManagementComponent implements OnInit {

  @Input() noShowMenu = false;
  @ViewChild( 'fileInput' ) fileInput;
  file: any;
  inactiveButton = false;
  showPrev = 'show';

  section = undefined;
  id = undefined;
  editType = undefined;
  form: FormGroup = this.fb.group({});
  formElements: FormGroup = this.fb.group({});
  controls = undefined;
  types = undefined;
  elementsTypes = undefined;
  elements = undefined;
  filesTypes = undefined;
  filterTypes = undefined;
  filterQuestionTypes = undefined;
  filters: any[] = [];
  formFilter: FormGroup;
  params = undefined;
  inactiveFilter = false;
  search = '';

  //new code
  filterFormGroup: FormGroup = this.fb.group({
    filters: new FormArray([])
  });

  get filtersArrForm(): FormArray {
    return this.filterFormGroup.get('filters') as FormArray;
  }
  //

  constructor(
    public extraService: ExtraService,
    private managementApiService: ManagementApiService,
    private managementService: ManagementService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private matDialogService: MatDialog,
    private destroy$: DestroyerService
  ) {
  }

  //new code
  private initFilterControlList(filters: FilterItem[]): void {
    from(filters)
    .pipe(
      tap(() => {
        this.filtersArrForm.push(
          this.fb.control(false)
        );
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  private makeFilterGroup(data: number[]): FilterGroupItem {
    const initialData = {
      document_filters:[],
      element_filters:[],
      banner_filters:[]
  }

    switch (this.editType) {
      case 'edit-doc': {
        initialData.document_filters = [...data];
        break;
      }
      case 'edit-banner': {
        initialData.banner_filters = [...data];
        break;
      }
      default: {
        initialData.element_filters = [...data];
        break;
      }
    }

    return initialData
  }

  onDeleteSelectedFilters(): void {
    const filtersValue = this.filtersArrForm.value as boolean[];
    const isAnySelected: boolean = filtersValue.some(
      item => item
    );

    if(isAnySelected) {
      const modal = this.matDialogService.open(
        AlertDialogComponent,
        { 
          data: this.changeDialogData('del-filter-selected'),
          disableClose: true
        }
      ).afterClosed();

      modal.pipe(
        filter(res => !!res),
        tap(() => {
          this.matDialogService.open(
            LoadingDialogComponent,
            { disableClose: true }
          )
        }),
        switchMap(() => from(filtersValue).pipe(
          filter(item => item),
          map((_, index) => this.filters[index].id),
          toArray(),
        )),
        switchMap(filterIdList => {
          return this.managementApiService.managementDeleteFilters(
            this.makeFilterGroup(filterIdList)
          ).pipe(
            tap(() => {
              this.matDialogService.closeAll();

              this.filterFormGroup.reset({filters: []});
              this.filters = this.filters.filter(item => !filterIdList.includes(item.id));
              this.initFilterControlList(this.filters);
    
              this.dialogService.openDialog(
                AlertDialogComponent, 
                this.changeDialogData('filters-all')
              );
            }),
            catchError(err => {
              this.matDialogService.closeAll();
              
              this.dialogService.openDialog(
                AlertDialogComponent, 
                this.changeDialogData('error')
              );
              return err
            }),
          )
        }),
        takeUntil(this.destroy$)
      ).subscribe();
    } else {
      this.dialogService.openDialog(
        AlertDialogComponent, 
        this.changeDialogData('nothing')
      );
    }
  }

  onDeleteAllFilters(): void {
    const dialog = this.matDialogService.open(
      AlertDialogComponent,
      {
         data: this.changeDialogData('del-filter-all'),
         disableClose: true
      }
    ).afterClosed();

    dialog.pipe(
      filter(res => !!res),
      tap(() => {
        this.matDialogService.open(
          LoadingDialogComponent,
          { disableClose: true }
        )
      }),
      switchMap(() => from(this.filters).pipe(
        map((item) => item.id),
        toArray(),
      )),
      switchMap(filterIdList => {
        return this.managementApiService.managementDeleteFilters(
          this.makeFilterGroup(filterIdList)
        ).pipe(
          tap(() => {
            this.matDialogService.closeAll();

            this.filterFormGroup.reset({filters: []});
            this.filters = this.filters.filter(item => !filterIdList.includes(item.id));
            this.initFilterControlList(this.filters);
  
            this.dialogService.openDialog(
              AlertDialogComponent, 
              this.changeDialogData('filters-all')
            );
          }),
          catchError(err => {
            this.matDialogService.closeAll();
            
            this.dialogService.openDialog(
              AlertDialogComponent, 
              this.changeDialogData('error')
            );
            return err
          }),
        )
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }
  //

  goBack() {
    this.router.navigate(['section-management/' + this.section]);
  }

  downloadQuestionTemplate() {
    let button = document.getElementById('buttonTemplate')
    let preloader = document.getElementById('preloaderTemplate')
    if (!this.inactiveButton) {
      this.inactiveButton = true
      button.style.display = 'none'
      preloader.style.display = 'flex'
      this.managementApiService.managementGetAttestationQuestionTemplate().subscribe((response: any) => {
        location.assign(response.url)
        this.inactiveButton = false
        button.style.display = 'flex'
        preloader.style.display = 'none'
      }, () => {
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
        this.inactiveButton = false
        button.style.display = 'flex'
        preloader.style.display = 'none'
      })
    }
  }

  processWebFile(event) {
    if (event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.file = event.target.files[0];
      return true;
    }
    return this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error-file-format'));
  }

  getFile() {
    this.fileInput.nativeElement.click();
  }

  sendFile() {
    let button = document.getElementById('buttonImport')
    let preloader = document.getElementById('preloaderImport')
    if (!this.inactiveButton) {
      this.inactiveButton = true;
      button.style.display = 'none'
      preloader.style.display = 'flex'
      const formData: FormData = new FormData();
      formData.append( 'file', this.file );
      this.managementApiService.managementAttestationQuestionImport(this.id, formData).subscribe(() => {
        this.inactiveButton = false;
        button.style.display = 'flex'
        preloader.style.display = 'none'
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-questions-import'));
        this.managementApiService.managementGetQuestionsAttestation(this.id).subscribe((response: any) => {
          this.elements = response
        })
      }, (err) => {
        this.inactiveButton = false;
        button.style.display = 'flex'
        preloader.style.display = 'none'
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error-import-file', err.error.error));
      })
    }
  }

  addElement() {
    this.formElements = this.fb.group(this.managementService.makeFormGroupElements(this.elementsTypes));
    const data = {
      formElements: this.formElements,
      docId: this.id,
      controlsElement: this.elementsTypes,
      section: this.section,
      arrFiles: [],
      arrFilters: []
    }
    switch (this.editType) {
      case 'edit-doc':
        data['type'] = 'add-elements'
        data['controlsFile'] = this.filesTypes
        data['formFiles'] = this.managementService.makeFormGroupElements(this.filesTypes)
        data['formFilters'] = this.managementService.makeFormGroupElements(this.filterQuestionTypes)
        data['controlsFilter'] = this.filterQuestionTypes
        this.dialogService.openDialog(ManagementElementsDialogComponent, data).subscribe((res: any) => {
          if (res) {
            this.elements = res.elements;
          }
        })
        break
      case 'edit-test':
        data['type'] = 'add-question'
        data['formFiles'] = undefined
        this.managementApiService.managementGetTypesOption(this.formElements.get('type').value).subscribe((response: any) => {
          this.filesTypes = response
          data['formFiles'] = this.managementService.makeFormGroupElements(this.filesTypes)
          data['controlsFile'] = this.filesTypes
          this.dialogService.openDialog(ManagementElementsDialogComponent, data).subscribe((res: any) => {
            if (res) {
              this.elements = res.elements;
            }
          })
        })
        break
      case 'edit-attestation-test':
        data['type'] = 'add-question-attestation'
        data['formFiles'] = undefined
        data['formFilters'] = this.managementService.makeFormGroupElements(this.filterQuestionTypes)
        data['controlsFilter'] = this.filterQuestionTypes
        this.managementApiService.managementGetTypesOptionAttestation(this.formElements.get('type').value).subscribe((response: any) => {
          this.filesTypes = response
          data['formFiles'] = this.managementService.makeFormGroupElements(this.filesTypes)
          data['controlsFile'] = this.filesTypes
          this.dialogService.openDialog(ManagementElementsDialogComponent, data).subscribe((res: any) => {
            if (res) {
              this.elements = res.elements;
            }
          })
        })
        break
      case 'edit-notification':
        data['type'] = 'add-file-notification'
        delete data['arrFilters']
        delete data['formElements']
        delete data['controlsElement']
        data['controlsFile'] = this.elementsTypes
        data['formFiles'] = this.managementService.makeFormGroupElements(this.elementsTypes)
        this.dialogService.openDialog(ManagementElementsDialogComponent, data).subscribe((res: any) => {
          if (res) {
            this.elements = res.elements;
          }
        })
        break
    }
  }

  addBannerImage() {
    if (this.elements.length === 3) {
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('many-file-error')).subscribe((res: any) => {
        this.matDialogService.closeAll();
      });
      return;
    }
    this.formElements = this.fb.group(this.managementService.makeFormGroupElements(this.elementsTypes));
    const data = {
      formElements: this.formElements,
      docId: this.id,
      controlsElement: this.elementsTypes,
      section: this.section,
      type: 'add-banner-image'
    }
    this.dialogService.openDialog(ManagementElementsDialogComponent, data).subscribe((res: any) => {
      if (res) {
        this.elements = res.elements;
      }
    })
  }

  addFilter() {
    this.formElements = this.fb.group(this.managementService.makeFormGroupElements(this.filterTypes));
    const data = {
      formElements: this.formElements,
      docId: this.id,
      controlsElement: this.filterTypes,
      section: this.section,
    }
    if (this.editType === 'edit-doc') {
      data['type'] = 'add-filter'
    } else if (this.editType === 'edit-banner') {
      data['type'] = 'add-banner-filter'
    } else if (this.editType === 'edit-notification') {
      data['type'] = 'add-notification-filter'
    }
    this.dialogService.openDialog(ManagementElementsDialogComponent, data).subscribe((res: any) => {
      if (res) {
        this.filterFormGroup.reset({filters: []});
        this.initFilterControlList(res.filters);
        this.filters = res.filters
      }
    })
  }

  editBannerImage(image) {
    this.managementApiService.managementGetBannerImage(image.id).subscribe((response: any) => {
      this.formElements = this.fb.group(this.managementService.makeFormGroupElements(this.elementsTypes, response));
      const data = {
        formElements: this.formElements,
        docId: this.id,
        elementId: image.id,
        controlsElement: this.elementsTypes,
        section: this.section,
        type: 'edit-banner-image'
      }
      this.dialogService.openDialog(ManagementElementsDialogComponent, data).subscribe((res: any) => {
        if (res) {
          this.elements = res.elements;
        }
      })
    })
  }

  deleteFilter(filterID: number) {
    this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('del-filter')).subscribe((res: any) => {
      if (res) {
        if (this.editType === 'edit-doc') {
          this.managementApiService.managementDeleteFilter(filterID)
          .pipe(
            takeUntil(this.destroy$)
          ).subscribe(() => {
            this.matDialogService.open(AlertDialogComponent, {
              data: this.changeDialogData('suc-del-filter')
            });
            this.managementApiService.managementGetFilters(this.id)
            .pipe(
              takeUntil(this.destroy$)
            )
            .subscribe((response: any) => {
              this.filterFormGroup.reset({filters: []});
              this.initFilterControlList(response);
              this.filters = response
            })
          }, () => {
            this.matDialogService.open(AlertDialogComponent, {
              data: this.changeDialogData('error')
            });
          });
        } else if (this.editType === 'edit-banner') {
          this.managementApiService.managementDeleteBannerFilter(filterID)
          .pipe(
            takeUntil(this.destroy$)
          )
          .subscribe(() => {
            this.matDialogService.open(AlertDialogComponent, {
              data: this.changeDialogData('suc-del-filter')
            });
            this.managementApiService.managementGetBannerFilters(this.id)
            .pipe(
              takeUntil(this.destroy$)
            )
            .subscribe((response: any) => {
              this.filterFormGroup.reset({filters: []});
              this.initFilterControlList(response);
              this.filters = response
            })
          }, () => {
            this.matDialogService.open(AlertDialogComponent, {
              data: this.changeDialogData('error')
            });
          });
        } else if (this.editType === 'edit-notification') {
          this.managementApiService.managementDeleteNotificationFilter(filterID)
          .pipe(
            takeUntil(this.destroy$)
          )
          .subscribe(() => {
            this.matDialogService.open(AlertDialogComponent, {
              data: this.changeDialogData('suc-del-filter')
            });
            this.managementApiService.managementGetNotificationListFilters(this.id).subscribe((response: any) => {
              this.filterFormGroup.reset({filters: []});
              this.initFilterControlList(response);
              this.filters = response
            })
          }, () => {
            this.matDialogService.open(AlertDialogComponent, {
              data: this.changeDialogData('error')
            });
          });
        }
      }
    })
  }

  drop(event: CdkDragDrop<string[]>, arr: any) {
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
    const formData = new FormData()
    formData.append('document_id', this.id)
    formData.append('order', String(event.currentIndex + 1))
    switch (this.editType) {
      case 'edit-doc':
        this.managementApiService.managementUpdateElement(formData, arr[event.currentIndex].id).subscribe()
        break;
      case 'edit-test':
        this.managementApiService.managementUpdateQuestion(formData, arr[event.currentIndex].id).subscribe()
        break;
      case 'edit-attestation-test':
        this.managementApiService.managementUpdateQuestionAttestation(formData, arr[event.currentIndex].id).subscribe()
        break;
    }
  }

  editElement(element) {
    this.formElements = this.fb.group(this.managementService.makeFormGroupElements(this.elementsTypes, element));
    const arrFiles = [];
    const arrFilters = [];
    const data = {
      formElements: this.formElements,
      docId: this.id,
      elementId: element.id,
      controlsElement: this.elementsTypes,
      section: this.section,
    }
    switch (this.editType) {
      case 'edit-doc':
        data['type'] = 'edit-elements'
        data['controlsFile'] = this.filesTypes
        data['formFiles'] = this.managementService.makeFormGroupElements(this.filesTypes)
        data['formFilters'] = this.managementService.makeFormGroupElements(this.filterQuestionTypes)
        data['controlsFilter'] = this.filterQuestionTypes
        forkJoin([
          this.managementApiService.managementGetFiles(element.id),
          this.managementApiService.managementListElementFilter(element.id)
        ]).subscribe((response: any) => {
          response[0].forEach((option) => {
            arrFiles.push({
              form: this.fb.group(this.managementService.makeFormGroupElements(this.filesTypes, option)),
              id: option.id,
              type: 'update'
            });
          })
          response[1].forEach((filter) => {
            arrFilters.push({
              title: filter.title,
              id: filter.id,
            });
          })
          data['arrFiles'] = arrFiles
          data['arrFilters'] = arrFilters
          this.dialogService.openDialog(ManagementElementsDialogComponent, data).subscribe((res: any) => {
            if (res) {
              this.elements = res.elements;
            }
          })
        })
        break
      case 'edit-test':
        data['type'] = 'edit-question'
        data['formFiles'] = undefined
        this.managementApiService.managementGetTypesOption(this.formElements.get('type').value).subscribe((response: any) => {
          this.filesTypes = response
          data['controlsFile'] = this.filesTypes
          data['formFiles'] = this.managementService.makeFormGroupElements(this.filesTypes)
          this.managementApiService.managementGetOption(element.id).subscribe((response: any) => {
            response.forEach((option) => {
              arrFiles.push({
                form: this.fb.group(this.managementService.makeFormGroupElements(this.filesTypes, option)),
                id: option.id,
                type: 'update'
              });
            })
            data['arrFiles'] = arrFiles
            this.dialogService.openDialog(ManagementElementsDialogComponent, data).subscribe((res: any) => {
              if (res) {
                this.elements = res.elements;
              }
            })
          })
        })
        break
      case 'edit-attestation-test':
        data['type'] = 'edit-question-attestation'
        data['formFiles'] = undefined
        this.managementApiService.managementGetTypesOptionAttestation(this.formElements.get('type').value).subscribe((response: any) => {
          this.filesTypes = response
          data['controlsFile'] = this.filesTypes
          data['formFiles'] = this.managementService.makeFormGroupElements(this.filesTypes)
          data['formFilters'] = this.managementService.makeFormGroupElements(this.filterQuestionTypes)
          data['controlsFilter'] = this.filterQuestionTypes
          forkJoin([
            this.managementApiService.managementGetOptionAttestation(element.id),
            this.managementApiService.managementGetFiltersQuestionAttestation(element.id)
          ]).subscribe((response: any) => {
            response[0].forEach((option) => {
              arrFiles.push({
                form: this.fb.group(this.managementService.makeFormGroupElements(this.filesTypes, option)),
                id: option.id,
                type: 'update'
              });
            })
            response[1].forEach((filter) => {
              arrFilters.push({
                title: filter.title,
                id: filter.id,
              });
            })
            data['arrFiles'] = arrFiles
            data['arrFilters'] = arrFilters
            this.dialogService.openDialog(ManagementElementsDialogComponent, data).subscribe((res: any) => {
              if (res) {
                this.elements = res.elements;
              }
            })
          })
        })
        break
      case 'edit-notification':
        data['type'] = 'edit-elements'
        data['controlsFile'] = this.filesTypes
        data['formFiles'] = this.managementService.makeFormGroupElements(this.filesTypes)
        this.managementApiService.managementGetNotificationsFileList(element.id).subscribe((response: any) => {
          response.forEach((file) => {
            arrFiles.push({
              form: this.fb.group(this.managementService.makeFormGroupElements(this.filesTypes, file)),
              id: file.id,
              type: 'update'
            });
          })
          data['arrFiles'] = arrFiles
          this.dialogService.openDialog(ManagementElementsDialogComponent, data).subscribe((res: any) => {
            if (res) {
              this.elements = res.elements;
            }
          })
        })
        break
    }
  }

  filterForm() {
    this.inactiveFilter = true;
    this.managementApiService.managementGetQuestionsAttestation(this.id, this.formFilter.value).subscribe((response: any) => {
      this.elements = response;
      this.inactiveFilter = false;
    })
  }

  changeDialogData(type = this.editType, text?) {
    let data = {};
    switch (type) {
      case 'nothing':
        data = {
          title: 'Ничего не выбрано',
          confirmButtonText: 'OK'
        }
        break
      case 'edit-doc':
        data = {
          title: 'Вы действительно хотите удалить элемент?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'edit-test':
      case 'edit-attestation-test':
        data = {
          title: 'Вы действительно хотите удалить вопрос?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'edit-banner':
        data = {
          title: 'Вы действительно хотите удалить изображение баннера?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'edit-notification':
        data = {
          title: 'Вы действительно хотите удалить файл?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'del-filter':
        data = {
          title: 'Вы действительно хотите удалить фильтр?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'del-filter-all':
        data = {
          title: 'Вы действительно хотите удалить все фильтры?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'del-filter-selected':
        data = {
          title: 'Вы действительно хотите удалить выбранные фильтры?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'delete-arr-question':
        data = {
          title: 'Вы действительно хотите удалить вопросы?',
          confirmButtonText: 'ДА',
          notButtonText: 'НЕТ'
        }
        break
      case 'suc-del-element':
        data = {
          title: 'Элемент успешно удален',
          confirmButtonText: 'OK',
        }
        break
      case 'filters-all':
        data = {
          title: 'Элементы успешно удалены',
          confirmButtonText: 'OK',
        }
        break
      case 'suc-del-question':
        data = {
          title: 'Вопрос успешно удален',
          confirmButtonText: 'OK',
        }
        break
      case 'suc-del-image':
        data = {
          title: 'Изображение баннера успешно удалено',
          confirmButtonText: 'OK',
        }
        break
      case  'suc-del-filter':
        data = {
          title: 'Фильтр успешно удален',
          confirmButtonText: 'OK',
        }
        break
      case 'suc-del-arr-question':
        data = {
          title: 'Вопросы успешно удалены',
          confirmButtonText: 'OK',
        }
        break
      case 'error':
        data = {
          title: 'Произошла ошибка',
          confirmButtonText: 'OK',
        }
        break
      case 'many-file-error':
        data = {
          title: 'Нельзя добавить больше трёх форматов',
          confirmButtonText: 'ОК',
        }
        break
      case 'error-file-format':
        data = {
          title: 'Только xls или xlsx!',
          confirmButtonText: 'OK'
        }
        break
      case 'suc-questions-import':
        data = {
          title: 'Вопросы успешно импортированны',
          confirmButtonText: 'OK'
        }
        break
      case 'error-import-file':
        data = {
          title: 'Произошла ошибка',
          text: text,
          confirmButtonText: 'OK'
        }
        break
      case 'send-mail':
        data = {
          title: 'Письмо успешно отправлено',
          confirmButtonText: 'OK'
        }
        break
    }
    return data;
  }

  deleteElement(event, element) {
    event.stopPropagation();
    switch (this.editType) {
      case 'edit-doc':
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData()).subscribe((res: any) => {
          if (res) {
            this.managementApiService.managementDeleteElement(element.id).subscribe(() => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-del-element'));
              this.managementApiService.managementGetElements(this.id).subscribe((response: any) => {
                this.elements = response;
              })
            }, () => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            })
          }
        })
        break
      case 'edit-test':
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData()).subscribe((res: any) => {
          if (res) {
            this.managementApiService.managementDeleteQuestion(element.id).subscribe(() => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-del-question'));
              this.managementApiService.managementGetQuestions(this.id).subscribe((response: any) => {
                this.elements = response;
              })
            }, () => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            })
          }
        })
        break
      case 'edit-attestation-test':
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData()).subscribe((res: any) => {
          if (res) {
            this.managementApiService.managementDeleteQuestionAttestation(element.id).subscribe(() => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-del-question'));
              this.managementApiService.managementGetQuestionsAttestation(this.id).subscribe((response: any) => {
                this.elements = response;
              })
            }, () => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            })
          }
        })
        break
      case 'edit-banner':
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData()).subscribe((res: any) => {
          if (res) {
            this.managementApiService.managementDeleteBannerImage(element.id).subscribe(() => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-del-image'));
              this.managementApiService.managementGetBannerImages(this.id).subscribe((response: any) => {
                this.elements = response;
              })
            }, () => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            })
          }
        })
        break
      case 'edit-notification':
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData()).subscribe((res: any) => {
          if (res) {
            this.managementApiService.managementDeleteNotificationFile(element.id).subscribe(() => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-del-element'));
              this.managementApiService.managementGetNotificationsFileList(this.id).subscribe((response: any) => {
                this.elements = response;
              })
            }, () => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
            })
          }
        })
        break
    }
  }

  handleElementClick(element) {
    element.is_checked = !element.is_checked;
  }

  deleteElementArr(elements) {
    switch (this.editType) {
      case 'edit-attestation-test':
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('delete-arr-question')).subscribe((res: any) => {
          if (res) {
            elements.forEach((item: any) => {
              if (item.is_checked) {
                this.managementApiService.managementDeleteQuestionAttestation(item.id).subscribe()
              }
            })
            this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-del-arr-question'));
            this.managementApiService.managementGetQuestionsAttestation(this.id).subscribe((response: any) => {
              this.elements = response;
            })
          }
        })
        break
    }
  }

  makeFormGroup(types, controls) {
    const group = {};

    Object.keys(types).forEach((key: any) => {
      if (types[key].input.required) {
        group[types[key].input.name] = [controls[key], Validators.required]
      } else {
        group[types[key].input.name] = controls[key]
      }
    })
    this.form = this.fb.group(group);
  }

  sendMail() {
    this.managementApiService.managementSendNotification(this.id).subscribe(() => {
      this.router.navigate(['section-management/' + this.section]);
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('send-mail'));
    }, () => {
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
    });
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.section = params.section;
      this.id = params['id'];
      this.editType = params['edit-type'];
      switch (this.editType) {
        case 'edit-folder':
          forkJoin([
            this.managementApiService.managementGetTypesFolder(this.section),
            this.managementApiService.managementGetFolder(this.id)
          ]).subscribe((response: any) => {
            this.types = response[0];
            this.controls = response[1];
            this.makeFormGroup(this.types, this.controls);
          });
          break
        case 'edit-banner':
          forkJoin([
            this.managementApiService.managementGetTypesBanner(this.section),
            this.managementApiService.managementGetBanner(this.id),
            this.managementApiService.managementGetTypesImage(this.section),
            this.managementApiService.managementGetBannerImages(this.id),
            this.managementApiService.managementGetTypesBannerFilters(this.section),
            this.managementApiService.managementGetBannerFilters(this.id),
          ]).subscribe((response: any) => {
            this.types = response[0];
            this.controls = response[1];
            this.elementsTypes = response[2];
            this.elements = response[3];
            this.filterTypes = response[4];
            this.filters = response[5];
            this.makeFormGroup(this.types, this.controls);
          });
          break
        case 'edit-doc':
          forkJoin([
            this.managementApiService.managementGetTypesDoc(this.section),
            this.managementApiService.managementGetDoc(this.section, this.id),
            this.managementApiService.managementGetTypesElements(this.section),
            this.managementApiService.managementGetTypesFile(this.section),
            this.managementApiService.managementGetElements(this.id),
            this.managementApiService.managementGetTypesFilters(this.section),
            this.managementApiService.managementGetFilters(this.id),
            this.managementApiService.managementGetTypesElementFilter(),
          ]).subscribe((response: any) => {
            this.types = response[0];
            this.controls = response[1];
            this.elementsTypes = response[2];
            this.filesTypes = response[3];
            this.elements = response[4];
            this.filterTypes = response[5];
            this.filters = response[6];
            this.filterQuestionTypes = response[7];
            this.makeFormGroup(this.types, this.controls);

            this.initFilterControlList(response[6]);
          });
          break
        case 'edit-test':
          forkJoin([
            this.managementApiService.managementGetTypesTest(),
            this.managementApiService.managementGetTest(this.id),
            this.managementApiService.managementGetTypesQuestions(),
            this.managementApiService.managementGetQuestions(this.id)
          ]).subscribe((response: any) => {
            this.types = response[0];
            this.controls = response[1];
            this.elementsTypes = response[2];
            this.elements = response[3];
            this.makeFormGroup(this.types, this.controls);
          });
          break
        case 'edit-attestation-test':
          forkJoin([
            this.managementApiService.managementGetTypesAttestationTest(),
            this.managementApiService.managementGetAttestationTest(this.id),
            this.managementApiService.managementGetTypesQuestionsAttestation(),
            this.managementApiService.managementGetQuestionsAttestation(this.id),
            this.managementApiService.managementGetTypesFilterQuestionAttestation(),
            this.managementApiService.managementGetParamsQuestionAttestation()
          ]).subscribe((response: any) => {
            this.types = response[0];
            this.controls = response[1];
            this.elementsTypes = response[2];
            this.elements = response[3];
            this.filterQuestionTypes = response[4];
            this.params = response[5];
            this.formFilter = new FormGroup({
              'theme': new FormControl(null),
              'microtheme': new FormControl(null)
            })
            this.makeFormGroup(this.types, this.controls);
          });
          break
        case 'edit-notification':
          forkJoin([
            this.managementApiService.managementGetNotificationsTypes(),
            this.managementApiService.managementGetNotification(this.id),
            this.managementApiService.managementGetNotificationsFileTypes(),
            this.managementApiService.managementGetNotificationsFileList(this.id),
            this.managementApiService.managementGetNotificationTypesFilters(),
            this.managementApiService.managementGetNotificationListFilters(this.id),
          ]).subscribe((response: any) => {
            this.types = response[0];
            this.controls = response[1];
            this.elementsTypes = response[2];
            this.elements = response[3];
            this.filterTypes = response[4];
            this.filters = response[5];
            this.makeFormGroup(this.types, this.controls);
          });
          break
      }
    });
  }
}
