<div class="profile" *ngIf="extraService.profileInfo" [@show_preview]="showPrev">
  <div class="profile_img_container">
    <div class="profile_img" [ngStyle]="{'backgroundImage': 'url(' + (extraService.profileInfo.photo !== null ? (extraService.profileInfo.photo) : '/assets/images/icons/avatar_none.png') + ')'}"></div>
    <div class="profile_button">
      <button (click)="getPicture()">Сменить фото</button>
    </div>
    <div class="no_show">
      <input type="file" #photoInput name="files[]" (change)="processWebImage($event)" />
    </div>
  </div>
  <div class="profile_info">
    <div class="name">{{extraService.profileInfo.name}} {{extraService.profileInfo.surname}}</div>

    <div class="profile_table">
      <div class="profile_table_row">
        <div class="profile_table_td">
          ЛОГИН, E-MAIL:
        </div>
        <div class="profile_table_td">
          {{extraService.profileInfo.email}}
        </div>
      </div>

      <div class="profile_table_row">
        <div class="profile_table_td">
          ТЕЛЕФОН:
        </div>
        <div class="profile_table_td">
         {{extraService.profileInfo.phone | mask: '+0 (000) 000-00-00'}}
        </div>
      </div>

      <div class="profile_table_row">
        <div class="profile_table_td">
          Город:
        </div>
        <div class="profile_table_td" *ngIf="extraService.profileInfo.city">
          {{extraService.profileInfo.city.title}}
        </div>
      </div>

      <div class="profile_table_row">
        <div class="profile_table_td">
          Регион:
        </div>
        <div class="profile_table_td" *ngIf="extraService.profileInfo.regions">
          <span *ngFor="let region of extraService.profileInfo.regions">{{region.title}}, </span>
        </div>
      </div>

      <div class="profile_table_row">
        <div class="profile_table_td">
          Мегарегион:
        </div>
        <div class="profile_table_td" *ngIf="extraService.profileInfo.district">
          {{extraService.profileInfo.district}}
        </div>
      </div>

      <div class="profile_table_row">
        <div class="profile_table_td">
          Должность:
        </div>
        <div class="profile_table_td" *ngIf="extraService.profileInfo.position">
          {{extraService.profileInfo.position.title}}
        </div>
      </div>

    </div>
  </div>



</div>

<div *ngIf="extraService.profileInfo">
  <div class="profile_button" *ngIf="extraService.profileInfo.is_inside">
    <button (click)="changePassword()">Сменить пароль</button>
  </div>
</div>

