<app-header></app-header>
<div class="outer">
  <div class="workspace"
       #workspace
       scrollButtonListener
       [ngClass]="{'mobile_menu': extraService.showUserMobile}"
  >
    <app-side-menu></app-side-menu>
    <section class="content" [@show_preview]="showPrev" [ngClass]="{'open_mobile_menu': extraService.showUserMobile}"
             infiniteScroll
             [infiniteScrollDistance]="2"
             [infiniteScrollThrottle]="50"
             [infiniteScrollDisabled]="endScroll"
             (scrolled)="paginationOn()">

      <div class="back_button" *ngIf="checkBack" (click)="goBack()">
        <div class="back_icon"></div>
        <div class="back_title">Назад</div>
      </div>

      <form class="form_search" [formGroup]="formSearch" (ngSubmit)="searchForm()" enctype="multipart/form-data">
        <input placeholder="Поиск" formControlName="query">
        <button class="search_button" type="submit" [disabled]="inactiveSearch"></button>
      </form>

      <div class="document_list">
        <div class="document" *ngFor="let folder of folders" (click)="goFolder(folder.id)">
          <div class="document_image" [ngStyle]="{backgroundImage: 'url(' + (folder.preview !== '' ? (folder.preview) : '/assets/images/icons/folder.png') + ')'}"></div>
          <div class="document_title">
            {{folder.title}}
          </div>
        </div>

        <div class="document" *ngFor="let document of documents" (click)="goDocument(document)">
          <div class="new_doc" *ngIf="!document.is_view">NEW</div>
          <div class="document_image" [ngStyle]="{backgroundImage: 'url(' + (document.preview !== '' ? (document.preview) : '') + ')'}"></div>
          <div class="document_title">
            {{document.title}}
          </div>
        </div>
      </div>


    </section>

    <app-scroll-to-top [workspace]="workspace"></app-scroll-to-top>

  </div>

</div>
