import { Component, Inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BaseService, ManagementApiService } from 'services/api'
import { AlertDialogComponent } from 'components/dialogs'
import { DialogService } from 'services'

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})

export class FeedbackDialogComponent {

  @ViewChild( 'photoInput' ) photoInput;

  formFeedback: FormGroup;

  subtheme = undefined;
  department = undefined;
  theme = undefined;
  files: File[] = [];
  showForm = true;
  feedbackId: number;
  title: string;

  managementAnswer = false;

  defaultCountry = undefined

  inactiveSubmit = false

  constructor(
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private baseService: BaseService,
    private dialogService: DialogService,
    private managementService: ManagementApiService,
    @Inject(MAT_DIALOG_DATA) private dialogData: { 'title': string, 'feedbackId': number, showForm: boolean, managementAnswer: boolean },
  ) {
    ({
      title: this.title,
      feedbackId: this.feedbackId,
      showForm: this.showForm,
      managementAnswer: this.managementAnswer
    } = this.dialogData);
  }

  sendFeedback(themeId, subthemeId, title, text) {
    let button = document.getElementById('buttonTemplate')
    let preloader = document.getElementById('preloaderTemplate')
    if (!this.inactiveSubmit) {
      this.inactiveSubmit = true
      button.style.display = 'none'
      preloader.style.display = 'flex'
      if(themeId && subthemeId) {
        this.baseService.feedbackCreate(themeId, subthemeId, title, text, this.files).subscribe( (response: any) => {
          const data = {
            title: 'Запрос отправлен',
            confirmButtonText: 'OK'
          }
          this.dialogService.openDialog(AlertDialogComponent, data);
          this.dialogRef.close(true);
          this.inactiveSubmit = false
          button.style.display = 'flex'
          preloader.style.display = 'none'
        }, () => {
          const data = {
            title: 'Произошла ошибка',
            confirmButtonText: 'OK'
          }
          this.dialogService.openDialog(AlertDialogComponent, data);
          this.inactiveSubmit = false;
          button.style.display = 'flex'
          preloader.style.display = 'none'
        })
      } else {
        const data = {
          title: 'Поля Тема и Подтема должны быть заполнены.',
          confirmButtonText: 'OK'
        }
        this.dialogService.openDialog(AlertDialogComponent, data);
        this.inactiveSubmit = false
        button.style.display = 'flex'
        preloader.style.display = 'none'
      }

    }

  }

  sendAnswerFeedback(text) {
    if (!this.managementAnswer) {
      this.baseService.feedbackMessageCreate(this.feedbackId, text, this.files).subscribe( (response: any) => {
        const data = {
          title: 'Ответ отправлен',
          confirmButtonText: 'OK'
        }
        this.dialogService.openDialog(AlertDialogComponent, data);
        this.dialogRef.close();
      }, () => {
        const data = {
          title: 'Произошла ошибка',
          confirmButtonText: 'OK'
        }
        this.dialogService.openDialog(AlertDialogComponent, data);
      })
    } else {
      this.managementService.managementFeedbackMessageCreate(this.feedbackId, text).subscribe( (response: any) => {
        this.managementService.managementFeedbackMessageFileCreate(response.id, this.files).subscribe( (response: any) => {
          const data = {
            title: 'Ответ отправлен',
            confirmButtonText: 'OK'
          }
          this.dialogService.openDialog(AlertDialogComponent, data);
          this.dialogRef.close();
        }, () => {
          const data = {
            title: 'Произошла ошибка с добавлением файла',
            confirmButtonText: 'OK'
          }
          this.dialogService.openDialog(AlertDialogComponent, data);
        })
      }, () => {
        const data = {
          title: 'Произошла ошибка',
          confirmButtonText: 'OK'
        }
        this.dialogService.openDialog(AlertDialogComponent, data);
      })
    }
  }

  addFiles(files: File[]) {
    this.files = files
  }

  deleteFile(index: number) {
    this.files.splice(index, 1)
  }

  paramsFilters() {
    this.baseService.feedbackParams(this.formFeedback.value.department, this.formFeedback.value.theme).subscribe( (response: any) => {
      this.department = response.department;
      this.theme = response.theme;
      this.subtheme = response.subtheme;
    })
  }

  ngOnInit() {
    if (this.showForm) {
      this.baseService.feedbackParams().subscribe( (response: any) => {
        this.subtheme = response.subtheme;
        this.department = response.department;
        this.theme = response.theme;
        this.defaultCountry = response.default_country
        this.formFeedback = new FormGroup( {
          'subtheme': new FormControl(null,[Validators.required]),
          'department': new FormControl(null,[Validators.required]),
          'theme': new FormControl(null, [Validators.required]),
          'title': new FormControl(null, [Validators.required]),
          'text': new FormControl(null, [Validators.required]),
        })
      })
    } else {
      this.formFeedback = new FormGroup( {
        'text': new FormControl(null),
      })
    }
  }

}
